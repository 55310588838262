<template>
  <div class="container-fluid">
    <div class="row">
      <header-vpos></header-vpos>
      <div class="col-12 col-md-6 mt-2">
        <div class="d-flex">
          <button
            class="w-25 m-2"
            :style="{
              background: currentButton === 'store' ? '#1481da' : '#eeeeee',
              color: currentButton === 'store' ? 'white' : 'black',
              border: 'none',
              height: '35px',
            }"
            @click="setCurrentButton('store')"
          >
            Cửa hàng
          </button>
          <button
            class="w-25 m-2"
            :style="{
              background: currentButton === 'category' ? '#1481da' : '#eeeeee',
              color: currentButton === 'category' ? 'white' : 'black',
              border: 'none',
              height: '35px',
            }"
            @click="setCurrentButton('category')"
          >
            Danh mục
          </button>
          <button
            class="w-25 m-2"
            :style="{
              background: currentButton === 'product' ? '#1481da' : '#eeeeee',
              color: currentButton === 'product' ? 'white' : 'black',
              border: 'none',
              height: '35px',
            }"
            @click="setCurrentButton('product')"
          >
            Sản phẩm
          </button>
          <!-- <button
            class="w-25 m-2"
            :style="{
              background: currentButton === 'promotion' ? '#1481da' : '#eeeeee',
              color: currentButton === 'promotion' ? 'white' : 'black',
              border: 'none',
              height: '35px',
            }"
            @click="setCurrentButton('promotion')"
          >
            Khuyến mãi
          </button> -->
        </div>
      </div>
      <div class="col-12 col-md-12 mt-2" v-if="create_Store">
        <div class="container-fluid">
          <form class="row">
            <div
              class="col-12 col-md-6 mt-2"
              style="border-right: 1px #f4f4f4 solid"
            >
              <div class="d-flex flex-column">
                <label style="text-align: start; color: #5f5f5f">
                  Tên cửa hàng</label
                >
                <input
                  style="
                    height: 40px;
                    border: none;
                    border-bottom: 1px #cbcbcb solid;
                  "
                  v-model="storeName"
                />
              </div>
              <div class="d-flex flex-column mt-4">
                <label style="text-align: start; color: #5f5f5f">
                  Địa chỉ</label
                >
                <input
                  style="
                    height: 40px;
                    border: none;

                    border-bottom: 1px #cbcbcb solid;
                  "
                  v-model="address"
                  @blur="geocodeAddress()"
                  ref="autocompleteInput"
                  placeholder=""
                />
                <ul v-if="suggestions.length">
                  <li
                    v-for="(suggestion, index) in suggestions"
                    :key="index"
                    @click="selectSuggestion(suggestion)"
                  >
                    {{ suggestion.description }}
                  </li>
                  <Places
                    v-model="address"
                    :options="options"
                    @change="handleChange"
                    @clear="handleClear"
                  />
                </ul>
              </div>

              <div class="container-fluid p-0 mt-3">
                <div class="row">
                  <div class="col-12 col-md-5 mt-2">
                    <div class="d-flex">
                      <span
                        class="d-flex align-items-center m-2"
                        style="text-align: start; color: #5f5f5f"
                      >
                        Vĩ độ:
                      </span>
                      <input
                        placeholder=""
                        style="
                          height: 40px;
                          border: none;
                          border-bottom: 1px #cbcbcb solid;
                        "
                        v-model="latitude"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-5 mt-2">
                    <div class="d-flex">
                      <span
                        class="d-flex align-items-center m-2"
                        style="text-align: start; color: #5f5f5f"
                      >
                        Kinh độ:</span
                      >
                      <input
                        placeholder=""
                        style="
                          height: 40px;
                          border: none;

                          border-bottom: 1px #cbcbcb solid;
                        "
                        v-model="longitude"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-2 mt-2">
                    <button
                      type="button"
                      class="btn"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <img :src="imageUrls.iconMap" />
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <!-- <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                    </div>
                    <div class="modal-body"></div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column mt-4">
                <label style="text-align: start; color: #5f5f5f">
                  Email nhận thông tin</label
                >
                <input
                  style="
                    height: 40px;
                    border: none;

                    border-bottom: 1px #cbcbcb solid;
                  "
                  v-model="emailNhanFileDoiSoat"
                  placeholder=""
                />
              </div>
              <div class="container-fluid p-0 mt-4">
                <div class="row">
                  <div class="d-flex flex-column">
                    <span style="text-align: start"> Chọn ảnh</span>
                    <div class="d-flex">
                      <div
                        class="drop-zone m-2"
                        @click="handleClick"
                        @dragover.prevent="handleDragOver"
                        @drop.prevent="handleDrop"
                        :class="{ 'drop-zone--over': isDragging }"
                      >
                        <span
                          class="drop-zone__prompt d-flex justify-content-center align-items-center"
                          >+</span
                        >
                        <input
                          type="file"
                          class="drop-zone__input"
                          @change="handleFileChange"
                          ref="fileInput"
                          style="display: none"
                          multiple
                        />
                      </div>

                      <!-- Hiển thị các ảnh đã chọn -->
                      <div class="selected-images">
                        <div
                          v-for="(thumbnail, index) in thumbnails"
                          :key="index"
                          class="selected-image-container"
                        >
                          <img :src="thumbnail" class="selected-image" />
                          <span class="delete-icon" @click="deleteImage(index)"
                            >×</span
                          >
                        </div>
                      </div>
                      <div class="selected-images">
                        <div
                          v-for="(imageId, index) in image_key"
                          :key="index"
                          class="selected-image-container"
                        >
                          <img
                            :src="imageUrls.urlAnh2 + imageId"
                            class="selected-image"
                          />
                          <span class="delete-icon" @click="deleteImage(index)"
                            >×</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 mt-2">
                <div class="d-flex flex-column">
                  <label style="text-align: start; color: #5f5f5f">
                    Ngân hàng</label
                  >
                  <div class="form-group">
                    <div class="select-menu active shadow-sm">
                      <div class="select-btn" @click="toggleMenu">
                        <img
                          :src="selectedIcon ? `${selectedIcon.logo}` : ``"
                          style="width: 50px"
                        />
                        <span
                          class="sBtn-text"
                          style="color: black; margin-left: 10px"
                          >{{
                            selectedIcon
                              ? selectedIcon.name
                              : bankCode
                              ? getBankName(bankCode)
                              : ""
                          }}</span
                        >
                      </div>
                      <div v-if="message" class="text-danger">
                        Vui lòng chọn 1 ngân hàng!
                      </div>

                      <ul class="options" v-show="menuActive">
                        <li
                          class="option"
                          v-for="itemBank in listBank"
                          :key="itemBank.id"
                          @click="selectIcon(itemBank)"
                        >
                          <img :src="itemBank.logo" style="width: 50px" />
                          <div
                            class="d-flex flex-column"
                            style="
                              margin-left: 10px;
                              text-align: start;
                              font-family: system-ui, -apple-system,
                                BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
                                Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
                                sans-serif;
                            "
                          >
                            <span
                              class="option-text ml-4"
                              style="
                                color: black;
                                font-size: 14px;
                                font-weight: 600;
                              "
                              >{{ itemBank.short_name }}</span
                            >
                            <span
                              class="option-text ml-4"
                              style="color: black; font-size: 12px"
                              >{{ itemBank.short_name }} -
                              {{ itemBank.name }}</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <div class="d-flex flex-column w-50">
                    <label style="text-align: start; color: #5f5f5f">
                      Số tài khoản</label
                    >
                    <input
                      style="height: 40px; border: 1px #cbcbcb solid"
                      v-model="soTaiKhoan"
                      @blur="searchBank()"
                    />
                  </div>
                  <div
                    class="d-flex flex-column w-50 m-auto"
                    v-if="bankNameShow"
                  >
                    <input
                      class="mt-4"
                      style="
                        height: 40px;
                        border: none;
                        margin-left: 30px;
                        font-weight: 700;
                        background: none;
                      "
                      disabled
                      placeholder="Tên chủ tài khoản"
                      :value="bankName"
                    />
                  </div>
                </div>
                <div class="mt-4" style="text-align: start; line-height: 2">
                  <span style="font-weight: 600"
                    >Chuyển tiền về TK theo số giao dịch/ số dư
                  </span>
                  <br />
                </div>
                <div class="d-flex flex-column">
                  <div
                    class="d-flex w-50 justify-content-sm-between"
                    style="text-align: start; line-height: 2"
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex">
                        <input
                          type="radio"
                          id="contactChoice1"
                          v-model="selectedOption"
                          value="transaction"
                          @click="clearInputs"
                        />
                        <label for="contactChoice1" style="padding: 5px"
                          >Giao dịch</label
                        >
                      </div>
                      <input
                        v-if="selectedOption === 'transaction'"
                        style="height: 40px; border: 1px #cbcbcb solid"
                        v-model="numberOfTransactions"
                        placeholder="Nhập số giao dịch"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <div class="d-flex">
                        <input
                          type="radio"
                          id="contactChoice2"
                          v-model="selectedOption"
                          value="money"
                          @click="clearInputs"
                        />
                        <label for="contactChoice2" style="padding: 5px"
                          >Số dư
                        </label>
                      </div>
                      <input
                        v-if="selectedOption === 'money'"
                        style="height: 40px; border: 1px #cbcbcb solid"
                        v-model="totalLimit"
                        placeholder="Nhập số tiền"
                      />
                    </div>
                  </div>
                  <span class="text-start mt-1"
                    ><i>{{ currentLanguage.conttentPhi }}</i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mt-2">
              <h4 class="mt-3" style="text-align: start; margin-left: 60px">
                Quy đổi điểm thưởng
              </h4>
              <div class="d-flex justify-content-around">
                <div class="d-flex mt-2">
                  <input
                    style="height: 40px; border: 1px #cbcbcb solid"
                    v-model="tienQuyDoiRa1Diem"
                    placeholder="Nhập số tiền"
                  />
                  <span class="d-flex align-items-center m-2"> VND</span>
                </div>
                <div class="d-flex align-items-center">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </div>
                <div class="d-flex">
                  <input
                    style="
                      height: 40px;
                      border: 1px #cbcbcb solid;
                      background: white;
                    "
                    :value="diemTuTien"
                    disabled
                    placeholder="1"
                  />
                  <span class="d-flex align-items-center m-2"> điểm</span>
                </div>
              </div>
              <h4 class="mt-3" style="text-align: start; margin-left: 60px">
                Quy đổi điểm thành tiền
              </h4>
              <div class="d-flex justify-content-around">
                <div class="d-flex mt-2">
                  <input
                    style="height: 40px; border: 1px #cbcbcb solid"
                    v-model="diemQuyDoiRaTien"
                    placeholder="Nhập số điểm"
                  />
                  <span class="d-flex align-items-center m-2"> điểm</span>
                </div>
                <div class="d-flex align-items-center">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </div>
                <div class="d-flex">
                  <input
                    style="
                      height: 40px;
                      border: 1px #cbcbcb solid;
                      background: white;
                    "
                    v-model="tienDuocQuyDoi"
                    disabled
                    placeholder="Số tiền"
                  />
                  <span class="d-flex align-items-center m-2"> VND</span>
                </div>
              </div>
              <div class="d-flex flex-column mt-2">
                <div class="d-flex">
                  <input
                  type="checkbox"
                    id="contactChoice2"
                    value="giaohang"
                  />
                  <label for="contactChoice2" style="padding: 5px"
                    >Giao hàng theo địa chỉ</label
                  >
                </div>
              </div>

              <p class="text-start mt-1">
                Phí vận chuyển được quy định tại đơn hàng
              </p>
              <h4 style="text-align: start; margin-left: 60px">Phân quyền</h4>
              <div class="d-flex justify-content-around">
                <button
                  type="button"
                  class="w-25 m-2"
                  :style="{
                    background:
                      currentButtonCuaHang === 'chuTk' ? '#1481da' : '#eeeeee',
                    color: currentButtonCuaHang === 'chuTk' ? 'white' : 'black',
                    border: 'none',
                    height: '35px',
                  }"
                  @click="setCurrentButtonCuaHang('chuTk')"
                >
                  Chủ TK
                </button>
                <button
                  type="button"
                  class="w-25 m-2"
                  :style="{
                    background:
                      currentButtonCuaHang === 'tienMat'
                        ? '#1481da'
                        : '#eeeeee',
                    color:
                      currentButtonCuaHang === 'tienMat' ? 'white' : 'black',
                    border: 'none',
                    height: '35px',
                  }"
                  @click="setCurrentButtonCuaHang('tienMat')"
                >
                  Tiền mặt
                </button>
                <button
                  type="button"
                  class="w-25 m-2"
                  :style="{
                    background:
                      currentButtonCuaHang === 'thayDoi'
                        ? '#1481da'
                        : '#eeeeee',
                    color:
                      currentButtonCuaHang === 'thayDoi' ? 'white' : 'black',
                    border: 'none',
                    height: '35px',
                  }"
                  @click="setCurrentButtonCuaHang('thayDoi')"
                >
                  Thay đổi
                </button>
                <button
                  type="button"
                  class="w-25 m-2"
                  :style="{
                    background:
                      currentButtonCuaHang === 'xem' ? '#1481da' : '#eeeeee',
                    color: currentButtonCuaHang === 'xem' ? 'white' : 'black',
                    border: 'none',
                    height: '35px',
                  }"
                  @click="setCurrentButtonCuaHang('xem')"
                >
                  Xem
                </button>
              </div>
              <div class="mt-3">
                <div class="d-flex flex-column" v-if="chuTk">
                  <h4 style="text-align: start; margin-left: 60px">
                    Chủ tài khoản có quyền cập nhật điện thoại, email các mục
                    Tiền mặt, Thay đổi, Xem.
                  </h4>
                  <div
                    class="d-flex w-100"
                    style="border-bottom: 1px #f4f4f4 solid"
                  >
                    <div class="d-flex flex-column w-100">
                      <div class="d-flex w-100 mt-4 justify-content-sm-between">
                        <div class="d-flex flex-column w-75 p-2">
                          <input
                            style="height: 40px; border: 1px #cbcbcb solid"
                            placeholder="Nhập họ tên "
                            v-model="nameQuyenChuCuaHang"
                          />
                        </div>
                        <div class="d-flex flex-column w-75 p-2">
                          <input
                            style="height: 40px; border: 1px #cbcbcb solid"
                            placeholder="Nhập số điện thoại"
                            v-model="sdtChuCuaHang"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column" v-else>
                  <h4
                    style="text-align: start; margin-left: 60px"
                    v-if="thuTienMat"
                  >
                    Điện thoại trong mục này có quyền xác nhận Tiền mặt.
                  </h4>
                  <h4
                    style="text-align: start; margin-left: 60px"
                    v-if="thayDoi"
                  >
                    Điện thoại trong mục này có quyền thay đổi Cửa hàng, Danh
                    mục, Sản phẩm.
                  </h4>
                  <h4 style="text-align: start; margin-left: 60px" v-if="xem">
                    Điện thoại, Email trong mục này có quyền xem Sao kê và Tra
                    cứu.
                  </h4>
                  <!-- <h4 style="text-align: start; margin-left: 60px">
                Người được quyền tra cứu hoặc thay đổi
              </h4> -->
                  <div
                    class="d-flex flex-column w-100"
                    style="
                      border-bottom: 1px #f4f4f4 solid;
                      height: 300px;
                      overflow: auto;
                    "
                  >
                    <div
                      class="d-flex flex w-100"
                      style="border-bottom: 1px #f4f4f4 solid"
                      v-for="(phoneNumber, index) in rShow"
                      :key="index"
                    >
                      <div class="d-flex flex-column w-100">
                        <div
                          class="d-flex w-100 mt-4 justify-content-sm-between"
                        >
                          <div class="d-flex flex-column w-75 p-2">
                            <input
                              style="height: 40px; border: 1px #cbcbcb solid"
                              placeholder="Nhập họ tên"
                              :value="rShowName[index]"
                              :key="'name_' + index"
                              @input="updateName(index, $event.target.value)"
                            />
                          </div>
                          <div class="d-flex flex-column w-75 p-2">
                            <input
                              style="height: 40px; border: 1px #cbcbcb solid"
                              placeholder="Nhập số điện thoại"
                              :value="rShow[index]"
                              :key="'phone_' + index"
                              @input="updatePhone(index, $event.target.value)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="w-25 p-2 mt-4">
                        <img :src="imageUrls.iconDele" />
                      </div>
                    </div>
                  </div>
                  <div class="m-auto mt-4">
                    <button
                      type="button"
                      class="w-25"
                      style="
                        border: 2px #1481da solid;
                        color: #1481da;
                        height: 40px;
                        background: none;
                        border-radius: 15px;
                      "
                    >
                      <i class="fa fa-plus-circle" aria-hidden="true"></i> Thêm
                      người
                    </button>
                    <!-- <button @click="postAnh" type="button">Đẩy ảnh</button> -->
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-around w-100 p-2 mt-5">
                <input
                  class="w-25"
                  style="height: 40px; border: 1px #cbcbcb solid"
                  placeholder="Nhập 6 số token"
                  v-model="token"
                />
                <button
                  class="w-50"
                  type="button"
                  style="
                    border: 2px #1481da solid;
                    color: white;
                    height: 40px;
                    background: #1481da;
                    border-radius: 15px;
                  "
                  @click="taoCuaHang()"
                >
                  Sửa cửa hàng
                </button>
                <!-- <button
                  class="w-15"
                  type="button"
                  style="
                    border: 2px red solid;
                    color: white;
                    height: 40px;
                    background: red;
                    border-radius: 15px;
                  "
                  @click="xoaCuaHang()"
                >
                  Xóa cửa hàng
                </button> -->
              </div>
            </div>
          </form>
        </div>
      </div>

      <ProductComponent
        ref="refProduct"
        v-if="create_Product"
      ></ProductComponent>

      <CategoryComponent v-if="create_Cate"></CategoryComponent>
    </div>
  </div>
</template>

<script>
import gmapsInit from "@/components/js/googleApi.js";
import HeaderVpos from "../Home/HeaderVpos/HeaderVpos.vue";
import ProductComponent from "./ProductComponent.vue";
// import axios from "axios";
import { Places } from "vue-places";
import { imageUrls } from "@/components/js/urlImg";
import { languages } from "@/components/js/langVpos";
import { ref } from "vue";
// import _ from 'lodash';
import {
  convertToBase64,
  getMD5,
  KEY_PHONE_ID,
  KEY_SESSION_ID,
  postImage,
  postSearchBank,
  postUpdateStore,
} from "@/components/js/VposService";
import CategoryComponent from "./CategoryComponent.vue";
export default {
  name: "AddressInput",

  components: { HeaderVpos, CategoryComponent, ProductComponent },

  data() {
    return {
      currentLanguage: languages.vi,

      imageUrls: imageUrls,
      address: "",
      latitude: "",
      longitude: "",
      options: {},
      suggestions: [],
      files: [],
      thumbnails: [],
      isDragging: false,
      maxFiles: 5,
      listBank: [],
      bankNameShow: false,

      nameQuyen: "",
      phoneQuyen: "",
      traCuuChecked: false,
      thayDoiChecked: false,

      token: "",
      storeName: "",
      storeAdd: "",
      storeCode: "",
      soTaiKhoan: "",
      bankCode: "",
      bankName: "",
      concatenatedURLs: "",
      email: "",
      sdt: "",
      rShowName: "",
      rMarName: "",
      rShow: "",
      rMar: "",

      selectedOption: "", // This will hold the selected option
      numberOfTransactions:0, // This will hold the value for number of transactions
      moneyAmount: "",

      menuActive: false, // Thêm menuActive vào data
      selectedIcon: null, // Thêm selectedIcon vào data

      image_key: "",
      imgKeyLogo: "",
      nameBank: "",

      create_Store: true,
      create_Product: false,
      create_Cate: false,

      sessionLogin: KEY_SESSION_ID,
      idLogin: KEY_PHONE_ID,
      accOwner: KEY_PHONE_ID,

      currentButton: null,
      currentButtonCuaHang: null,
      chuTk: null,
      thuTienMat: null,
      thayDoi: null,
      xem: null,
      totalLimit: 0,

      tienQuyDoiRa1Diem: "",
      diemQuyDoiRaTien: "",
      tienDuocQuyDoi: 0,
    };
  },
  setup() {
    const isMapVisible = ref(false);

    const showMap = () => {
      isMapVisible.value = true;
    };

    return {
      isMapVisible,
      showMap,
    };
  },

  watch: {
    diemQuyDoiRaTien(newVal) {
      if (!isNaN(parseFloat(newVal))) {
        this.tienDuocQuyDoi =
          parseFloat(newVal) * (this.tienQuyDoiRa1Diem / 10);
      } else {
        this.tienDuocQuyDoi = "";
      }
    },
  },
  computed: {
    getStore() {
      const res = JSON.parse(localStorage.getItem("selectedStore"));
      return res;
    },

    diemTuTien() {
      if (!isNaN(parseFloat(this.tienQuyDoiRa1Diem))) {
        return 1;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.initSearch();
    this.fetchDataBank();
    this.setCurrentButton("store");
    this.setCurrentButtonCuaHang("chuTk");

    this.detalStore();
  },
  methods: {
    setCurrentButton(button) {
      this.currentButton = button;
      console.log(button);
      switch (button) {
        case "store":
          this.create_Store = true;
          this.create_Cate = false;
          this.create_Product = false;
          break;
        case "category":
          this.create_Store = false;
          this.create_Cate = true;
          this.create_Product = false;
          break;
        case "product":
          this.create_Store = false;
          this.create_Cate = false;
          this.create_Product = true;
          setTimeout(() => {
            this.$refs.refProduct.reset();
          }, 500);
          break;
      }
    },
    setCurrentButtonCuaHang(button) {
      this.currentButtonCuaHang = button;
      console.log(button);
      switch (button) {
        case "chuTk":
          (this.chuTk = true),
            (this.thuTienMat = false),
            (this.thayDoi = false),
            (this.xem = false);
          break;
        case "tienMat":
          (this.chuTk = false),
            (this.thuTienMat = true),
            (this.thayDoi = false),
            (this.xem = false);
          break;
        case "thayDoi":
          (this.chuTk = false),
            (this.thuTienMat = false),
            (this.thayDoi = true),
            (this.xem = false);
          break;
        case "xem":
          (this.chuTk = false),
            (this.thuTienMat = false),
            (this.thayDoi = false),
            (this.xem = true);
          break;
      }
    },
    detalStore() {
      this.bankCode = this.getStore.bankCode;
      this.soTaiKhoan = this.getStore.bankNumber;
      this.address = this.getStore.storeAdd;
      this.storeName = this.getStore.storeName;
      this.latitude = this.getStore.lat;
      this.longitude = this.getStore.lng;
      this.tienQuyDoiRa1Diem = this.getStore.tienQuyDoiRa1Diem;
      this.diemQuyDoiRaTien = this.getStore.diemQuyDoiRaTien;
      this.tienDuocQuyDoi = this.getStore.tienDuocQuyDoi;
      this.image_key = this.getStore.image.split(";");
      this.numberOfTransactions = this.getStore.numberOfTransactions;
      this.totalLimit = this.getStore.totalLimit;
      this.emailNhanFileDoiSoat = this.getStore.emailLH;
      this.rShowName = this.getStore.rShowName.split(";");
      this.rShow = this.getStore.rShow.split(";");
      this.traCuuChecked = Array(this.rShow.length).fill(true);

      console.log(this.rShow);
      if (this.getStore && this.getStore.sdtChuCuaHang) {
        this.sdtChuCuaHang = this.getStore.sdtChuCuaHang.split(";")[0];
        this.nameQuyenChuCuaHang = this.getStore.sdtChuCuaHang.split(";")[1];
      } else {
        console.error("this.getStore.sdtChuCuaHang is undefined");
      }
      // this.sdtChuCuaHang = this.getStore.sdtChuCuaHang.split(';')[0];
      // this.nameQuyenChuCuaHang = this.getStore.sdtChuCuaHang.split(';')[1];

      if (this.numberOfTransactions) {
        this.selectedOption = "transaction";
        this.totalLimit = 0;
      } else if (this.totalLimit) {
        this.selectedOption = "money";
        this.numberOfTransactions = 0;
      }
      this.searchBank();
    },
    updateName(index, value) {
      this.$set(this.rShowName.split(";"), index, value);
    },
    updatePhone(index, value) {
      this.$set(this.rShow.split(";"), index, value);
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    selectIcon(itemBank) {
      this.selectedIcon = itemBank;
      this.imgKeyLogo = itemBank.logo;
      this.nameBank = itemBank.name;
      this.bankCode = itemBank.code;
      this.menuActive = false;
    },
    clearInputs() {
      this.numberOfTransactions = "";
      this.moneyAmount = "";
    },

    // handleTraCuuChange(index) {
    //   this.traCuuChecked[index] = !this.traCuuChecked[index];
    //   if (this.traCuuChecked[index] ) {
    //     this.rShowName = this.nameQuyen;
    //     this.rShow = this.phoneQuyen;
    //   } else {
    //     this.rShowName = "";
    //     this.rShow = "";
    //   }
    // },
    // handleThayDoiChange(index) {
    //   this.thayDoiChecked[index] = !this.thayDoiChecked[index];
    //   if (this.thayDoiChecked[index] ) {
    //     this.rMarName = this.nameQuyen;
    //     this.rMar = this.phoneQuyen;
    //   } else {
    //     this.rMarName = "";
    //     this.rMar = "";
    //   }
    // },
    async handleInput() {
      if (!this.address) {
        this.suggestions = [];
        return;
      }

      try {
        const response = await Places.autocomplete.getPlacePredictions({
          input: this.address,
        });

        this.suggestions = response.predictions;
      } catch (error) {
        console.error("Đã xảy ra lỗi khi lấy gợi ý địa chỉ:", error);
      }
    },
    selectSuggestion(suggestion) {
      this.address = suggestion.description;
      this.suggestions = [];
    },

    async geocodeAddress() {
      try {
        console.log(encodeURIComponent(this.address));
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            this.address
          )}&key=AIzaSyCydVhSpoBMJvAZoVfxOYpWW05e9cLm16E`
        );
        const data = await response.json();
        console.log(data);
        if (data.results && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          this.latitude = location.lat;
          this.longitude = location.lng;
        } else {
          alert("Không tìm thấy tọa độ cho địa chỉ này !");
        }
      } catch (error) {
        console.error("Đã xảy ra lỗi khi lấy tọa độ:", error);
      }
    },
    async initSearch() {
      try {
        const google = await gmapsInit();
        // Lấy đối tượng input cho Autocomplete
        const inputElement = document.getElementById("inputAddress");
        var autocomplete1 = new google.maps.places.Autocomplete(
          inputElement,
          this.options
        );
        console.log(autocomplete1);
      } catch (error) {
        console.log("Đã xảy ra lỗi khi tải Google Maps:", error);
      }
    },
    /* eslint-enable */

    handleClick() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFiles = event.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (this.files.length < this.maxFiles) {
          this.files.push(file);
          if (file.type.startsWith("image/")) {
            this.createThumbnail(file);
          } else {
            this.thumbnails.push("");
          }
        } else {
          alert("Bạn chỉ có thể chọn tối đa " + this.maxFiles + " ảnh.");
          break;
        }
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const droppedFiles = event.dataTransfer.files;
      for (let i = 0; i < droppedFiles.length; i++) {
        const file = droppedFiles[i];
        if (this.files.length < this.maxFiles) {
          this.files.push(file);
          if (file.type.startsWith("image/")) {
            this.createThumbnail(file);
          } else {
            this.thumbnails.push("");
          }
        } else {
          alert("Bạn chỉ có thể thả tối đa " + this.maxFiles + " ảnh.");
          break;
        }
      }
    },
    createThumbnail(file) {
      const thumbnailURL = URL.createObjectURL(file);
      this.thumbnails.push(thumbnailURL);
    },
    deleteImage(index) {
      console.log(index);
      this.thumbnails.splice(index, 1);
      this.files.splice(index, 1);
    },

    fetchDataBank() {
      import("@/components/json/bankJson.json").then((module) => {
        const data = JSON.parse(JSON.stringify(module.default));
        this.listBank = data;
        // this.articles = data.filter(article => article.img_cn);
      });
    },
    async searchBank() {
      var session = KEY_SESSION_ID;
      var keyPhone = KEY_PHONE_ID;
      if (this.bankCode || this.soTaiKhoan) {
        console.log("code " + this.bankCode + "  " + this.bankNumber);

        this.bankCode = this.bankCode.split("-")[0];
        try {
          const res = await postSearchBank(
            keyPhone,
            session,
            this.soTaiKhoan,
            this.bankCode.trim(),
            ""
          );
          console.log(res);
          this.bankNameShow = true;
          this.bankName = res.result.tenChuTK;
          console.log(" this.bankName " + this.bankName);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.bankNameShow = false;
        alert("Vui lòng nhập số tài khoản");
      }
    },
    getBankName(bankCode) {
      console.log("bankCode " + bankCode);
      const bank = this.listBank.find((item) => item.code === bankCode);
      // console.log("List of Banks:", this.listBank);
      return bank ? bank.name : "Chọn ngân hàng";
    },
    async postAnh() {
      try {
        const imgURLs = [];
        for (const thumbnail of this.thumbnails) {
          console.log(thumbnail + " aaaaaa");
          const value = await convertToBase64(thumbnail);
          console.log("vlaue " + value);
          const idCheck = await getMD5(convertToBase64(thumbnail));
          console.log("base64String: " + idCheck);
          const res = await postImage(value, idCheck);
          console.log(res);
          imgURLs.push(res.result); // Giả sử res trả về một đối tượng với thuộc tính imgURL chứa đường dẫn của ảnh
        }
        this.concatenatedURLs = imgURLs.join(";");
        console.log("aaaa " + this.concatenatedURLs);
      } catch (e) {
        console.log(e);
        alert("Lỗi đẩy ảnh");
      }
    },
    async taoCuaHang() {
      var sessionLogin = KEY_SESSION_ID;
      var idLogin = KEY_PHONE_ID;
      var idParent = "";
      // var numberOfTransactions = 50;
      var typeWithDrawMoney = 1;
      this.sdt = KEY_PHONE_ID;
      var img = "";
      if (this.concatenatedURLs) {
        img = this.concatenatedURLs;
      } else {
        img = this.getStore.image;
      }
      this.postAnh();
      var idStore = this.getStore.id;
      if (this.numberOfTransactions == null) {
        this.numberOfTransactions = 0;
      } else if (this.totalLimit == null) {
        this.totalLimit = 0;
      }
      try {
        const res = await postUpdateStore(
          sessionLogin,
          idStore,
          idLogin,
          this.token,
          this.storeName,
          this.address,
          this.storeCode,
          idParent,
          typeWithDrawMoney,
          this.numberOfTransactions,
          img,
          this.email,
          this.sdt,
          this.bankCode,
          this.soTaiKhoan,
          this.latitude,
          this.longitude,
          this.getStore.rShowName,
          this.rMarName,
          this.getStore.rShow,
          this.rMar,
          this.getStore.rBillName,
          this.getStore.rBill,
          this.sdtChuCuaHang,
          this.tienQuyDoiRa1Diem,
          this.diemQuyDoiRaTien,
          this.tienDuocQuyDoi,
          this.totalLimit
        );
        console.log(res);
        if (res.msgCode == 1 && res.msgCode != null) {
          alert(
            "Đã cập nhật lại cửa hàng " + this.storeName + " thành công ! "
          );
          console.log(res);
          // localStorage.setItem("selectedStore", JSON.stringify(res.result));
          this.$router.push("/vpos/taothaydoi");
        } else {
          alert(res.msgContent);
        }
      } catch (erro) {
        console.log(erro);
      }
    },
  },
};
</script>
<style>
.drop-zone {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 30px;
  cursor: pointer;
  color: #088dc2;
  border: 1px solid #088dc2;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  background-position: center;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}
.selected-images {
  display: flex;
  flex-wrap: wrap;
  background-size: cover;
  background-position: center;
}

.selected-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 5px;
}
.selected-image-container {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  background-color: rgb(201 201 201 / 70%);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}
</style>