<template>
  <!-- <div class="container-login">
    <img :src="imageUrls.logoVpassXanh" class="mt-5" />
    <form @submit.prevent="register" class="content" style="top: 100px;">
      <div class="inputWithIcon">
        <input
          type="text"
          placeholder="Nhập số điện thoại"
          v-model="user"
        />
        <i class="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Mật khẩu đăng nhập (6 - 25 ký tự) "
          v-model="pass"
        />
        <i class="fa fa-lock fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Mật khẩu token (6 số)"
          maxlength="6"
          v-model="token"
        />
        <i class="fa fa-keyboard-o fa-lg fa-fw" aria-hidden="true"></i>
      </div>
    
      <input v-if="otp" placeholder="Nhập mã xác nhận OTP" class="detail" />
      <div class="d-flex justify-content-between mt-2">
        <h4 class="f-text-dangky">
          <a
            href="/vpos/dangnhap"
            style="margin-left: 10px; color: black; font-weight: 600"
          >
            Đăng nhập
          </a>
        </h4>
      </div>
      <button type="submit" class="btn int btn-outline-primary mt-5">
        Đăng ký
      </button>
    </form>
  </div> -->

  <div class="container-login d-flex flex-column">
    <header style="background-color: white">
      <div class="d-flex justify-content-between p-2">
        <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
          ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
        </a>
        <a class="p-2" style="font-size: 18px">
          <i class="fa fa-bell-o" aria-hidden="true"></i
        ></a>
        <a class="p-2" style="font-size: 18px">
          <i class="fa fa-ellipsis-v" aria-hidden="true"></i
        ></a>
      </div>
    </header>
    
    <form @submit.prevent="login" class="content">
      <img :src="imageUrls.logoVpassXanh" class="p-5" style="width: 300px" />

      <br />
      <div class="inputWithIcon">
        <input
          type="text"
          placeholder="Nhập số điện thoại"
          v-model="user"
        />
        <i class="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Mật khẩu đăng nhập (6 - 25 ký tự) "
          v-model="pass"
        />
        <i class="fa fa-lock fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Mật khẩu token (6 số)"
          maxlength="6"
          v-model="token"
        />
        <i class="fa fa-keyboard-o fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <h4 class="f-text-dangky">
          <a
            href="/vpos/dangky"
            style="margin-left: 10px; color: black; font-weight: 600"
          >
            Đăng nhập</a
          >
        </h4>
      
      </div>
      <button type="submit" class="btn int btn-outline-primary mt-5">
        Đăng ký
      </button>
    </form>
    <div class="d-flex flex-column">
      <span style="font-weight: 600"
        >Công ty cổ phần nền dịch vụ di động Việt Nam
      </span>
      <span>Giấy phép trung gian thanh toán số 41/GP-NHNN 12/3/2018</span>
    </div>
  </div>
</template>
  <script>
import { imageUrls } from "@/components/js/urlImg";
import { postResgister } from "@/components/js/VposService";

export default {
  
  data() {
    return {
      imageUrls: imageUrls,
      numberRandom: "",
      user: "",
      pass: "",
      companyCode: "",
      enteredCaptcha: "",
    };
  },
  mounted() {
    this.getDangKy();
  },
  methods: {
    getDangKy() {
      var user = localStorage.getItem("phoneDangKy");
      this.user = user;
    },

    async register() {
      localStorage.clear();
      try {
        const response = await postResgister(this.user, this.pass);
        console.log(response);
        if (response.msgCode == 1) {
          alert("Đăng ký thành công !");
          this.$router.push("/vpos/dangnhap");
        } else if (response.msgCode == 9) {
          alert("Xác nhận OTP !");
        } else {
          alert(response.msgContent);
        }
      } catch (error) {
        console.error("Lỗi khi đăng nhập:", error);
      }
    },
  },
};
</script>