<template>
  <div class="from-style d-flex flex-column">
    <form @submit.prevent="login" class="content " v-if="loginForm">
      <img :src="imageUrls.logoVpassXanh" class="p-5" style="width: 300px" />

      <br />
      <div class="inputWithIcon">
        <input
          type="text"
          placeholder="Số điện thoại/Email"
          v-model="user"
          @blur="checkPhoneNumber"
        />
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      </div>
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Nhập mật khẩu"
          v-model="pass"
        />
        <i class="fa fa-lock" aria-hidden="true"></i>
      </div>
      <!-- <input
        type="password"
        placeholder="Nhập mật khẩu"
        class="detail mt-5"
        v-model="pass"
      /> -->
      <div class="d-flex justify-content-between mt-2">
        <h4 class="f-text-dangky">
          <a
            @click="dangKy()"
            style="
              margin-left: 10px;
              color: black;
              font-weight: 600;
              color: rgb(20, 129, 218) !important;
            "
          >
            Đăng ký</a
          >
        </h4>
        <h4 class="f-text-dangky">
          <a
            @click="doiMatKhau()"
            style="
              margin-left: 10px;
              color: black;
              font-weight: 600;
              color: rgb(20, 129, 218) !important;
            "
          >
            Đổi mật khẩu
          </a>
        </h4>
      </div>
      <span class ="fs-5 ">{{ NotificationLogin }}</span>
      <button type="submit" class="btn int btn-outline-primary mt-5 mb-5">
        Đăng Nhập
      </button>
    </form>

    <form @submit.prevent="register" class="content" v-if="registerForm">
      <img :src="imageUrls.logoVpassXanh" class="p-5" style="width: 300px" />

      <br />
      <div class="inputWithIcon">
        <input type="text" placeholder="Số điện thoại/Email" v-model="user" />
        <i class="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Mật khẩu đăng nhập (6 - 25 ký tự) "
          v-model="pass"
        />
        <i class="fa fa-lock fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Mật khẩu token (6 số)"
          maxlength="6"
          v-model="token"
        />
        <i class="fa fa-keyboard-o fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <h4 class="f-text-dangky">
          <a
            @click="dangNhap"
            style="margin-left: 10px; color: black; font-weight: 600"
          >
            Đăng nhập</a
          >
        </h4>
      </div>
      <button type="submit" class="btn int btn-outline-primary mt-5 mb-5">
        Đăng ký
      </button>
    </form>

    <form @submit.prevent="changePass" class="content" v-if="changePassForm">
      <img :src="imageUrls.logoVpassXanh" class="p-5" style="width: 300px" />

      <br />
      <div class="inputWithIcon">
        <input type="text" placeholder="Số điện thoại/Email" v-model="user" />
        <i class="fa fa-user fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input
          type="password"
          placeholder="Mật khẩu đăng nhập mới (6 - 25 ký tự) "
          v-model="passNew"
        />
        <i class="fa fa-lock fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="d-flex justify-content-between mt-2">
        <h4 class="f-text-dangky">
          <a
            @click="dangNhap"
            style="margin-left: 10px; color: black; font-weight: 600"
          >
            Đăng nhập</a
          >
        </h4>
      </div>
      <button type="submit"  @click="changePassByPhone" class="btn int btn-outline-primary mt-5 mb-5">
        Đổi mật khẩu
      </button>
    </form>

    <div>
        <registerByEmail/>
    </div>
    <div v-if="fromComfrimOTP" class = "p-3">
      <span class ="fs-5 ">{{ Notification }}</span>
      <comfirmOTP/>
  </div>

  <div v-if="fromComfrimPhoneOTP" class = "p-3">
      <span class ="fs-5 ">{{ Notification }}</span>
      <confirmOtpPhone :phoneProp="user"></confirmOtpPhone>
  </div>

  <div v-if="fromConfirmChangePass" class = "p-3">
      <span class ="fs-5 ">{{ Notification }}</span>
      <confirmChangePass :phoneChange="user" :passNewChange ="passNew"></confirmChangePass>
  </div>

    <!-- <div class="d-flex flex-column">
      <span style="font-weight: 600"
        >Công ty cổ phần nền dịch vụ di động Việt Nam
      </span>
      <span>Giấy phép trung gian thanh toán số 41/GP-NHNN 12/3/2018</span>
    </div> -->
  </div>
</template>
<script>
import store from "@/components/js/store/index";
import { imageUrls } from "@/components/js/urlImg";
import {
  getCheckLoginUser,
  postLogin,
  postResgister,
  postResgisterByEmail,
  getIpWan,
  postLoginByEmail,
  getChnagePassPhone
} from "@/components/js/VposService";

import comfirmOTP from "@/views/VPOS/LoginEmail/confirmOTP.vue";
import confirmOtpPhone from "./confirmOtpPhone.vue";
import confirmChangePass from "./confirmChangePass.vue";
export default {
  
  components: { comfirmOTP, confirmOtpPhone, confirmChangePass },

  data() {
    return {
      loginForm: true,
      registerForm: false,
      showPasswordField: false,
      imageUrls: imageUrls,
      changePassForm: false,
      numberRandom: "",
      user: "",
      pass: "",
      companyCode: "",
      enteredCaptcha: "",
      fromComfrimOTP: false,
      fromComfrimPhoneOTP: false,
      fromConfirmChangePass: false,
      Notification: "",
      NotificationLogin: ""

    };
  },
  computed: {
    loggedIn() {
      return store.getters.loggedIn;
    },
    currentUser() {
      return store.getters.currentUser;
    },
  },
  mounted() {

    // localStorage.clear();
    this.getIpWan();
    this.getCapcha();
  },
  methods: {

    dangKy() {
      this.loginForm = false;
      this.registerForm = true;
      this.changePassForm = false;
      this.fromComfrimOTP = false;
      this.fromComfrimPhoneOTP = false;
      this.fromConfirmChangePass = false;
    },
 
    doiMatKhau() {
      this.loginForm = false;
      this.registerForm = false;
      this.changePassForm = true;
      this.fromComfrimOTP = false;
      this.fromComfrimPhoneOTP = false;
      this.fromConfirmChangePass = false;
    },
    dangNhap() {
      this.loginForm = true;
      this.registerForm = false;
      this.changePassForm = false;
      this.fromComfrimOTP = false;
      this.fromComfrimPhoneOTP = false;
      this.fromConfirmChangePass = false;
      // EventBus.$emit('callFunctionInVueB');
    
    },
 
    checkPhoneNumber() {
      if (this.user.trim() != "") {
        this.checkUserLogin();
      } else {
        this.showPasswordField = false;
      }
    },
    getCapcha() {
      let random_id = Math.ceil(Math.random() * 1000000);
      this.numberRandom = random_id;
    },

    async checkUserLogin() {
      try {
        const res = await getCheckLoginUser(this.user);
        if (res == 14 || res == 15) {
          this.showPasswordField = true;
        } else {
          this.loginForm = false;
          this.registerForm = true;
          // this.$router.push("/vpos/dangky");
          // localStorage.setItem("phoneDangKy", this.user);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async login() {
      localStorage.removeItem('user');
      localStorage.removeItem('idCuaHang');
      localStorage.removeItem('selectedStore');
      if (this.isEmail(this.user)) {
        const responseEmail = await postLoginByEmail(this.user, this.pass, this.ipWan);
        console.log(responseEmail);
        if (responseEmail.msgCode == 1) {
              localStorage.setItem("user", JSON.stringify(responseEmail.result));
              localStorage.setItem("sessionUser" , responseEmail.result.secssion )
              localStorage.setItem("phoneUser" , responseEmail.result.phone )
              this.$store.dispatch('loginUser', responseEmail.result);

            window.location.reload();
          } else {
            // alert(responseEmail.msgContent);
            this.NotificationLogin = responseEmail.msgContent;
          }
      }
      if (this.isPhone(this.user)) {
        try {
          const response = await postLogin(this.user, this.pass, 4, 1, "", "1");
          console.log(JSON.stringify(response));

          if (response.msgCode == 1) {
              localStorage.setItem("user", JSON.stringify(response.result));
              localStorage.setItem("sessionUser" , response.result.secssion )
              localStorage.setItem("phoneUser" , response.result.phone )
              this.$store.dispatch('loginUser', response.result);
           
           window.location.reload();
          } else {
          //  alert(response.msgContent);
            this.NotificationLogin = response.msgContent;
          }
        } catch (error) {
          console.error("Lỗi khi đăng nhập:", error);
        }
      }
    },
    async register() {

      // localStorage.clear();
      localStorage.removeItem('user');
      localStorage.removeItem('idCuaHang');
      localStorage.removeItem('selectedStore');
      if (this.isEmail(this.user)) {
       // alert("isEmail: " + this.isEmail(this.user));
        try{
        const response = await postResgisterByEmail(this.user, this.pass, this.ipWan);
        //alert("postResgisterByEmail res ==========> " + JSON.stringify(response));
         this.Notification = response.msgContent;
         this.registerForm = false;
         this.fromComfrimOTP = true;
        }catch(e){
        console.log("Exception e:",e);
        }
      }

      if (this.isPhone(this.user)) {
       // alert("isPhone: "+ this.isPhone(this.user));
        try {
            const response = await postResgister(this.user, this.pass);
            console.log(response);

            this.registerForm = false;
            this.fromComfrimPhoneOTP = true;
            if (response.msgCode == 1) {
              //alert("Đăng ký thành công !");
              this.Notification ="Đăng ký thành công !";
              // this.$router.push("/vpos");
            } else if (response.msgCode == 9) {
             // alert("Xác nhận OTP !");
              this.Notification = "Xác nhận OTP!, có hiệu lực trong 5 phút";
            } else {
              // alert(response.msgContent);
              this.Notification = response.msgContent;
            }
          } catch (error) {
            console.error("Lỗi khi đăng nhập:", error);
          }
      }

    },
    async changePassByPhone() {
      alert("response res ==========> " +this.user+"/ "+this.passNew);
      //localStorage.clear();
   
      try{
        const response = await getChnagePassPhone(this.user, this.passNew);
        this.changePassForm = false;
        this.fromConfirmChangePass = true;
       // alert("response res ==========> " + JSON.stringify(response));

        if(response.msgCode == 1){
          this.Notification = "Xác nhận OTP!, có hiệu lực trong 5 phút";
        }else if(response.msgCode == 9){
          this.Notification = "Xác nhận OTP!, có hiệu lực trong 5 phút";
        }else{
          this.Notification = response.msgContent;
        }

        }catch(e){
          console.log("Exception e:",e);
        }

    },

    async getIpWan(){
      try{
        const res = await getIpWan();
        this.ipWan = res;
        //alert("postResgisterByEmail res ==========> " + this.ipWan);
      }catch(e){
        console.log("Exception e:",e);
      }
    },

    isEmail(input) {
      // Biểu thức chính quy để kiểm tra email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(input);
    },

    isPhone(input) {
      // Biểu thức chính quy để kiểm tra số điện thoại
      const phonePattern = /^\+?(\d{1,3})?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return phonePattern.test(input);
    }
  },

};
</script>



<style scoped>
.from-style{
  max-width: 500px;
  width: 425px;
    /* height: 576px; */
    margin: auto;
    background: #f7f6f7;
    border-radius: 25px;
    height: auto;
    padding: 0px 32px !important;
}
</style>