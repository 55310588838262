<template>
    <div class="container-login">
      <img :src="imageUrls.logoVpassXanh" class="mt-5" />
      <form @submit.prevent="login" class="content">
        <input
          type="text"
          placeholder="Nhập số điện thoại "
          class="detail"
          v-model="user"
        /><br />
        <input
          type="password"
          placeholder="nhập mật khẩu"
          class="detail"
          v-model="pass"
        /><br />
        <div class="d-flex justify-content-between mt-2">
          <h4 class="f-text-dangky">
            Bạn đã có tài khoản?
            <a
              href="/vpos/dangnhap"
              style="margin-left: 10px; color: black; font-weight: 600"
            >
              Đăng nhập tại đây</a
            >
          </h4>
        </div>
        <button type="submit" class="btn int btn-outline-primary mt-5">
          Quên mật khâủ
        </button>
      </form>
    </div>
  </template>
    <script>
  import { imageUrls } from "@/components/js/urlImg";
  import { postResgister } from "@/components/js/VposService";
  
  export default {
    data() {
      return {
        imageUrls: imageUrls,
        numberRandom: "",
        user: "",
        pass: "",
        companyCode: "",
        enteredCaptcha: "",
      };
    },
    mounted() {
      this.getCapcha();
    },
    methods: {
      getCapcha() {
        let random_id = Math.ceil(Math.random() * 1000000);
        this.numberRandom = random_id;
      },
      async login() {
        localStorage.clear();
        try {
          const response = await postResgister(this.user, this.pass);
          console.log(response);
          if (response.msgCode == 1) {
            alert("Đăng ký thành công !");
            this.$router.push("/vpos/dangnhap");
          } else if (response.msgCode == 9) {
            alert("Xác nhận OTP !");
           
          } else {
            alert(response.msgContent);
          }
        } catch (error) {
          console.error("Lỗi khi đăng nhập:", error);
        }
      },
    },
  };
  </script>