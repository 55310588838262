<template>
    <div class="container-fluid row m-0 p-0"
        style="background-color: #FFFFFF; display: flex; align-items: center; position: relative">
        <div id="AnhCuoi" v-if="statusBackGround" class="d-flex justify-content-center w-100">
            <div class="col-md-4 col-12">
                <div style="margin-top: 15px;">
                    <span class="" style="color: pink;font-size: 30px">Bùi Danh ♥ Nguyễn Bắc <br> 26 MAY 2024</span>
                </div>
                <div class="w-100">
                    <img :src="icon.anhPhongNen" alt="" style="display: block; margin: auto;">
                </div>
                <div class="w-100">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716202434525sZjMF" alt="8"
                        style="display: block; margin: auto;">
                </div>
            </div>
        </div>
        <div id="banDo">
            <div class="row w-100 p-0 m-0">
                <span
                    style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 30px;color: rgb(235, 102, 102);">
                    Bản đồ
                </span>
                <span
                    style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16px;color: black;font-style: italic;">
                    Cảm ơn bạn rất nhiều vì đã gửi những lời chúc mừng tốt đẹp nhất đến đám cưới của chúng tôi!
                </span>
            </div>
            <div> 
                <div class="d-flex justify-content-evenly">
                    <div style="border-style: dotted;border-color: black;border-width: 2px;">
                        <div class="col-12">
                            <span class="col-3"
                                style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20px;color: black;">
                                Địa chỉ nhà trai:
                            </span>
                            <span class="col-9"
                                style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20px;color: black;font-style: italic;">
                                Đội 1 Yên Thái, Đông Yên, Quốc Oai, Hà Nội
                            </span>
                        </div>
                        <div class=" my-2" style="background-color: aqua;">
                            <a style="" target="_blank"
                                href="https://www.google.com/maps?q=20.9354557,105.5900080&entry=gps&lucs=,94215790,47071704,47069508,94218635,94203019,47084304,94208458,94208447&g_ep=CAISDTYuMTE0LjMuNTkxOTAYACDXggMqSCw5NDIxNTc5MCw0NzA3MTcwNCw0NzA2OTUwOCw5NDIxODYzNSw5NDIwMzAxOSw0NzA4NDMwNCw5NDIwODQ1OCw5NDIwODQ0N0ICVk4%3D&g_st=iz">Xem
                                đường đến nhà trai</a>
                        </div>

                    </div>
                    <div style="border-style: dotted;border-color: black;border-width: 2px;">
                        <div class="col-12">
                            <span class="col-3"
                                style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20px;color: black;">
                                Địa chỉ nhà gái:
                            </span>
                            <span class="col-9"
                                style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 20px;color: black;font-style: italic;">
                                Xa Loan, Bồng Lai, Quế Võ, Bắc Ninh
                            </span>
                        </div>
                        <div class=" my-2" style="background-color: aqua;">
                            <a class="" style="" target="_blank"
                                href="https://maps.app.goo.gl/Jr5m3y2YHdqx5eSLA?g_st=iz">Xem
                                đường đến nhà gái</a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div id="qrCuoi" v-if="statusHopMungCuoi" class="d-flex flex-column justify-content-center p-0 my-5"
            style="color: black;">
            <div class="my-2">
                <img :src="icon.duongKe" alt="" style="display: block; margin: auto;">
            </div>
            <div class="row w-100 p-0 m-0">
                <span
                    style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 30px;color: rgb(235, 102, 102);">
                    HỘP MỪNG CƯỚI
                </span>
                <span
                    style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16px;color: black;font-style: italic;">
                    Thật vui vì được gặp và đón tiếp các bạn trong một dịp đặc biệt như đám cưới của chúng tôi.
                </span>
            </div>
            <div>
                <div class="d-flex flex-column justify-content-center  p-1 m-1"
                    style="border-style: dotted;border-color: black;border-width: 2px;">
                    <span class="my-2" style="font-size: 25px;color: black;">ĐẾN CHÚ RỂ</span>
                    <div class="">
                        <img class="col-md-2 col-3"
                            src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1714121652092b2E5F"
                            alt="">
                    </div>
                    <div class="d-flex flex-column justify-content-center my-2" style="font-size: 24px;color: black;">
                        <span>Ngân hàng: TECHCOMBANK</span>
                        <span>Số tài khoản: 3319981998</span>
                        <span>Tên tài khoản: BUI DANG DANH</span>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-center m-1 "
                    style="border-style: dotted;border-color: black;border-width: 2px;">
                    <span class="my-2" style="font-size: 25px;color: black;">ĐẾN CÔ DÂU</span>
                    <div class="">
                        <img class="col-md-2 col-3"
                            src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1714121651982Hp6nF"
                            alt="">
                    </div>
                    <div class="d-flex flex-column justify-content-center my-2" style="font-size: 24px;color: black;">
                        <span>Ngân hàng: VPBANK</span>
                        <span>Số tài khoản: 155951998</span>
                        <span>Tên tài khoản: NGUYEN THI BAC</span>
                    </div>
                </div>
            </div>
            <div class="my-2">
                <img :src="icon.duongKe" alt="" style="display: block; margin: auto;">
            </div>
        </div>


        <div v-if="statusBackGround" class="w-100 justify-content-center">
            <div class="row my-5">
                <span
                    style="color: rgb(235, 102, 102);font-size: 30px;font-style: italic;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
                    Album Hình Cưới</span>
                <span
                    style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-size: 16px;color: black;font-style: italic;">

                    Đã từng muốn chinh phục cả thế giới, nhưng cuối cùng quay đầu lại mới nhận ra, từng mảnh, từng mảnh
                    trong thế giới ấy đều là em
                </span>
            </div>
            <div class="col-md-12 col-12 d-flex justify-content-center">
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180044670idzpF&size=1000" alt="6"
                        style="display: block; margin: auto;">
                </div>
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17161800448586ZnZF&size=1000" alt="7"
                        style="display: block; margin: auto;">
                </div>
            </div>

            <div class="col-md-12 col-12 d-flex justify-content-center">
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180044420fj2BF&size=1000" alt="4"
                        style="display: block; margin: auto;">
                </div>
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180044545eIsGF&size=1000" alt="5"
                        style="display: block; margin: auto;">
                </div>
            </div>

            <div class="col-md-12 col-12 d-flex justify-content-center">
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180044123E08gF&size=1000" alt="2"
                        style="display: block; margin: auto;">
                </div>
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716199819977vmQNF&size=1000" alt="11"
                        style="display: block; margin: auto;">
                </div>
            </div>
            <div class="col-md-12 col-12 d-flex justify-content-center">
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180045108YsLEF&size=1000" alt="8"
                        style="display: block; margin: auto;">
                </div>
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180045561psA4F&size=1000" alt="9"
                        style="display: block; margin: auto;">
                </div>
            </div>
            <div class="col-md-12 col-12 d-flex justify-content-center">
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180043905CpejF&size=1000" alt="1"
                        style="display: block; margin: auto;">
                </div>
                <div class="w-50 p-1">
                    <img loading="lazy"
                        src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1716180045342G2XmF&size=1000" alt="10"
                        style="display: block; margin: auto;">
                </div>
            </div>

        </div>


    </div>
</template>


<script>
import { imageUrlsChiTietCuaHang } from "@/views/wedding/urlAnh";


export default {
    data() {
        return {
            icon: imageUrlsChiTietCuaHang,
            statusBackGround: true,
            statusHopMungCuoi: true,

        }
    },
    mounted() {
        document.title = "Danh <3 Bắc";  // Sửa thành thực thể HTML
        this.ThayDoiFavicon();
    },
    methods: {
        ThayDoiFavicon() {
            // Xóa tất cả các thẻ link có rel='icon'
            const headElement = document.getElementsByTagName('head')[0];
            const existingIcons = document.querySelectorAll("link[rel~='icon']");
            existingIcons.forEach(icon => headElement.removeChild(icon));

            // Tạo và thêm thẻ link mới
            const newLink = document.createElement('link');
            newLink.rel = 'icon';
            newLink.href = this.icon.favicon;
            headElement.appendChild(newLink);
        }
    }
}
</script>
