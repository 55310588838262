<template>
  <div class="d-flex flex-column justify-content-between">
    <div class="d-flex flex-column">
      <div class="p-2">
        <div class="p-4" style="background-color: #f4f4f4; border-radius: 10px">
          <div class="d-flex">
            <img
              class="ml-3"
              :src="imageUrls.urlAnh + avatar"
              style="border-radius: 50%; width: 50px"
            />
            <div class="m-auto flex-column" style="color: black">
              <span style="font-weight: 600; margin-left: 10px">{{ name }}</span
              ><span style="margin-left: 10px">{{ phone }}</span>
            </div>
            <div
              class="user-name"
              style="
                color: black;
                font-size: 18px;
                margin-top: 10px;
                cursor: pointer;
              "
              @click="logout()"
            >
              <i class="fa fa-sign-out" aria-hidden="true"></i>
            </div>
          </div>
        </div>

        <div
          class="p-4 mt-3"
          style="background-color: #f4f4f4; border-radius: 10px"
        >
          <div class="d-flex" v-if="tickShow" @click="clickShow()">
            <a class="has-dropdown w-75">
              <i class="fa fa-shopping-bag" aria-hidden="true"></i>
              <div class="d-flex justify-content-end">
                <span>Cửa hàng</span>
                <img :src="imageUrls.iconcuaHang" />
              </div>
            </a>
            <div class="w-25 d-flex justify-content-center m-auto">
              <button class="btn-icon-vpos" style="width: 30px; height: 30px">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="form-group">
            <div class="select-menu active">
              <a class="has-dropdown w-100" @click="toggleMenu">
                <div class="d-flex">
                  <div class="w-25">
                    <img :src="imageUrls.iconcuaHang" />
                  </div>
                  <span class="sBtn-text w-50">{{
                    selectedStore
                      ? selectedStore.storeName
                      : currentLanguage.header_menuCuaHang
                  }}</span>
                  <i
                    class="fa fa-angle-down w-25"
                    style="
                      font-size: 18px;
                      cursor: pointer;
                      color: black;
                      text-align: end;
                    "
                    aria-hidden="true"
                  ></i>
                </div>
              </a>
              <div class="options" v-show="menuActive" style="position: absolute; width: 85%;">
                <div
                  v-for="itemCuaHang in listCuaHang"
                  :key="itemCuaHang.id"
                  class="option"
                >
                  <div
                    class="d-flex flex-column w-100"
                    style="border-bottom: 1px #c6c0c0 solid"
                    @click="selectIcon(itemCuaHang)"
                  >
                    <strong style="font-size: 15px !important; color: black">{{
                      itemCuaHang.storeName
                    }}</strong>
                    <span style="color: black"
                      >{{ itemCuaHang.bankCode }} -
                      {{ genNumber(itemCuaHang.bankNumber) }}</span
                    >
                  </div>
                  
                </div>
                <a href="/vpos/taocuahang"> 
                <button
                  class="w-100"
                  type="button"
                  style="
                    border: 2px #1481da solid;
                    color: white;
                    height: 40px;
                    background: #1481da;
                    border-radius: 10px;
                  "
                >
                  Tạo cửa hàng
                </button>
              </a>
              </div>
              
            </div>
          </div>
        </div>

        <div
          class="p-4 mt-3"
          style="background-color: #f4f4f4; border-radius: 10px"
        >
          <div class="d-flex flex-column" style="color: black">
            <li>
              <a class="d-flex" @click="chonCuaHang()">
                <div class="w-25">
                  <img :src="imageUrls.iconSanPham" style="width: 20px" />
                </div>
                <span class="w-75">{{ currentLanguage.titleBanHang }}</span></a
              >
            </li>
            <li>
              <a class="d-flex">
                <div class="w-25">
                  <img :src="imageUrls.iconKhuyenMai" style="width: 20px" />
                </div>
                <span class="w-75">{{
                  currentLanguage.menu_KhuyenMai
                }}</span></a
              >
            </li>

            <li>
              <a class="d-flex" href="/vpos/taothaydoi">
                <div class="w-25">
                  <img :src="imageUrls.iconThayDoi" style="width: 20px" />
                </div>
                <span class="w-75">{{
                  currentLanguage.header_taoThayDoi
                }}</span></a
              >
            </li>

            <li>
              <a class="d-flex">
                <div class="w-25">
                  <img :src="imageUrls.iconSaoKe" style="width: 15px" />
                </div>
                <span class="w-75">{{ currentLanguage.titleSaoKe }}</span></a
              >
            </li>
            <li>
              <a class="d-flex">
                <div class="w-25">
                  <img :src="imageUrls.iconTraCuu" style="width: 20px" />
                </div>
                <span class="w-75">{{ currentLanguage.titleTraCuu }}</span></a
              >
            </li>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex flex-column">
      <span style="font-weight: 600"
        >Công ty cổ phần nền dịch vụ di động Việt Nam
      </span>
      <span>Giấy phép trung gian thanh toán số 41/GP-NHNN 12/3/2018</span>
    </div> -->
  </div>
</template>
  <script>
import { imageUrls } from "@/components/js/urlImg";
import {  postLayDsStore } from "@/components/js/VposService";
import { languages } from "@/components/js/langVpos";
export default {
  components: {},
  data() {
    return {
      savedLanguage: "",
      currentLanguage: languages.vi,
      imageUrls: imageUrls,
      name: "",
      email: "",
      avatar: "",
      phone: "",
      listCuaHang: [],
      tickShow: false,
      tickHide: false,
      showMenu: false,
      menuActive: false, // Thêm menuActive vào data
      selectedIcon: null,
      nameStore: "",
      urlSanPham: "",
      idCuaHang: "",
      selectedStore: null,
    };
  },
  created() {
    this.savedLanguage = localStorage.getItem("languages");
    // const lang = this.$route.params.lang;
    if (languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }

    if (this.savedLanguage && languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }
  },
  mounted() {
    this.getUser();
    this.selectedStore = this.getSelectedStoreFromLocalStorage();
    console.log(" ", this.listCuaHang);
  },
  methods: {
    selectIcon(itemCuaHang) {
      this.selectedStoreId = itemCuaHang.id;
      this.selectedStore = itemCuaHang;
      this.saveSelectedStoreToLocalStorage(itemCuaHang, itemCuaHang.id);
      this.selectedIcon = itemCuaHang;
      this.imgKey = itemCuaHang.image_key;
      this.nameStore = itemCuaHang.nameStore;
      this.menuActive = false;
    },
    chonCuaHang() {
      var id = localStorage.getItem("idCuaHang");
      if (id == "" || id == null) {
        alert("Vui lòng chọn 1 cửa hàng");
      } else {
        // this.$router.push("/vpos/o?idStore=" + id + "&idBanAn=1")
        window.location.href = "/vpos/o?idStore=" + id + "&idBanAn=1";
        // this.urlSanPham = "/vpos/o?idStore=" + this.idCuaHang + "&idBanAn=1";
      }
    },

   saveSelectedStoreToLocalStorage(selectedStore, id) {
        localStorage.setItem("selectedStore", JSON.stringify(selectedStore));
        localStorage.setItem("idCuaHang", id);
    },

    getSelectedStoreFromLocalStorage() {
        const selectedStore = localStorage.getItem("selectedStore");
        return selectedStore ? JSON.parse(selectedStore) : null;
    },
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },

    async clickShow() {
      this.tickHide = true;
      this.tickShow = false;
      this.showMenu = true;
    },
    clickHide() {
      this.tickHide = false;
      this.tickShow = true;
      this.showMenu = false;
    },
    toggetMenu() {
      this.showMenu = true;
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("sessionUser");
      localStorage.removeItem("phoneUser");
      //  this.$router.push("/vpos");
      window.location.href = "/vpos";
      // window.location.reload();
    },
    genNumber(phoneNumber) {
      // const lengthToMask = phoneNumber.length - 4;
      const maskedPhoneNumber = "xxxx" + phoneNumber.slice(-4);
      return maskedPhoneNumber;
    },
    async getUser() {
      const dataFromLocalStorage = localStorage.getItem("user");
      if (dataFromLocalStorage) {
        try {
          const parsedData = JSON.parse(dataFromLocalStorage);
          this.name = parsedData.nameAlias;
          this.avatar = parsedData.avatar;
          this.phone = parsedData.phone;
          this.email = parsedData.email;
          try {
            const dataCuaHang = await postLayDsStore(
              parsedData.secssion,
              parsedData.id
            );
            this.listCuaHang = dataCuaHang.result.docs;
            console.log("this.listCuaHang() ", dataCuaHang);
            // Chọn cửa hàng đầu tiên trong danh sách là cửa hàng mặc định
            if(this.selectedStore == "" || this.selectedStore == null){
            if (this.listCuaHang.length > 0) {
              this.selectedStoreId = this.listCuaHang[0].id;
              this.selectedStore = this.listCuaHang[0]; // Thêm dòng này
              this.saveSelectedStoreToLocalStorage(
                this.listCuaHang[0],
                this.listCuaHang[0].id
              );
              var user = localStorage.getItem("user");
              var id = this.listCuaHang[0].id;
              if (user == "" || user == null) {
                this.$router.push("/vpos");
              } else {
                // this.$router.push("/vpos/o?idStore=" + id + "&idBanAn=1")
                this.$router.push("/vpos/o?idStore=" + id + "&idBanAn=1");
                // this.urlSanPham = "/vpos/o?idStore=" + this.idCuaHang + "&idBanAn=1";
              }
            }
          }
          
            
            

            localStorage.setItem(
              "dataCuaHang",
              JSON.stringify(this.listCuaHang)
            );
          } catch (error) {
            console.error("Error fetching dataCuaHang:", error);
          }
        } catch (error) {
          console.error("Error parsing data from localStorage:", error);
        }
      } else {
        console.warn("No data found in localStorage");
      }
    },

    dataClickitemCuaHang(itemCuaHang) {
      this.$emit("dataClickitemCuaHang", {
        ip: itemCuaHang.ip,
        tenMayChu: itemCuaHang.tenMayChu,
        mcID: itemCuaHang.mcID,
        vID: itemCuaHang.vID,
        phone: itemCuaHang.phone,
        userName: itemCuaHang.userName,
      });
    },
  },
};
</script>
