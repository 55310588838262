<template>
      <div class=" mt-tb-1">
        <div class="framer_table_cks">

<table cellspacing="0" border="0">
    <tbody class="tbody_table_cks">
        <tr style="background: #85c8eb;color: white;text-align: center;height: 30px;line-height: 30px;">
            <td class="td_bg_1 border_buttom_td"><b>{{ currentLanguage.body_text_dichvu_cks_STT}}</b></td>
            <td class="td_bg_2 border_buttom_td"><b> {{ currentLanguage.body_text_dichvu_cks_DICHVUCHUKYSOsudungtheSIMkysoVimass}} </b></td>
            <td class="td_bg_3 border_buttom_td" style="font-size: 12px;line-height: 20px;"><b>{{ currentLanguage.body_text_dichvu_cks_phiVAT}}</b></td>
            <td class="td_bg_4 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_diemThuong}}</td>
            <td class="td_bg_5 border_buttom_td"> {{ currentLanguage.body_text_dichvu_cks_diemMay}}</td>
        </tr>
        <tr style="background: paleturquoise;">
            <td class="td_bg_1 border_buttom_td"></td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_CHUKYSOTHEONAM}}</td>
            <td class="td_bg_3 border_buttom_td"> </td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td"> </td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">1</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giathuebaochukysocanhan1nam}} </td>
            <td class="td_bg_3 border_buttom_td"> 200.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 100</td>
            <td class="td_bg_5 border_buttom_td"> 100</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">2</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giathuebaochukysocanhan2nam}} </td>
            <td class="td_bg_3 border_buttom_td"> 350.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 100</td>
            <td class="td_bg_5 border_buttom_td"> 100</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">3</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giathuebaochukysocanhan3nam}}  </td>
            <td class="td_bg_3 border_buttom_td"> 420.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 100</td>
            <td class="td_bg_5 border_buttom_td"> 100</td>

        </tr>
    

        <tr>
            <td class="td_bg_1 border_buttom_td">4</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giathuebaochukysotochuc1nam}}</td>
            <td class="td_bg_3 border_buttom_td"> 430.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 200</td>
            <td class="td_bg_5 border_buttom_td"> 200</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">5</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giathuebaochukysotochuc2nam}} </td>
            <td class="td_bg_3 border_buttom_td"> 680.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 200</td>
            <td class="td_bg_5 border_buttom_td"> 200</td>

        </tr>

        <tr>
            <td class="td_bg_1 border_buttom_td">6</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giathuebaochukysotochuc3nam}}</td>
            <td class="td_bg_3 border_buttom_td"> 770.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}} </td>
            <td class="td_bg_4 border_buttom_td"> 200</td>
            <td class="td_bg_5 border_buttom_td"> 200</td>

        </tr>

        <tr>
            <td class="td_bg_1 border_buttom_td">7</td>
            <td class="td_bg_2 border_buttom_td"> {{ currentLanguage.body_text_dichvu_cks_Giahanchukysodanhchotochuc1nam}}</td>
            <td class="td_bg_3 border_buttom_td"> 310.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 200</td>
            <td class="td_bg_5 border_buttom_td"> 200</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">8</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giahanchukysodanhchotochuc2nam}} </td>
            <td class="td_bg_3 border_buttom_td"> 540.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 200</td>
            <td class="td_bg_5 border_buttom_td"> 200</td>

        </tr>

        <tr>
            <td class="td_bg_1 border_buttom_td">9</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giahanchukysodanhchotochuc3nam}}</td>
            <td class="td_bg_3 border_buttom_td">720.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> 200</td>
            <td class="td_bg_5 border_buttom_td"> 200</td>


        </tr>

        <tr style="background: paleturquoise;">
            <td class="td_bg_1 border_buttom_td"></td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_CHUKYSOTUNGLAN}}</td>
            <td class="td_bg_3 border_buttom_td"> </td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td"> </td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">10</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia1lankyneuduoi1000lannamkhongduoi8lan}}</td>
            <td class="td_bg_3 border_buttom_td"> 4.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td"> 200 </td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">11</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia1lankyneutu1000den10000lannam}}</td>
            <td class="td_bg_3 border_buttom_td"> 3.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td"> 150  </td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">12</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia1lankyneutu10000den100000lannam}}</td>
            <td class="td_bg_3 border_buttom_td"> 2.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td"> 100</td>
        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">13</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia1lankyneutu100000den1000000lannam}}</td>
            <td class="td_bg_3 border_buttom_td"> 1.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}} </td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td"> 50</td>
        </tr>


        <tr>
            <td class="td_bg_1 border_buttom_td">14</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia1lankyneutren1000000lannam}}</td>
            <td class="td_bg_3 border_buttom_td"> 500 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td">25 </td>

        </tr>

        <tr style="background: paleturquoise;">
            <td class="td_bg_1 border_buttom_td"></td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_CHUKYSOTUXA}}</td>
            <td class="td_bg_3 border_buttom_td"> </td>
            <td class="td_bg_4 border_buttom_td"> </td>
            <td class="td_bg_5 border_buttom_td"> </td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">15</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrong24gio}}</td>
            <td class="td_bg_3 border_buttom_td"> 39_600 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td">100 </td>
            <td class="td_bg_5 border_buttom_td">100</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">16</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrongthang}}</td>
            <td class="td_bg_3 border_buttom_td"> 72_600 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td">100 </td>
            <td class="td_bg_5 border_buttom_td">100</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">17</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia10lankytuxathang}}</td>
            <td class="td_bg_3 border_buttom_td"> 33.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td">100 </td>
            <td class="td_bg_5 border_buttom_td">100</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">18</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia20lankytuxathang}}</td>
            <td class="td_bg_3 border_buttom_td"> 54_400 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td">100 </td>
            <td class="td_bg_5 border_buttom_td">100</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">20</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia50lankytuxathang}}</td>
            <td class="td_bg_3 border_buttom_td"> 132.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td">200 </td>
            <td class="td_bg_5 border_buttom_td">200</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">21</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Gia100lankytuxathang}}</td>
            <td class="td_bg_3 border_buttom_td"> 231.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td">200 </td>
            <td class="td_bg_5 border_buttom_td">200</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">22</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Giakytuxakhongqua200lannam}}</td>
            <td class="td_bg_3 border_buttom_td"> 396.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>
            <td class="td_bg_4 border_buttom_td">200 </td>
            <td class="td_bg_5 border_buttom_td">200</td>

        </tr>

        <tr style="background: #85c8eb;color: white;text-align: center;height: 30px;line-height: 30px;">
            <td class="td_bg_1 border_buttom_td"><b>{{ currentLanguage.body_text_dichvu_cks_STT}}</b></td>
            <td class="td_bg_2 border_buttom_td"><b>{{ currentLanguage.body_text_dichvu_cks_DICHVUCHUKYSORemoteSigning}}</b></td>
            <td colspan="3" class="td_bg_3 border_buttom_td" style="font-size: 12px;line-height: 20px;"><b>{{ currentLanguage.body_text_dichvu_cks_phiVAT}}</b></td>
        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">1</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Chukyso24hKhoitaovakychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong24hketukhicap}}</td>
            <td colspan="3" class="td_bg_3 border_buttom_td"> 39_600 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">2</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Chukysothoihan1thangvoi10luotkychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong30ngayketukhicap}}</td>
            <td colspan="3" class="td_bg_3 border_buttom_td"> 72_600 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">3</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Goibosung10luotkythang}}</td>
            <td colspan="3" class="td_bg_3 border_buttom_td"> 33.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">4</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Goibosung20luotkythang}}</td>
            <td colspan="3" class="td_bg_3 border_buttom_td"> 59_400 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">5</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Goibosung50luotkythang}}</td>
            <td colspan="3" class="td_bg_3 border_buttom_td"> 132.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">6</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Goibosung100luotkythang}}</td>
            <td colspan="3" class="td_bg_3 border_buttom_td"> 231.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>

        </tr>
        <tr>
            <td class="td_bg_1 border_buttom_td">7</td>
            <td class="td_bg_2 border_buttom_td">{{ currentLanguage.body_text_dichvu_cks_Chukyso1namkhongqua200luotnam}}</td>
            <td colspan="3" class="td_bg_3 border_buttom_td"> 396.000 {{ currentLanguage.body_text_dichvu_cks_menhGia}}</td>

        </tr>
    </tbody>
</table>

</div>

</div>
</template>

<script>

import { languages } from "@/components/languages";


export default {
  components: {
 
   
  },
  data() {
    return {
  

      currentLanguage: languages.vi,
      lang: "",
     

    };
  },
  mounted() {
    this.lang = localStorage.getItem("languages");
  },
  created() {
  const savedLanguage = localStorage.getItem("languages");
  if (savedLanguage && languages[savedLanguage]) {
    this.currentLanguage = languages[savedLanguage];
  }
},
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
    window.location.reload();
    },

   
 
  },
};
  </script>