<template>
  <div class="dropdown flex-column d-flex">
    <span class="dropbtn">
      <img :src="imageUrls.iconTyGia" />
      <template v-if="windowWidth >= 750">
        {{ currentLanguage.header_TyGia }}
      </template>
    </span>
    <span style="font-size: 11px"> {{ viewTyGia }} </span>
    <div class="dropdown-content" style="height: auto">
      <span class="p-2 d-flex justify-content-sm-center" style="font-size: 16px; color: black">{{
        currentLanguage.capNhapTyGia }} : {{ timeUpdateTyGia }}
      </span>
      <a style="color: black" @click="selectItem(810)" class="p-2">
        <div class="d-flex">
          <div class="d-flex w-100" style="margin-left: 20px">
            <div class="w-50 d-flex flex-column" style="border-right: 1px burlywood solid">
              <span n style="font-weight: 100">
                {{ currentLanguage.titleDongVietNam }}</span>
            </div>
            <div class="w-50 m-auto">
              <span> </span>
            </div>
            <i v-if="selectedIndex !== null && selectedIndex === 810" class="fa fa-check-circle" style="color: green"
              aria-hidden="true"></i>
          </div>
        </div>
      </a>
      <a v-for="(item, index) in translatedCurrencies" :key="index" @click="selectItem(index)" style="color: black"
        class="p-2">
        <div class="d-flex">
          <!-- <img :src="getCurrencyImage(item.currency)" /> -->
          <div class="d-flex w-100" style="margin-left: 20px">
            <div class="w-50 d-flex flex-column" style="border-right: 1px burlywood solid">
              <!-- <span style="font-weight: 600">{{ item.currency }}</span> -->
              <span style="font-weight: 100">{{ item.name }}</span>
            </div>
            <div class="w-50 m-auto">
              <span>1 {{ item.currency }} =
                {{ formattedPrice(item.muaCK) }} VND</span>
            </div>
          </div>
          <i v-if="selectedIndex !== null && selectedIndex === index" class="fa fa-check-circle" style="color: green"
            aria-hidden="true"></i>
        </div>
      </a>
    </div>
  </div>
</template>
<style>
@media (max-width: 600px) {
  .dropbtn {
    padding: 0 !important;
    /* background-color: rgb(20, 129, 218) !important; */

}
.dropdown-content{
    min-width: 300px !important;
    left: 0 !important;
}
.dropdown-content span{
    font-size: 13px !important;
}
.dropdown-content a{
    padding: 0 !important;
    color: black !important;
    background-color: white !important;
}
}
</style>
<script>
import { imageUrls } from "@/components/js/urlImg";
import { languages } from "@/components/js/langVpos";
import { postLayTiGia } from "@/components/js/VposServiceDanh";

export default {
  data() {
    return {
      imageUrls: imageUrls,
      currentLanguage: languages.vi,
      tyGiaNgoaiTe: [],
      viewTyGia: "",
      selectedIndex: null,
      listStore: [],
      windowWidth: window.innerWidth,
    };
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    // const lang = this.$route.params.lang;
    this.lang = savedLanguage;
    if (languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }

    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  computed: {
    translatedCurrencies() {
      // console.log("aaâ " + this.tyGiaNgoaiTe)
      if (this.lang === "vi") {
        return this.tyGiaNgoaiTe.map((currency) => {
          switch (currency.name) {
            case "US DOLLAR":
              currency.name = "Đô la Mỹ";
              break;
            case "EURO":
              currency.name = "Euro";
              break;
            case "UK POUND STERLING":
              currency.name = "Bảng Anh";
              break;
            case "JAPANESE YEN":
              currency.name = "Yên Nhật";
              break;
            case "AUSTRALIAN DOLLAR":
              currency.name = "Đô la Úc";
              break;
            case "SINGAPORE DOLLAR":
              currency.name = "Đô la Singapore";
              break;
            case "THAI BAHT":
              currency.name = "Baht Thái";
              break;
            case "CANADIAN DOLLAR":
              currency.name = "Đô la Canada";
              break;
            case "SWISS FRANC":
              currency.name = "Franc Thụy Sĩ";
              break;
            case "HONG KONG DOLLAR":
              currency.name = "Đô la Hồng Kông";
              break;
            case "CHINESE YUAN":
              currency.name = "Nhân dân tệ Trung Quốc";
              break;
            case "DANISH KRONE":
              currency.name = "Krone Đan Mạch";
              break;
            case "INDIAN RUPEE":
              currency.name = "Rupi Ấn Độ";
              break;
            case "KOREAN WON":
              currency.name = "Won Hàn Quốc";
              break;
            case "KUWAITI DINAR":
              currency.name = "Dinar Kuwait";
              break;
            case "MALAYSIAN RINGGIT":
              currency.name = "Ringgit Malaysia";
              break;
            case "NORWEGIAN KRONE":
              currency.name = "Krone Na Uy";
              break;
            case "RUSSIAN RUBLE":
              currency.name = "Rúp Nga";
              break;
            case "SAUDI ARABIAN RIYAL":
              currency.name = "Riyal Ả Rập Saudi";
              break;
            case "SWEDISH KRONA":
              currency.name = "Krone Thụy Điển";
              break;
          }
          return currency;
        });
      } else {
        return this.tyGiaNgoaiTe; // Trả về mảng tiền tệ không dịch nếu lang không phải là 'vi'
      }
    },
  },
  mounted() {
    this.layTyGia();
    this.selectedIndex = parseInt(localStorage.getItem("selectedIndex"), 10);
  },
  methods: {
    selectItem(index) {
      this.selectedIndex = index;
      localStorage.setItem("selectedIndex", index);
      if (index === 810) {
        localStorage.setItem("objTyGia", 1);
        this.changeLanguage("vi");
      } else {
        this.changeLanguage(this.getLanguage(this.tyGiaNgoaiTe[index]));
      }
    },
    getLanguage(item) {
      localStorage.setItem("objTyGia", JSON.stringify(item));
      var chonTyGia =
        "1 " + item.currency + " = " + item.muaCK.toLocaleString() + " VND";
      localStorage.setItem("chonTyGia", chonTyGia);
      switch (item.currency) {
        case "USD":
        case "EUR":
          return "en";
        case "CNY":
          return "cn";
        case "JPY":
          return "en";
        case "KRW":
          return "kr";
        default:
          return "en";
      }
    },
    async layTyGia() {
      const res = await postLayTiGia();
      this.tyGiaNgoaiTe = res.result.currency;
      //  console.log(res.result.currency);
      this.timeUpdateTyGia = res.result.timeS;
    },
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },
    formattedPrice(tyGiaVND) {
      if (tyGiaVND !== undefined && tyGiaVND !== null) {
        return tyGiaVND.toLocaleString();
      } else {
        return ""; // Hoặc giá trị mặc định khác phù hợp với ứng dụng của bạn
      }
    },
  },
};
</script>