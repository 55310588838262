<template>
     <p id="demo"></p>
</template>
<script>
export default {
   
  name: 'RedirectComponent',
  mounted() {
    var str = window.navigator.userAgent;
    var str1 = "https://vimass.vn/"; // Default URL

    if (str.indexOf("Android") != -1) {
      str1 = "https://play.google.com/store/apps/details?id=com.vimass.vivimass";
    } else if (str.indexOf("iPhone") != -1) {
      str1 = "https://apps.apple.com/us/app/v%C3%AD-vimass/id959341649";
    }
    
    window.location.href = str1;
    console.log(str);
  }
}

</script>