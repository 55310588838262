<template>
  <div
    style="
      background-color: whitesmoke;
      box-shadow: 0 0 3px #c1ced5;
      height: 100%;
    "
    class="p-3"
  >
    <div class="d-flex flex-column align-items-center">
      <span class="txt--style"> Chọn ảnh</span>
      <div class="drop-zone-img m-2">
        <label
          for="fileInput"
          class="drop-zone__prompt d-flex"
          style="cursor: pointer"
          :style="{ backgroundImage: 'url(' + selectedImage + ')' }"
        >
          <span
            class="m-auto"
            v-if="!selectedImage"
            style="color: #1481da; cursor: pointer"
            >+</span
          >
        </label>
        <input
          id="fileInput"
          type="file"
          class="drop-zone__input"
          ref="fileInput"
          style="display: none"
          accept="image/*"
          @change="handleFileSelected"
          single
        />
      </div>
    </div>
    <div class="d-flex flex-column mt-3">
      <label class="txt--style"> Tên sản phẩm</label>
      <input
        class="rounded input-style input-style--h45px"
        v-model="nameProduct"
      />
    </div>
    <div class="d-flex flex-column mt-3">
      <label class="txt--style"> Mô tả</label>
      <textarea
        class="rounded input-style input-style--h100px"
        v-model="moTaProduct"
      ></textarea>
    </div>
    <div class="d-flex flex-column mt-3">
      <label class="txt--style"> Giá (VND)</label>
      <input
        class="rounded input-style input-style--h45px"
        v-model="giaProduct"
        maxlength="100"
        @input="formattedPrice()"
      />
    </div>
    <div class="d-flex flex-column mt-3">
      <label class="txt--style"> Danh mục</label>
      <select
        class="rounded input-style input-style--h45px"
        v-model="selectedCategoryId"
      >
        <option disabled>Chọn danh mục</option>
        <option
          v-for="itemCate in listCate"
          :key="itemCate.id"
          :value="itemCate.id"
        >
          {{ itemCate.catName }}
        </option>
      </select>
    </div>
    <div class="span--style-h pt-2">
      <span class="fs-5">{{ notification }} </span>
    </div>
    <div class="d-flex justify-content-between w-100 mt-4">
      <input
        class="w50 rounded me-2 input-style input-style--h45px"
        maxlength="6"
        placeholder="Nhập 6 số token"
        v-model="token"
      />
      <button
        v-if="createProduct"
        class="w-50 fs-5 rounded fw-bold btn-style"
        type="button"
        @click="postCreateProduct"
      >
        Thêm sản phẩm
      </button>
    </div>
  </div>
</template>

<script>
import {
  convertToBase64,
  getMD5,
  KEY_ID_STORE,
  KEY_PHONE_ID,
  KEY_SESSION_ID,
  postImage,
  postProduct,
} from "@/components/js/VposService";
import { imageUrls } from "@/components/js/urlImg";
import { postLayCategory } from "@/components/js/VposServiceDanh";
export default {
  props: {
    parentFuncCreate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrls: imageUrls,
      selectedImage: null,
      concatenatedURLs: "",
      nameProduct: "",
      moTaProduct: "",
      giaProduct: 0,
      danhMucProduct: "",
      token: "",
      idCate: "",
      listCate: [],
      listProduct: [],
      createProduct: true,
      updateProduct: false,
      selectedCategoryId: "",
      notification: "",
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    handleFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async postAnh() {
      try {
        const imgURLs = [];
        const value = await convertToBase64(this.selectedImage);
        const idCheck = await getMD5(convertToBase64(this.selectedImage));
        const res = await postImage(value, idCheck);
        imgURLs.push(res.result);
        this.concatenatedURLs = imgURLs.join(";");
      } catch (e) {
        console.log(e);
        alert("Lỗi đẩy ảnh");
      }
    },
    async getCategory() {
      try {
        var sessionLogin = KEY_SESSION_ID;
        var idLogin = KEY_PHONE_ID;
        var idStore = KEY_ID_STORE;
        const res = await postLayCategory(sessionLogin, idLogin, idStore);
        this.listCate = res.result.docs;
      } catch (e) {
        console.log(e);
      }
    },

    async postCreateProduct() {
      try {
        var sessionLogin = KEY_SESSION_ID;
        var idLogin = KEY_PHONE_ID;
        var idStore = KEY_ID_STORE;
        var giaGiam = 0;
        var thumbnail = "";
        try {
          const imgURLs = [];
          const value = await convertToBase64(this.selectedImage);
          const idCheck = await getMD5(convertToBase64(this.selectedImage));
          const resAnh = await postImage(value, idCheck);
          imgURLs.push(resAnh.result);
          this.concatenatedURLs = imgURLs.join(";");

          const res = await postProduct(
            sessionLogin,
            idLogin,
            this.token,
            this.giaProduct.replaceAll(".",""),
            giaGiam,
            idStore,
            this.selectedCategoryId,
            this.nameProduct,
            this.concatenatedURLs,
            thumbnail,
            this.moTaProduct
          );

          // console.log("res postCreateProduct =========>" + JSON.stringify(res));
          if (res.msgCode == 1) {
            this.notification = res.msgContent;
            setTimeout(() => {
              this.notification = "";
              this.selectedImage = null;
              this.nameProduct = "";
              this.moTaProduct = "";
              this.giaProduct = "";
              this.selectedCategoryId = "";
              this.token = "";

              this.parentFuncCreate(this.selectedCategoryId);
            }, 2000);
          } else {
            this.notification = res.msgContent;
          }
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    },
    formattedPrice() {
      console.log(Intl.NumberFormat("vi-VN").format(this.giaProduct));
      this.giaProduct = this.giaProduct.replace(/[.,]/g, "");
      this.giaProduct = Intl.NumberFormat("vi-VN").format(this.giaProduct);
      if (this.giaProduct == 0 || this.giaProduct == "NaN") {
        this.giaProduct = "";
      }
    },
  },
};
</script>
<style scoped>
.drop-zone-img {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 30px;
  cursor: pointer;
  color: #088dc2;
  border: 1px solid #088dc2;
  border-radius: 10px;
}
.drop-zone__prompt {
  display: block;
  /* width: 200px;
  height: 200px; */
  width: 198px;
  height: 198px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 10px;
}
.txt--style {
  text-align: start;
  color: black;
  /* font-weight: bold; */
}
.input-style {
  border: 1px #cbcbcb solid;
}
.input-style--h100px {
  height: 100px;
  padding: 5px 8px 5px 8px;
}
.input-style--h45px {
  height: 45px;
  padding: 5px 8px 5px 8px;
}
</style>