<template>
  <div class="row">
    <div class="column" v-for="(item, index) in items" :key="index">
      <img
        :src="item.src"
        style="width: 100%"
        @click="openModal(index, item.id)"
        class="hover-shadow cursor"
      />
      <p class="mt-3">{{ item.caption }}</p>
    </div>
  </div>


</template>
  
  <script>
import axios from "axios";
import { apiChiTietBaiViet } from "@/components/listService";
export default {
  name: "TestQuiz",
  data() {
    return {
      lang: "",
      showModal: false,
      currentSlide: 0,
      items: [],
      apiUrl: "",
      base64: "",
      base64Title: "",
      //title
      titleHeThong: "",
      //content
      contentHeThong: "",
    };
  },
  mounted() {
    this.fetchData();
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }

    const lang = localStorage.getItem("languages");

    this.lang = lang;
  },
  computed: {
    slides() {
      if (this.items.length === 0) {
        return []; // Return an empty array if items is empty
      } else {
        return this.items
          .filter((item) => item.id !== undefined)
          .map((item) => ({
            src: item.src,
            caption: item.caption,
            lang: item.lang,
            srcChiTiet: item.srcChiTiet
              .split(";")
              .filter((url) => url.trim() !== ""),
            capChiTiet: item.capChiTiet
              .split(";")
              .filter((cap) => cap.trim() !== ""),
          }));
      }
    },
  },
  methods: {
    async fetchData() {
      this.apiUrl =
        "https://sheets.googleapis.com/v4/spreadsheets/1zsSXFf0MMRfyJU3iDr70Te8OjvtkI6M9dFbOnWRwDXw/values/json?key=AIzaSyB6KdsUEG02YYtkYDjFlVA-HrSvs_PULh4";

      try {
        const response = await axios.get(this.apiUrl);
        const lang = localStorage.getItem("languages");
        this.items = response.data.values
          .map((row) => {
            if (row[2] && lang && row[2].trim() === lang.trim()) {
              return {
                id: row[4],
                src: row[0],
                caption: row[1],
                // srcChiTiet: row[2],
                // capChiTiet: row[3],
              };
            }
          })
          .filter((item) => item !== undefined);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async performPostRequest(id) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }
        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        this.titleHeThong = decodeURIComponent(escape(title));
        this.contentHeThong = decodeURIComponent(escape(decodedString));
      } catch (error) {
        console.error("Error:", error);
      }
    },
    openModal(index, id) {
      this.performPostRequest(id);
      this.currentSlide = index;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
  },
};
</script>
  
  <style scoped>
.row > .column {
  padding: 0 5px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 12.5%;
}
.column img {
  width: 100%;
  border: 5px solid #e5e5e5;
  border-radius: 10px;
}
.column p {
  text-align: center;
}
/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fff;
  margin: auto;
  padding: 0;
  width: 100%;
  max-width: 1000px;
}

/* The Close Button */
.close {
  color: #666;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #111;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}
.mySlides img {
  border: 10px solid #e5e5e5;
  border-radius: 20px;
}
.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: #999;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: -42px;
  border-radius: 3px 0 0 3px;
}
.prev {
  left: -42px;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

img {
  margin-bottom: -4px;
}

.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
  display: none;
}

.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
  