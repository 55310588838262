<template>
  <div style="background-color: whitesmoke; height: 100%; box-shadow: 0 0 3px #c1ced5;" class ="p-3">
    <div class="d-flex flex-column align-items-center">
      <span class="txt--style"> Chọn ảnh</span>
      <div class="drop-zone-img m-2">
        <label for="fileInput" class="drop-zone__prompt d-flex" style="cursor: pointer" :style="{ backgroundImage: 'url(' + selectedImage + ')' }" >
          <span class="m-auto" v-if="!selectedImage" style="color: #1481da; cursor: pointer">+</span>
        </label>
        <input id="fileInput" type="file" class="drop-zone__input" ref="fileInput" style="display: none" accept="image/*" @change="handleFileSelected" single/>
      </div>
    </div>
    <div class="d-flex flex-column mt-3" >
      <label class="txt--style"> Tên sản phẩm</label>
      <input class ="rounded input-style input-style--h45px" v-model="nameProduct" />
    </div>
    <div class="d-flex flex-column mt-3">
      <label class="txt--style"> Mô tả</label>
      <textarea class="rounded input-style input-style--h100px"  v-model="moTaProduct"></textarea>
    </div>
    <div class="d-flex flex-column mt-3">
      <label class="txt--style"> Giá (VND)</label>
      <input class ="rounded input-style input-style--h45px"  v-model="giaProduct"  maxlength="100" @input="formattedPrice()"/>
    </div>
    <div class="d-flex flex-column mt-3">
      <label class="txt--style"> Danh mục</label>
      <select class ="rounded input-style input-style--h45px" v-model="selectedCategoryId" >
        <option disabled>Chọn danh mục</option>
        <option v-for="itemCate in listCate" :key="itemCate.id" :value="itemCate.id" >
          {{ itemCate.catName }}
        </option>
      </select>
    </div>
    <div class ="span--style-h pt-2">
            <span class ="fs-5 ">{{notification}} </span> 
        </div>
  <div class="d-flex justify-content-between w-100 mt-4">
    <input class="w50 rounded me-2 input-style input-style--h45px" maxlength="6"  placeholder="Nhập 6 số token" v-model="token"/>
    <button class="w-50 fs-5  rounded-1 fw-bold btn-style" type="button" @click="EditProduct()">
      Sửa sản phẩm
    </button>
  </div>
  </div>
   
</template>

<script>
import { convertToBase64, getMD5, postImage,postEditProduct} from "@/components/js/VposService";
import { imageUrls } from "@/components/js/urlImg";
export default {
 props:{
  itemProductSelect: null,
  parentFuncEdit:{
          type: Function,
          required: true
    },  
   list:{
    type: Array,
    required: true
   }
 },
data() {
  return {
    imageUrls: imageUrls,
    selectedImage: null,
    concatenatedURLs: "",
    nameProduct: "",
    moTaProduct: "",
    giaProduct: 0,
    danhMucProduct: "",
    token: "",
    idCate: "",
    listCate: [],
    selectedCategoryId: "",
    notification: "",
  
  };
},
mounted() {
  // this.getCategory();
},
methods: {
  openFilePicker() {
    this.$refs.fileInput.click();
  },
  handleFileSelected(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result;
      };
      reader.readAsDataURL(file);
    }
  },
    getValue(){
         
      this.nameProduct = this.itemProductSelect.productName;
      this.giaProduct = Intl.NumberFormat("vi-VN").format(this.itemProductSelect.gia);
      this.moTaProduct = this.itemProductSelect.description;
      this.selectedImage = imageUrls.urlAnh2 + this.itemProductSelect.image;
      this.selectedCategoryId = this.itemProductSelect.id;
      this.listCate = this.list;
      //console.log("this.listCate ==========> " + JSON.stringify(this.listCate));
      if (this.listCate.length > 0) {
        for (let i = 0; i <this.listCate.length; i++) {

          // console.log("item ==========> " + JSON.stringify(this.listCate[i]));
         // console.log("item id ==========> " + this.listCate[i].idStore + "/ "+this.itemProductSelect.idStore);
          const str1 = this.itemProductSelect.idCate;
          const str2 = this.listCate[i].id;      
          if(str1 === str2){
             // console.log("true" );
              this.selectedCategoryId = this.listCate[i].id;
            }
        } 
      }
    },

  async postAnh() {
    try {
      const imgURLs = [];
      const value = await convertToBase64(this.selectedImage);
      const idCheck = await getMD5(convertToBase64(this.selectedImage));
      const res = await postImage(value, idCheck);
      imgURLs.push(res.result);
      this.concatenatedURLs = imgURLs.join(";");
      //console.log("this.concatenatedURLs 1=========> "+this.concatenatedURLs);
    } catch (e) {
      console.log(e);
      alert("Lỗi đẩy ảnh");
    }
  },

  async EditProduct() {
    try {

      try {
      const imgURLs = [];
      const value = await convertToBase64(this.selectedImage);
      const idCheck = await getMD5(convertToBase64(this.selectedImage));
      const res = await postImage(value, idCheck);
      imgURLs.push(res.result);
      this.concatenatedURLs = imgURLs.join(";");


        setTimeout(async () => {

          let itemProductEdit ={	
          idStore: this.itemProductSelect.idStore,
          idCate: this.selectedCategoryId,
          productName: this.nameProduct,
          gia:    this.giaProduct.replaceAll(".",""),
          giaGiam: this.itemProductSelect.giaGiam,
          image: this.concatenatedURLs,
          thumbnail: this.itemProductSelect.thumbnail,
          description: this.moTaProduct,
          id: this.itemProductSelect.id,
        };
       // console.log("this.concatenatedURLs 2=========> "+this.concatenatedURLs);
       // console.log("input itemProductEdit =========>" + JSON.stringify(itemProductEdit));

        const res = await postEditProduct( this.token,itemProductEdit,1);
       
        // console.log("res postCreateProduct =========>" + JSON.stringify(res));
        if(res.msgCode == 1){
                this.notification = res.msgContent;
                setTimeout(() => {
                  this.notification ="";
                  this.selectedImage = null;
                  this.nameProduct = "";
                  this.moTaProduct = "";
                  this.giaProduct = "";
                  this.selectedCategoryId = "";
                  this.token = "";
                  this.parentFuncEdit(this.selectedCategoryId);
                }, 1000);
            }else{
                this.notification = res.msgContent;

          }
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  } catch (e) {
      console.log(e);
      alert("Lỗi đẩy ảnh");
    }
  },
  formattedPrice() {
    console.log(Intl.NumberFormat("vi-VN").format(this.giaProduct));
    this.giaProduct = this.giaProduct.replace(/[.,]/g, '');
    this.giaProduct = Intl.NumberFormat("vi-VN").format(this.giaProduct);
    if(this.giaProduct == 0 || this.giaProduct =="NaN"){
      this.giaProduct = "";
    }
  },
},
};
</script>
<style scoped>
.drop-zone-img {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 30px;
  cursor: pointer;
  color: #088dc2;
  border: 1px solid #088dc2;
  border-radius: 10px;
}
.drop-zone__prompt {
  display: block;
  /* width: 200px;
  height: 200px; */
  width: 198px;
  height: 198px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 10px;
}
.txt--style{
text-align: start; color: black; 
/* font-weight: bold; */
}
.input-style{
 border: 1px #cbcbcb solid
}
.input-style--h100px{
height: 100px;
padding: 5px 8px 5px 8px;
}
.input-style--h45px{
height: 45px;
padding: 5px 8px 5px 8px;
}

</style>