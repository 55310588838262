<template>
    <div class="baongoai">
  <div class="rule ">
    <div class="rule__content">
            <div class="rule__color color-1"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  6.666 vnd</strong>
             
            </div>
        </div>
        <div class="rule__content">
            <div class="rule__color color-2"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  16.161 vnd</strong>
            </div>
        </div>
        <div class="rule__content">
            <div class="rule__color color-3"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  20.000 vnd</strong>
            </div>
        </div>
        <div class="rule__content">
            <div class="rule__color color-4"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  33.333 vnd</strong>
            </div>
        </div>
        <div class="rule__content">
            <div class="rule__color color-5"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  36.363 vnd</strong>
            </div>
        </div>
        <div class="rule__content">
            <div class="rule__color color-6"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  38.383 vnd</strong>
            </div>
        </div>
        
        <div class="rule__content">
            <div class="rule__color color-7"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  66.666 vnd</strong>
            </div>
        </div>
        <div class="rule__content">
            <div class="rule__color color-8"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;">  68.686 vnd</strong>
            </div>
        </div>
        <div class="rule__content">
            <div class="rule__color color-9"></div>
            <div class="rule__text">
                <strong style="color: #ffffff;"> CHÚC BẠN MAY MẮN LẦN SAU (5k)</strong>
                
            </div>
        </div>
       
    </div>
    <div class="wheel">
        <div class="wheel__inner">
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
            <div class="wheel__sec">
            </div>
        </div>
        <div class="wheel__arrow">
            <button class="wheel__button">QUAY</button>
        </div>
    </div>
</div>
   
  </template>
  
  <script>
 export default {
  mounted() {
    let value = 0;

    document.querySelector('.wheel__button').addEventListener('click', () => {
   
        const random = Math.floor((Math.random() * 360) + 720);
        value += random;
        document.querySelector(".wheel__inner").style.transform = `rotate(${value}deg)`;
        // setTimeout(() => {
        //   getPosition(value % 360);
        // }, 5000);
      
      
    });
    
    document.querySelector('.congratulation__close').addEventListener('click', () => {
      document.querySelector('.congratulation').style.display = 'none';
    });
    
    document.querySelector('.congratulation').addEventListener('click', (event) => {
      if (event.target == document.querySelector('.congratulation')) {
        document.querySelector('.congratulation').style.display = 'none';
      }
    });
  }
}

  </script>

  

<style>

html, body {
    width: 100%;
    height: 100%;
}

.baongoai {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-left: 50px;
    flex-direction: column;
    background-color: rgb(98, 181, 170);
    font-family: 'Open sans', sans-serif;
}

.rule {
    margin: 50px 0 0 0;
}

.rule__content {
    display: flex;
}

.rule__color {
    width: 25px;
    height: 25px;
    margin: 0 20px 20px 0;
}

.color-1 {
    background-color: #8e44ad;
}

.color-2 {
    background-color: #2980b9;
}

.color-3 {
    background-color: #34495e;
}

.color-4 {
    background-color: #f39c12;
}

.color-5 {
    background-color: #d35400;
}

.color-6 {
    background-color: #aeaeae;
}
.color-7 {
    background-color: #18bc8b;
}
.color-7 {
    background-color: #a01616;
}
.color-8 {
    background-color: #16a085;
}
.color-9 {
    background-color: #ff0000;
}

.wheel {
    width: 312px;
    height: 312px;
    border-radius: 50%;
    border: solid 6px #fff;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    position: relative;
}

.wheel__inner {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 5s;
}

.wheel__sec {
    position: absolute;
    top: 0;
    left: 62px;
    width: 0;
    height: 0;
    border: solid;
    border-width: 150px 88px 0;
    border-color: transparent;
    transform-origin: 50% 100%;
}

.wheel__sec:nth-child(1) {
  border-top-color: #16a085;
}

.wheel__sec:nth-child(2) {
  border-top-color: #ff0000;
  transform: rotate(40deg);
}

.wheel__sec:nth-child(3) {
  border-top-color: #34495e;
  transform: rotate(80deg);
}

.wheel__sec:nth-child(4) {
  border-top-color: #f39c12;
  transform: rotate(120deg);
}

.wheel__sec:nth-child(5) {
  border-top-color: #d35400;
  transform: rotate(160deg);
}

.wheel__sec:nth-child(6) {
  border-top-color: #aeaeae;
  transform: rotate(200deg);
}

.wheel__sec:nth-child(7) {
  border-top-color: #a01616;
  transform: rotate(240deg);
}

.wheel__sec:nth-child(8) {
  border-top-color: #3498db;
  transform: rotate(280deg);
}

.wheel__sec:nth-child(9) {
  border-top-color: #8e44ad;
  transform: rotate(320deg);
}
.wheel__text {
    margin: 70px 0 0 70px;
}

.wheel__arrow {
    width: 70px;
    height: 70px;
    background: #fff;
    position: absolute;
    top: 121px;
    left: 115px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open sans', sans-serif;
}

.wheel__arrow::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: solid;
    border-width: 0 10px 20px;
    border-color: transparent;
    border-bottom-color: #fff;
    top: -15px;
    left: 25px;
}

.wheel__button {
    width: 60px;
    height: 60px;
    background-color: lightgray;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 600;
    font-size: 18px;
}

.wheel__button:hover {
    color: #27AE60;
}

.wheel__button:active {
    border: solid 3px rgba(0, 0, 0, 0.1);
    font-size: 15px;
}

.popup {
    position: fixed;
    width: 30vw;
    top: 0;
    left: 50%;
    transform: translate(-50%, -110%);
    background: #fff;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    transition: all 0.5s;
    opacity: 0;
}

.popup.active {
    transform: translate(-50%, 0);
    opacity: 1;
}

.popup__emotion {
    color: #f39c12;
    text-align: center;
    font-size: 30px;
    margin: 0 0 25px 0;
}

.popup__note {
    text-align: center;
}

.congratulation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: none;
}

.congratulation__container {
    width: 40vw;
    padding: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.congratulation__close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #c0392b;
    cursor: pointer;
    transition: all 0.5s;
}

.congratulation__close:hover {
    transform: rotate(360deg);
}

.congratulation__emotion {
    color: #f39c12;
    text-align: center;
    margin: 0 0 20px 0;
}

.congratulation__note {
    text-align: center;
}



</style>