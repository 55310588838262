<template>
   <nav class="nav_center2" style="padding: 0; background: cadetblue">
    </nav>
    <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <div class="wrapper row3">
        <main class="hoc container-fluid clear bv">
          <!-- gioithieu -->
          <div
            id="gioithieu"
            v-show="showGioiThieu"
            style="min-height: 500px"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleGioiThieu"></div>
            <div v-html="contentGioiThieu"></div>
          </div>
<!-- bidv -->
          <div
            id="bidv"
            v-show="showBIDV"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleBIDV"></div>
            <div v-html="contentBIDV"></div>
          </div>
<!-- vpb -->
          <div
            id="vcard"
            v-show="showVPB"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleVPB"></div>
            <div v-html="contentVPB"></div>
          </div>
 <!-- nab -->
          <div
            id="nab"
            v-show="showNAB"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
          <div class="title-bai-viet" v-html="titleNAB"></div>
            <div v-html="contentNAB"></div>
        </div>
 <!-- tdn -->
          <div id="tpb" v-show="showTPB"  style="min-height: 500px; background-image: none !important"  class="padding-top15" >
            <div class="title-bai-viet" v-html="titleTPB"></div>
            <div v-html="contentTPB"></div>
          </div>

        </main>
      </div>
    </main>
  </div>

</template>
<script>
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";

export default {
  components: {
  },
  data() {
    return {
      activeTab: 'gioiThieu',

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showGioiThieu: true,
      showBIDV: false,
      showVPB: false,
      showNAB: false,
      showTPB:false,
      
      //active tab
      isActiveGioiThieu: true,
      isActiveBIDV: false,
      isActiveVPB: false,
      isAcitveNAB: false,
      isActiveTPB:false,

      //title
      titleGioiThieu: "",
      titleBIDV: "",
      titleVPB: "",
      titleNAB: "",
      titleTPB:"",

      //content
      contentGioiThieu: "",
      contentBIDV: "",
      contentVPB: "",
      contentNAB:"",
      contentTPB:"",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("languages");

    this.performPostRequest("1695975526923tLcPE", "gioiThieu", this.lang);
    // document.getElementById('urlGioithieu').setAttribute('href', this.lang + 'giaiphapchuyendoiso#nganhangtaitro/');
    // document.getElementById('urlBIDV').setAttribute('href', this.lang + 'giaiphapchuyendoiso#nganhangtaitro/');
    // document.getElementById('urlVPB').setAttribute('href', this.lang + 'giaiphapchuyendoiso#nganhangtaitro/');
    // document.getElementById('urlNAB').setAttribute('href', this.lang + 'giaiphapchuyendoiso#nganhangtaitro/');
    // document.getElementById('urlTPB').setAttribute('href', this.lang + 'giaiphapchuyendoiso#nganhangtaitro/');

  },
  created() {
  const savedLanguage = localStorage.getItem("languages");
  if (savedLanguage && languages[savedLanguage]) {
    this.currentLanguage = languages[savedLanguage];
  }
},
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
    window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        }else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "gioiThieu") {
          this.titleGioiThieu = decodeURIComponent(escape(title));
          this.contentGioiThieu = decodeURIComponent(escape(decodedString));
        } else if (section === "bidv") {
          this.titleBIDV = decodeURIComponent(escape(title));
          this.contentBIDV = decodeURIComponent(escape(decodedString));
        }else if (section === "vpb") {
          this.titleVPB = decodeURIComponent(escape(title));
          this.contentVPB = decodeURIComponent(escape(decodedString));
        }else if (section === "nab") {
          this.titleNAB = decodeURIComponent(escape(title));
          this.contentNAB = decodeURIComponent(escape(decodedString));
        }else if (section === "tpb") {
          this.titleTPB = decodeURIComponent(escape(title));
          this.contentTPB = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    changeTab(tab) {
      this.lang = localStorage.getItem("languages");

      if (tab === 'gioiThieu') {
        this.isActiveGioiThieu = true;
        this.isActiveBIDV = false;
        this.isActiveVPB = false;
        this.isAcitveNAB = false;
        this.isAcitveNAB =false;
        this.performPostRequest("1695975526923tLcPE", 'gioiThieu', this.lang);

      } else if (tab === 'bidv') {
        this.isActiveGioiThieu = false;
        this.isActiveBIDV = true;
        this.isActiveVPB = false;
        this.isAcitveNAB = false;
        this.isAcitveNAB =false;
        this.performPostRequest("1695982264026RwBuC", 'bidv', this.lang);

      } else if (tab === 'vpb') {
        this.isActiveGioiThieu = false;
        this.isActiveBIDV = false;
        this.isActiveVPB = true;
        this.isAcitveNAB = false;
        this.isAcitveNAB =false;
        this.performPostRequest("1695982390574jVQqM", 'vpb', this.lang);

      } else if (tab === 'nab') {
        this.isActiveGioiThieu = false;
        this.isActiveBIDV = false;
        this.isActiveVPB = false;
        this.isAcitveNAB = true;
        this.isAcitveNAB =false;
        this.performPostRequest("1695982320075SRLNe", 'nab', this.lang);

      }else if (tab === 'tpb') {
        this.isActiveGioiThieu = false;
        this.isActiveBIDV = false;
        this.isActiveVPB = false;
        this.isAcitveNAB = false;
        this.isAcitveNAB =true;
        this.performPostRequest("1695985573908vc2yi", 'tpb', this.lang);
      }
   

      this.activeTab = tab; // Cập nhật giá trị activeTab

      this.showGioiThieu = tab === 'gioiThieu';
      this.showBIDV = tab === 'bidv';
      this.showVPB = tab === 'vpb';
      this.showNAB = tab === 'nab';
      this.showTPB = tab === 'tpb';
    },
 
  },
};
</script>