<template style="font-family:system-ui">
    <div class="container-fluid p-0">
        <div class="row">
            <header-vpos v-if="windowWidth > 750"></header-vpos>

            <header v-if="windowWidth < 750" class="d-flex" style="background-color: rgb(0, 122, 187); cursor: pointer">
                <tiGia id="tiGia" style="text-align: center; line-height: 2.2;width: 25%;margin: 0"></tiGia>
                <div class="col-3" style="text-align: center; line-height: 2.2" @click="bamVaoTrangChu()">
                    <img class="" :src="iconChiTiet.cuaHangChung" alt="Cửa hàng chung" />
                </div>
                <!-- <img class="" :src="iconChiTiet.logoQuocKi" alt="Ngôn ngữ" /> -->
                <div class="col-3" style="text-align: center; line-height: inherit; position: relative"
                    @click="bamVaoGioHang()">
                    <img class="" :src="iconChiTiet.gioHang" alt="Giỏ hàng" />
                    <span v-if="gioHang.length > 0"
                        style=" position: absolute; color: white; background-color: red; border-radius: 136px; padding: 1px; left: 57%; bottom: 60%; ">{{
                            soLuongTrongGioHang }}</span>
                </div>


                <a href="/vpos" class="col-3" style="text-align: center; line-height: inherit">
                    <img class="" :src="iconChiTiet.trangChu" alt="Trang bán hàng" />
                </a>
            </header>
            <div class="row p-0 m-auto">
                <div id="SanPham" v-if="(windowWidth < 750 && statusTrangChu) || windowWidth > 750"
                    :class="divSanPhamClass">
                    <div v-if="windowWidth < 750" style="background-color: white">
                        <div class="container-fluid m-0">
                            <div class="row no-gutters" style="background-color: white">
                                <div class="d-flex justify-content-center align-items-center m-2"
                                    v-for="thongTinCuaHang in thongTinCuaHangs" :key="thongTinCuaHang.id">
                                    <!-- Tên cửa hàng, căn giữa -->
                                    <h5 style="font-family: system-ui" class="m-0">
                                        {{ thongTinCuaHang.tenCuaHang }}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="w-100">
                            <ul class="ulCateGory">
                                <li class="">
                                    <button id="nutTatCa" class="button m-2 category" href="#"
                                        @click="selectCategory()">
                                        {{ currentLanguage.chiTietCuaHang_tatCa }}
                                    </button>
                                </li>
                                <li class="" v-for="category in categories" :key="category.id">
                                    <button :id="`button-${category.id}`" v-if="category.soLuongSanPham"
                                        class="button m-2 category" href="#" @click="selectCategory(category)">
                                        {{ category.name }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div class="container-fluid p-0" style="overflow: auto; height: 90vh">
                        <div class="w-100">
                            <ul :class="ulClass">
                                <li :class="liSanPham" v-for="sanPham in item" :key="sanPham.id">
                                    <button :id="sanPham.id" class="d-flex flex-column m-1"
                                        @click="themVaoGioHang(sanPham)"
                                        style="position: relative;border-style: none;min-height: 50%;border-radius: 10px;background-color: rgb(249, 249, 249);color: black;align-items: center;border-color: rgb(249, 249, 249);font-family: system-ui;"
                                        href="#">
                                        <img :style="styleSanPham" loading="lazy"
                                            :src="'https://web.vimass.vn/VimassMedia/services/VMMedia/thumnail?id=' + sanPham.anhMon + chatLuongAnh" />

                                        <span
                                            style="font-weight: 600; display: block; width: 100%; margin-top: 1rem;max-width: 80%;min-height: 40px;max-height: 41px; ">
                                            {{ sanPham.tenMon }}
                                        </span>


                                        <span
                                            :style="styleGiaMon">
                                            {{ sanPham.moTaMon }}
                                        </span>
                                        <div style="font-weight: 600; color: red; font-size: large">
                                            <span class="p-2">{{ formatTien(sanPham.giaMon) }}</span>
                                            <span v-if="statusTienViet">VND</span>
                                        </div>
                                        <div v-if="statusTienViet"
                                            style="font-weight: 600; color: black; font-size: large">
                                            <span class="p-2">{{
                                                formatTienKhac(sanPham.giaMon / objTyGia.giaTri)
                                                }}</span>
                                            <span>{{ objTyGia.vietTat }}</span>
                                        </div>
                                        <div style="position: absolute; left: 90%">
                                            <button
                                                style=" background-color: red; color: white; border-radius: 100px; padding-left: 5px; padding-right: 5px; border-style: none; "
                                                v-if="getSoLuongById(sanPham.id) > 0">
                                                {{ getSoLuongById(sanPham.id) }}
                                            </button>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="
                    (windowWidth < 750 && statusGioHang && gioHang.length > 0) ||
                    (windowWidth > 750 && statusGioHang)
                " :class="divGiohang" style="border-width: 1px; border-style: ridge">
                    <div class="row m-2" v-for="thongTinCuaHang in thongTinCuaHangs" :key="thongTinCuaHang.id">
                        <span class="col-12" style="font-weight: 600; font-family: system-ui; font-size: 20px">{{
                            thongTinCuaHang.tenCuaHang }}
                        </span>
                        <span class="col-12" style="font-family: system-ui; font-size: 13px">{{
                            thongTinCuaHang.diaChi }}
                        </span>
                        <span v-if="thongTinCuaHang.idHienThi > 0" class="col-12"
                            style="font-family: system-ui; font-size: 13px">{{ currentLanguage.chiTietCuaHang_maCuaHang
                                +
                                thongTinCuaHang.idHienThi }}
                        </span>
                    </div>
                    <div class="gio-hang-popup summary" style="cursor: pointer">
                        <!-- Loop qua mỗi sản phẩm trong giỏ hàng và hiển thị thông tin -->
                        <div v-for="sanPham in gioHang" :key="sanPham.id" class="san-pham-trong-gio"
                            style="font-size: larger">
                            <div v-if="shouldShow(sanPham.id)">
                                <div class="row container-fluid m-2 p-1 mb-0">
                                    <span
                                        style=" font-weight: 400; font-family: system-ui; text-align: left; line-height: 2; "
                                        class="col-9">
                                        {{ sanPham.tenMon }}</span>
                                    <i class="fa fa-trash-o fa-2x col-3" style="text-align: right" aria-hidden="true"
                                        @click="xoaSanPhamNay(sanPham.id)"></i>
                                </div>
                                <div class="row container-fluid my-0 m-2 p-1">
                                    <div class="col-3 d-flex justify-content-start"
                                        style="color: rgb(20, 129, 218); text-align: left">
                                        <div class="col-4" aria-hidden="true" @click="thayDoiSoLuong(sanPham, -1)">
                                            <img :src="iconChiTiet.dauTru" alt="dấu cộng" />
                                        </div>
                                        <span class="col-4" style="text-align: center; font-size: larger">{{
                                            sanPham.soLuong }}</span>
                                        <div style="text-align: center" class="col-4" aria-hidden="true"
                                            @click="thayDoiSoLuong(sanPham, 1)">
                                            <img :src="iconChiTiet.dauCong" alt="dấu trừ" />
                                        </div>
                                    </div>
                                    <div class="col-9" style="text-align: right">
                                        <span>
                                            {{ formatTien(sanPham.giaMon * sanPham.soLuong) }}
                                        </span>
                                        <span v-if="statusTienViet"> {{ " VND" }}</span>
                                    </div>
                                </div>
                                <div v-if="statusTienViet" class="row container-fluid my-0 m-2 p-1">
                                    <div class="col-12" style="text-align: right">
                                        <span>
                                            {{
                                                formatTienKhac(
                                                    (sanPham.giaMon * sanPham.soLuong) / objTyGia.giaTri
                                                )
                                            }}</span>
                                        <span> {{ " " + objTyGia.vietTat }}</span>
                                    </div>
                                </div>
                            </div>

                            <!-- Các phần tử khác như số lượng, giá, nút tăng/giảm, v.v. -->
                        </div>
                    </div>
                    <div class="tongGiaTien">
                        <img :src="iconChiTiet.duongKe" alt="" />
                        <div v-if="TongTien > 0" class="row container-fluid p-2">
                            <span class="col-3"
                                style=" font-family: system-ui; font-weight: 700; font-size: 19px; text-align: left; ">{{
                                    currentLanguage.chiTietCuaHang_Tong }}</span>
                            <div class="col-9"
                                style=" font-family: system-ui; font-weight: 700; font-size: larger; text-align: right; ">
                                <span>{{ formatTien(TongTien) }}</span>
                                <span v-if="statusTienViet"> {{ " VND" }}</span>
                            </div>
                        </div>
                        <div v-if="TongTien > 0" class="row container-fluid p-2">
                            <div v-if="statusTienViet" class="col-12"
                                style=" font-family: system-ui; font-weight: 700; font-size: larger; text-align: right; ">
                                <span>{{ formatTienKhac(TongTienKhac) }}</span>
                                <span> {{ " " + objTyGia.vietTat }}</span>
                            </div>
                        </div>
                        <!-- <div v-if="gioHang.length > 0" class="d-flex">
              <div style="text-align: left" class="my-2 col-3">
                <button
                  style="background-color: white; color: blue"
                  class="button"
                  @click="khuyenMai()"
                >
                  {{ currentLanguage.chiTietCuaHang_khuyenMai }}
                </button>
              </div>
              
            </div> -->

                        <div class="d-flex justify-content-around">
                            <button class="w-25 m-2" :style="{
                                background:
                                    currentButton === 'hoadon' ? '#1481da' : '#eeeeee',
                                color: currentButton === 'hoadon' ? 'white' : 'black',
                                border: 'none',
                                height: '35px',
                            }" @click="setCurrentButton('hoadon')">
                                {{ currentLanguage.chiTietCuaHang_hoaDon }}
                            </button>
                            <button class="w-25 m-2" :style="{
                                background:
                                    currentButton === 'giaoHang' ? '#1481da' : '#eeeeee',
                                color: currentButton === 'giaoHang' ? 'white' : 'black',
                                border: 'none',
                                height: '35px',
                            }" @click="setCurrentButton('giaoHang')">
                                {{ currentLanguage.chiTietCuaHang_giaoHang }}
                            </button>
                            <button class="w-25 m-2" :style="{
                                background:
                                    currentButton === 'khuyenmai' ? '#1481da' : '#eeeeee',
                                color: currentButton === 'khuyenmai' ? 'white' : 'black',
                                border: 'none',
                                height: '35px',
                            }" @click="setCurrentButton('khuyenmai')">
                                {{ currentLanguage.chiTietCuaHang_khuyenMai }}
                            </button>
                        </div>



                        <div class="d-flex flex-column" v-if="vHoaDon">
                            <input v-model="mstValue" class="p-1" type="text" style="height: 40px"
                                :placeholder="currentLanguage.chiTietCuaHang_maSoThueNhanHoaDon" />
                            <input v-model="emailValue" class="p-1" type="text" style="height: 40px"
                                :placeholder="currentLanguage.chiTietCuaHang_emailNhanHoaDon" />
                            <input v-model="sdtValue" class="p-1" type="text" style="height: 40px"
                                :placeholder="currentLanguage.chiTietCuaHang_dienThoai" />
                        </div>
                        <div class="d-flex flex-column" v-if="statusGiaoHang">
                            <input v-model="sdtGiaoHangValue" class="p-1" type="text" style="height: 40px"
                                :placeholder="currentLanguage.chiTietCuaHang_dienThoaiGiaoHang" />
                            <input v-model="diaChiGiaoHangValue" class="p-1" type="text" style="height: 40px"
                                :placeholder="currentLanguage.chiTietCuaHang_diaChiGiaoHang" />
                            <input v-model="phiGiaoHangValue" class="p-1" type="text" style="height: 40px"
                                :placeholder="currentLanguage.chiTietCuaHang_phiGiaoHang" />
                        </div>
                        <div v-if="statusKhuyenMai" class="row col-9" style="display: contents;">
                            <div class="col-9 d-flex m-auto">
                                <input v-model="khuyenMaiValue" class="p-1" type="text" style="height: 40px"
                                    :placeholder="currentLanguage.chiTietCuaHang_SDTvaEmail" />
                                <button class="button mt-2 w-25" style="height: 40px !important; margin-left: 10px;"
                                    type="button" @click="traCuuDiemThuong">
                                    {{ currentLanguage.chiTietCuaHang_tim }}
                                </button>
                            </div>
                            <!-- <div class="col-3 py-2" style="text-align: right ; height: 40px;">
                  <button class="button" >
                    {{ currentLanguage.chiTietCuaHang_tim }}
                  </button>
                </div> -->
                        </div>
                        <button @click="taoBill(TongTien)" class="button"
                            style=" background-color: rgb(20, 129, 218); min-width: 100%; height: 50px !important; ">
                            {{ currentLanguage.chiTietCuaHang_datHang }}
                        </button>
                    </div>
                </div>
                <div v-if="statusTaoBill" class="col-12 col-md-3" style="border-width: 1px; border-style: ridge">
                    <div id="billDonHang">
                        <div class="gio-hang-popup summary" style="cursor: pointer">
                            <!-- Loop qua mỗi sản phẩm trong giỏ hàng và hiển thị thông tin -->
                            <div class="row m-2" v-for="thongTinCuaHang in thongTinCuaHangs" :key="thongTinCuaHang.id">
                                <span class="col-12"
                                    style=" font-weight: 600; font-family: system-ui; font-size: 20px;">{{
                                        thongTinCuaHang.tenCuaHang }}</span>
                                <span class="col-12" style="font-family: system-ui; font-size: 13px">{{
                                    thongTinCuaHang.diaChi }}</span>
                                <span v-if="thongTinCuaHang.idHienThi > 0" class="col-12"
                                    style="font-family: system-ui; font-size: 13px">{{
                                        currentLanguage.chiTietCuaHang_maCuaHang
                                        + thongTinCuaHang.idHienThi }}
                                </span>
                            </div>
                            <div v-for="sanPham in gioHang" :key="sanPham.id" class="san-pham-trong-gio m-2"
                                style="font-size: larger">
                                <div class="row container-fluid my-0 py-0 m-2 p-2">
                                    <div class="d-flex">
                                        <span
                                            style=" font-weight: 400; font-family: system-ui; text-align: left; line-height: 2; "
                                            class="col-8">{{ ` ${sanPham.tenMon} ` }}</span>

                                        <div class="col-4"
                                            style=" text-align: right; font-weight: 400; font-family: system-ui; "
                                            aria-hidden="true">
                                            <div class="d-flex justify-content-between">
                                                <span style="font-weight: 500">
                                                    {{ `x ${sanPham.soLuong} ` }}</span>
                                                <div class="d-flex">
                                                    <span>
                                                        {{
                                                            formatTien(sanPham.soLuong * sanPham.giaMon)
                                                        }}</span>
                                                    <span v-if="statusTienViet"> {{ " VND" }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row container-fluid my-0 py-0 m-2 p-2">
                                    <div v-if="statusTienViet" class="col-12"
                                        style=" text-align: right; font-weight: 700; font-family: system-ui; "
                                        aria-hidden="true">
                                        <span>
                                            {{
                                                formatTienKhac(
                                                    (sanPham.giaMon * sanPham.soLuong) / objTyGia.giaTri
                                                )
                                            }}</span>
                                        <span> {{ " " + objTyGia.vietTat }}</span>
                                    </div>
                                </div>
                                <!-- Các phần tử khác như số lượng, giá, nút tăng/giảm, v.v. -->
                            </div>
                        </div>
                        <div class="tongGiaTien">
                            <img :src="iconChiTiet.duongKe" alt="" />
                            <div class="row container-fluid p-2">
                                <span class="col-6"
                                    style=" font-family: system-ui; font-weight: 700; font-size: 19px; text-align: left; ">{{
                                        currentLanguage.chiTietCuaHang_Tong }}</span>
                                <div class="col-6"
                                    style=" font-family: system-ui; font-weight: 700; font-size: 19px; text-align: right; ">
                                    <span>{{ formatTien(TongTien) }}</span>
                                    <span v-if="statusTienViet"> {{ " VND" }}</span>
                                </div>
                            </div>
                            <div class="row container-fluid p-2">
                                <div v-if="statusTienViet" class="col-12"
                                    style=" font-family: system-ui; font-weight: 700; font-size: 19px; text-align: right; ">
                                    <span>{{ formatTienKhac(TongTien / objTyGia.giaTri) }}</span>
                                    <span> {{ " " + objTyGia.vietTat }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="bill">
                            <div class="row container-fluid p-2">
                                <span class="col-4"
                                    style=" font-family: system-ui; font-weight: 400; font-size: 13px; text-align: left; ">{{
                                        currentLanguage.chiTietCuaHang_donHang }}</span>
                                <span class="col-8"
                                    style=" font-family: system-ui; font-weight: 400; font-size: 13px; text-align: right; ">{{
                                        bill.idHienThi }}</span>
                            </div>
                        </div>
                        <div class="qr-bill">
                            <div class="container-fluid p-2">
                                <div id="qrThanhToan" style="position: relative">
                                    <img id="qrCodeImage" class="col-6"
                                        style=" font-family: system-ui; font-weight: 700; font-size: larger; text-align: center; " />
                                    <img style="position: absolute; left: 46%; top: 40%" :src="iconChiTiet.vietqr"
                                        alt="" />
                                </div>
                                <div v-if="false" class="d-flex justify-content-center m-2">
                                    <button style="background-color: rgb(20, 129, 218); color: white" class="button"
                                        @click="downloadQRImage()">
                                        Tải xuống
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div id="duoiBill">
                        <div class="d-flex justify-content-center m-4">
                            <button class="col-4"
                                style="text-align: center; line-height: inherit;background-color: white;border-style: none;"
                                @click="downloadQRImage()">
                                <img class="" :src="iconChiTiet.taixuong" alt="Ngôn ngữ" />
                            </button>

                            <div class="col-4" style="text-align: center; line-height: inherit">
                                <img class="" :src="iconChiTiet.indai" alt="Ngôn ngữ" />
                            </div>
                            <div class="col-4" style="text-align: center; line-height: inherit">
                                <img class="" :src="iconChiTiet.inngan" alt="Ngôn ngữ" />
                            </div>
                        </div>

                        <div v-if="statusDangNhap" class="d-flex justify-content-around">
                            <button class="w-25 m-2" :style="{
                                background:
                                    setBillButtons === 'tienMat' ? '#1481da' : '#eeeeee',
                                color: setBillButtons === 'tienMat' ? 'white' : 'black',
                                border: 'none',
                                height: '35px',
                            }" @click="setBillButton('tienMat')">
                                {{ currentLanguage.chiTietCuaHang_tienMat }}
                            </button>
                            <button class="w-25 m-2" :style="{
                                background:
                                    setBillButtons === 'theVimass' ? '#1481da' : '#eeeeee',
                                color: setBillButtons === 'theVimass' ? 'white' : 'black',
                                border: 'none',
                                height: '35px',
                            }" @click="setBillButton('theVimass')">
                                {{ currentLanguage.chiTietCuaHang_theVimass }}
                            </button>
                        </div>
                        <div v-if="statusTienMat" id="thanhToanTienMat" class="row">
                            <input v-model="tokenValue" class="p-1 col-9 m-1" type="text"
                                style="  width: 70% !important;" :placeholder="currentLanguage.chiTietCuaHang_token" />
                            <button class="col-3 button p-1 m-1">{{ currentLanguage.chiTietCuaHang_xacNhan }}</button>
                        </div>
                        <div class="d-flex flex-column"
                            v-if="mstValue.length > 0 || emailValue.length > 0 || sdtValue.length > 0">
                            <div class="d-flex justify-content-between" v-if="mstValue.length > 0">
                                <span>{{ currentLanguage.chiTietCuaHang_maSoThueNhanHoaDon }}</span>
                                <span>{{ mstValue }}</span>
                            </div>
                            <div class="d-flex justify-content-between" v-if="emailValue.length > 0">
                                <span>{{ currentLanguage.chiTietCuaHang_emailNhanHoaDon }}</span>
                                <span>{{ emailValue }}</span>
                            </div>
                            <div class="d-flex justify-content-between" v-if="sdtValue.length > 0">
                                <span>{{ currentLanguage.chiTietCuaHang_dienThoai }}</span>
                                <span>{{ sdtValue }}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center m-2">
                            <button style=" background-color: rgb(20, 129, 218); color: white; min-width: 80px; "
                                class="button m-2" @click="quayLai()">
                                {{ currentLanguage.chiTietCuaHang_quayLai }}
                            </button>

                            <!-- <button style="background-color: red ;color: white;min-width: 80px" class="button m-2"
                                    @click="huy()">{{ currentLanguage.chiTietCuaHang_huy }}</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.category {
    font-weight: 300;
    border-color: black;
    border-style: ridge;
    border-width: 1px;
    border-radius: 10px;
    background-color: rgb(249, 249, 249);
    color: black;
}

.category:hover {
    font-weight: 300;
    border-color: black;
    border-style: ridge;
    border-width: 1px;
    border-radius: 10px;
    background-color: rgb(20, 129, 218) !important;
    color: white;
}

.activeSanPham {
    border-style: groove !important;
    border-color: red !important;
}

.activee {
    background-color: rgb(20, 129, 218);
    color: white;
}

#headerChiTiet {
    display: none;
}

.ulCateGory {
    display: flex;
    flex-wrap: wrap;
}
</style>


<script>
import md5 from "md5"; // Giả sử bạn đã cài đặt và import thư viện md5
import QRCode from "qrcode";
import html2canvas from "html2canvas";
import { imageUrlsChiTietCuaHang } from "@/components/js/urlImgChiTietCuaHang";
import {
    postLayCategory,
    postLayItem,
    postLayDsStore,
    postTaoBill,
    postLayTiGia,
} from "@/components/js/VposServiceDanh";
import { b64EncodeUnicode } from "@/components/VimassCrop";
import HeaderVpos from "/src/views/VPOS/Home/HeaderVpos/HeaderVpos.vue";
import tiGia from "/src/views/VPOS/Home/HeaderVpos/TyGiaCompoment";
import { languages } from "@/components/js/langVpos";
import { KEY_PHONE_ID, KEY_SESSION_ID, postTraCuuDiemThuong } from '@/components/js/VposService';
var sessionLogin = "";
var idLogin = "";
var accOwner = "";
var dataBill;
class ObjectVPosItemOrder {
    constructor(
        idProduct,
        productName,
        sl,
        price1Item,
        priceDiscount1Item,
        total
    ) {
        this.idProduct = idProduct;
        this.productName = b64EncodeUnicode(productName); // Mã hóa Base64 cho tên sản phẩm
        this.sl = sl;
        this.price1Item = price1Item;
        this.priceDiscount1Item = priceDiscount1Item;
        this.total = total;
        this.cksInput = this.generateCksInput(); // Tạo CKS input bằng method
    }

    generateCksInput() {
        // Giả sử bạn có thể sử dụng một hàm md5 đã được import để tạo hash
        return md5(
            "0819226669jdqpowrifioefiqo3289r79f" +
            this.idProduct +
            this.sl +
            this.price1Item +
            this.priceDiscount1Item +
            this.total
        );
    }
}
export default {
    props: ["idStore", "idBanAn"],
    components: {
        HeaderVpos,
        tiGia
    },
    data() {
        return {
            donHang: "Đơn hàng",
            tongTien: 0,
            bistyle: "font-family:system-ui;font-weight: 700;",
            iconChiTiet: imageUrlsChiTietCuaHang,
            categories: [],
            item: [],
            gioHang: [],
            thongTinCuaHangs: [],
            statusGioHang: false,
            statusTrangChu: true,
            statusCuaHangChung: false,
            statusTaoBill: false,
            statusTienMat: false,
            statusTheVimass: false,
            sdtValue: "",
            mstValue: "",
            khuyenMaiValue: "",
            sdtGiaoHangValue: "",
            diaChiGiaoHangValue: "",
            phiGiaoHangValue: "",
            emailValue: "",
            bill: {},
            tiGia: [],
            windowWidth: window.innerWidth,
            divSanPhamClass: "", // Giá trị mặc định ban đầu
            styleSanPham: "", // Giá trị mặc định ban đầu
            soLuongTrongGioHang: "", // Giá trị mặc định ban đầu
            currentLanguage: languages.vi,
            objTyGia: {},
            statusTienViet: false,
            statusCatgory: false,
            statusKhuyenMai: false,
            currentButton: null,
            vHoaDon: false,
            userDiem: "",
            setBillButtons: "",
            statusDangNhap: false,
            chatLuongAnh: '',
            styleGiaMon:''
        };
    },
    created() {
        const savedLanguage = localStorage.getItem("languages");
        // const lang = this.$route.params.lang;
        this.lang = savedLanguage;
        if (languages[savedLanguage]) {
            this.currentLanguage = languages[savedLanguage];
        }

        if (savedLanguage && languages[savedLanguage]) {
            this.currentLanguage = languages[savedLanguage];
        }
    },
    computed: {
        TongTien() {
            return this.gioHang.reduce(
                (tong, sanPham) => tong + sanPham.giaMon * sanPham.soLuong,
                0
            );
        },
        TongTienKhac() {
            return this.gioHang.reduce(
                (tong, sanPham) =>
                    tong + (sanPham.giaMon / this.objTyGia.giaTri) * sanPham.soLuong,
                0
            );
        },
        ulClass() {
            // Nếu chiều rộng màn hình nhỏ hơn hoặc bằng 640px, thêm 'd-flex flex-wrap'
            return this.windowWidth > 640
                ? "d-flex flex-wrap justify-content-start p-3"
                : "d-flex flex-wrap justify-content-start";
        },
        ulCateGory() {
            // Nếu chiều rộng màn hình nhỏ hơn hoặc bằng 640px, thêm 'd-flex flex-wrap'
            return this.windowWidth > 640
                ? "list-unstyled d-flex flex-wrap justify-content-center"
                : "list-unstyled d-flex flex-wrap justify-content-start";
        },
        liSanPham() {
            return this.windowWidth > 640 ? "col-2" : "col-4";
        },
        divSanPham() {
            return this.windowWidth > 640 ? "col-md-12" : "col-12 col-md-9";
        },
        divGiohang() {
            return this.windowWidth > 640 ? "col-md-3" : "col-12 col-md-3";
        },
    },
    mounted() {
        this.LayThongTinLogin();
        this.getCategories();
        this.updateDivSanPhamClass(); //Class Sản phẩm ban đầu
        this.updateStyleSanPhamClass(); //Class Sản phẩm ban đầu
        this.updateGiaMon(); //Class Sản phẩm ban đầu
        this.capNhatChatLuongAnh();
        if (this.idStore) {
            console.log("Đã lấy được ID cửa hàng " + this.idStore);
        }
        // Tự động click nút đầu tiên khi component được mount
        const firstButton = document.getElementById("nutTatCa");
        if (firstButton) {
            firstButton.click();
        }
        if (this.windowWidth > 750) {
            this.statusGioHang = true;
        }
        this.CapNhatTyGia();
    },
    methods: {


        setCurrentButton(button) {
            this.currentButton = button;
            console.log(button);
            switch (button) {
                case "hoadon":
                    this.vHoaDon = true;
                    this.statusKhuyenMai = false;
                    this.statusGiaoHang = false;
                    break;
                case "khuyenmai":
                    this.vHoaDon = false;
                    this.statusKhuyenMai = true;
                    this.statusGiaoHang = false;
                    break;
                case "giaoHang":
                    this.vHoaDon = false;
                    this.statusKhuyenMai = false;
                    this.statusGiaoHang = true;
                    break;
            }
        },
        lay5SoCuoi(inputString) {
            if (inputString) {
                const lastFourDigits1 = inputString.slice(-5);
                return lastFourDigits1;
            } else {
                console.error("Chuỗi không được định nghĩa.");
            }
        },

        lay4SoCuoi(inputString) {
            const lastFourDigits = inputString.slice(-4);
            return lastFourDigits;
        },
        updateDivSanPhamClass() {
            this.divSanPhamClass =
                this.windowWidth > 640 ? "col-md-9  p-0" : "col-12 p-0";

        },
        updateStyleSanPhamClass() {
            this.styleSanPham =
                this.windowWidth > 640 ? "border-top-left-radius:5px; border-top-right-radius:5px; width: 100%;height: 200px;min-width:200px" : "border-top-left-radius:5px; border-top-right-radius:5px; width: 100%;height: 130px;min-width:130px";

        },
        async thayDoiTiGia() {
            try {
                var resTiGia = await postLayTiGia();
                if (resTiGia && resTiGia.result && resTiGia.result.currency) {
                    this.tiGia = resTiGia.result.currency.map((doc) => ({
                        DonViTien: doc.currency,
                        TenLoaiTien: doc.name,
                        giaMuaTienMat: doc.muaTienMat,
                        giaMuaCK: doc.muaCK,
                        giaBanRa: doc.ban,
                    }));
                } else {
                    console.log("Không có tài liệu nào hoặc thiếu dữ liệu.");
                }
            } catch (error) {
                console.error("thayDoiTiGia catch: ", error);
            }
        },
        async getCategories() {
            try {
                // Gọi dịch vụ API để lấy danh sách các danh mục
                // Giả sử bạn gọi một API và nhận về kết quả dưới dạng JSON
                const dataThongTinCuaHang = await postLayDsStore(
                    sessionLogin,
                    idLogin,
                    accOwner,
                    this.idStore
                );
                if (
                    dataThongTinCuaHang &&
                    dataThongTinCuaHang.result &&
                    dataThongTinCuaHang.result.docs
                ) {
                    this.thongTinCuaHangs = dataThongTinCuaHang.result.docs.map(
                        (doc) => ({
                            id: doc.id,
                            tenCuaHang: doc.storeName,
                            diaChi: doc.storeAdd, // Lưu thêm thông tin CKS nếu cần
                            idHienThi: doc.VMStoreIndex
                        })
                    );
                } else {
                    console.log("Không có tài liệu nào hoặc thiếu dữ liệu.");
                }
                if (this.thongTinCuaHangs.length < 1) {
                    const thongTinLayTuCuaHangChung = JSON.parse(localStorage.getItem("objThongTinCuaHang"));
                    if (thongTinLayTuCuaHangChung) {
                        this.thongTinCuaHangs.push({
                            id: thongTinLayTuCuaHangChung.id,
                            tenCuaHang: thongTinLayTuCuaHangChung.storeName,
                            diaChi: thongTinLayTuCuaHangChung.storeAdd,
                            idHienThi: thongTinLayTuCuaHangChung.VMStoreIndex
                        });
                    }
                }

                const dataCategory = await postLayCategory(
                    sessionLogin,
                    idLogin,
                    this.idStore
                );
                if (dataCategory && dataCategory.result && dataCategory.result.docs) {
                    this.categories = dataCategory.result.docs.map((doc) => ({
                        id: doc.id,
                        name: doc.catName,
                        cks: doc.cks, // Lưu thêm thông tin CKS nếu cần
                        soLuongSanPham: false,
                    }));
                    for (const category of this.categories) {
                        const dataItem = await postLayItem(
                            sessionLogin,
                            idLogin,
                            this.idStore,
                            category.id
                        );
                        if (
                            dataItem.result.docs !== null &&
                            dataItem.result.docs.length > 0
                        ) {
                            category.soLuongSanPham = true;
                        }
                    }
                } else {
                    console.log("Không có tài liệu nào hoặc thiếu dữ liệu.");
                }
            } catch (error) {
                console.log("Lỗi rồi anh em ơi:" + error);
            }
        },
        async selectCategory(category = null) {
            var dataItem;
            try {
                // Xóa class 'activee' khỏi tất cả các button
                const allButtons = document.querySelectorAll('.category');
                allButtons.forEach(button => {
                    button.classList.remove('activee');
                });

                // Thêm class 'activee' cho button hiện tại
                const currentButtonId = category ? `button-${category.id}` : 'nutTatCa';
                const currentButton = document.getElementById(currentButtonId);
                if (currentButton) {
                    currentButton.classList.add('activee');
                }

                if (category) {
                    // Xử lý khi người dùng chọn một danh mục cụ thể
                    dataItem = await postLayItem(
                        sessionLogin,
                        idLogin,
                        this.idStore,
                        category.id
                    );
                } else {
                    // Xử lý cho nút "Tất cả"
                    dataItem = await postLayItem(sessionLogin, idLogin, this.idStore, "");
                }
                if (dataItem && dataItem.result && dataItem.result.docs) {
                    this.item = dataItem.result.docs.map((doc) => ({
                        id: doc.id,
                        tenMon: doc.productName,
                        anhMon: doc.image,
                        moTaMon: doc.description,
                        giaMon: doc.gia,
                        giaKhuyenMai: doc.giaGiam,
                        soLuong: 1,
                        cks: doc.cks,
                    }));
                    return true;
                } else {
                    console.log("Không có tài liệu nào hoặc thiếu dữ liệu.");
                }
                console.log("Category selected:", dataItem);
            } catch (error) {
                console.log("selectCategory catch " + error);
            }
        },
        themVaoGioHang(item) {
            try {
                // Xóa class 'active' khỏi tất cả các button trong giỏ hàng trước khi thêm sản phẩm mới
                this.xoaAcTiveSanPham();

                // Nếu sản phẩm đã có trong giỏ hàng, thêm class 'active' vào button của sản phẩm đó
                const button = document.getElementById(item.id);
                if (button) {
                    button.classList.add("activeSanPham");
                }

                this.statusTaoBill = false;
                //Thay đổi class sản phẩm
                if (this.windowWidth > 750) {
                    this.divSanPhamClass = "col-md-9 p-0";
                    this.statusGioHang = true;
                }

                const index = this.gioHang.findIndex(
                    (sanPham) => sanPham.id === item.id
                );
                if (index === -1) {
                    this.gioHang.push({ ...item, soLuong: 1 });
                } else {
                    this.thayDoiSoLuong(this.gioHang[index], 1); // Gọi thayDoiSoLuong để tăng số lượng
                }
                this.updateSoLuongTrongGioHang();
            } catch (error) {
                console.log("themVaoGioHang catch " + error);
            }
        },
        thayDoiSoLuong(sanPham, giaTri) {
            this.xoaActivceSanPhamBangKhong();
            const index = this.gioHang.indexOf(sanPham);
            if (index !== -1) {
                const soLuongMoi = this.gioHang[index].soLuong + giaTri;
                if (soLuongMoi > 0) {
                    this.gioHang[index].soLuong = soLuongMoi;
                } else {
                    this.xoaAcTiveSanPhamDuocChon(sanPham.id);
                    this.gioHang.splice(index, 1); // Xoá sản phẩm khỏi giỏ hàng nếu số lượng là 0
                }
            }
            //Thay đổi class sản phẩm
            if (this.gioHang.length < 1) {
                if (this.windowWidth > 750) {
                    this.divSanPhamClass = "col-md-9 p-0";
                } else if (this.windowWidth < 750) {
                    this.quayVeTrangMuaHang();
                }
            }
            this.updateSoLuongTrongGioHang();
        },
        currencyFormat(value) {
            return new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
            }).format(value);
        },
        bamVaoGioHang() {
            try {
                this.statusGioHang = true;
                this.statusTrangChu = false;
                this.statusCuaHangChung = false;
                this.statusTaoBill = false;
            } catch (error) {
                console.log("bamVaoGioHang catch " + error);
            }
        },
        bamVaoCuaHangChung() {
            try {
                this.statusGioHang = false;
                this.statusTrangChu = false;
                this.statusCuaHangChung = true;
                this.statusTaoBill = false;
            } catch (error) {
                console.log("bamVaoCuaHangChung catch " + error);
            }
        },
        bamVaoTrangChu() {
            try {
                this.statusGioHang = false;
                this.statusTrangChu = true;
                this.statusCuaHangChung = false;
                this.statusTaoBill = false;
            } catch (error) {
                console.log("bamVaoTrangChu catch " + error);
            }
        },
        shouldShow(productId) {
            return this.gioHang.some((sanPham) => sanPham.id === productId);
        },
        xoaSanPhamNay(productId) {
            try {
                this.xoaAcTiveSanPhamDuocChon(productId);
                this.gioHang = this.gioHang.filter(
                    (sanPham) => sanPham.id !== productId
                );
                //Thay đổi class sản phẩm
                if (this.gioHang.length < 1) {
                    if (this.windowWidth > 750) {
                        this.divSanPhamClass = "col-md-9 p-0";
                    } else if (this.windowWidth < 750) {
                        this.quayVeTrangMuaHang();
                    }
                }
                this.updateSoLuongTrongGioHang();
            } catch (error) {
                console.log("xoaSanPhamNay catch " + error);
            }
        },
        generateQRAdvanced(text) {
            const options = {
                errorCorrectionLevel: "H",
                type: "image/jpeg",
                quality: 0.92,
                margin: 1,

                width: 200, // Chiều rộng của QR Code
            };

            QRCode.toDataURL(text, options, function (err, url) {
                if (err) throw err;

                // Cập nhật thẻ img hiện có thay vì tạo mới
                const imgElement = document.getElementById("qrCodeImage");
                imgElement.src = url;
            });
        },
        async taoBill(tongTien) {
            try {
                if (this.gioHang.length > 0) {
                    this.statusGioHang = false;
                    this.statusTrangChu = false;
                    this.statusCuaHangChung = false;
                    this.statusTaoBill = true;
                    const listOrder = this.gioHang.map((sanPham) => {
                        return new ObjectVPosItemOrder(
                            sanPham.id,
                            sanPham.tenMon,
                            sanPham.soLuong,
                            sanPham.giaMon,
                            sanPham.giaKhuyenMai,
                            sanPham.soLuong * sanPham.giaMon
                        );
                    });
                    dataBill = await postTaoBill(
                        sessionLogin,
                        idLogin,
                        this.idStore,
                        listOrder,
                        this.mstValue,
                        this.emailValue,
                        this.sdtValue,
                        tongTien,
                        this.sdtGiaoHangValue,
                        this.diaChiGiaoHangValue
                    );
                    console.log("dsfdf" + JSON.stringify(dataBill));
                    if (dataBill) {
                        console.log("Giá trị msgCode: ", dataBill.msgCode);
                        if (dataBill.msgCode === 1) {
                            this.bill = {
                                id: dataBill.result.id,
                                textQR: dataBill.result.textQR,
                                maQR: dataBill.result.maQR,
                                idHienThi: dataBill.result.billId
                            };
                            this.generateQRAdvanced(this.bill.textQR);
                        } else {
                            alert(dataBill.msgContent);
                            this.statusGioHang = true;
                            this.statusTrangChu = false;
                            this.statusCuaHangChung = false;
                            this.statusTaoBill = false;
                        }
                    } else {
                        console.log("dataBill hoặc dataBill.result không tồn tại.");
                    }

                } else {
                    alert(this.currentLanguage.chiTietCuaHang_chuaCoSanPhamNao);
                }
            } catch (error) {
                console.log("taoBill catch " + error);
            }
        },
        downloadQRImage() {
            const element = document.getElementById("billDonHang"); // Lấy phần tử div bạn muốn chụp

            html2canvas(element, { scale: 1 })
                .then((canvas) => {
                    // Tạo URL cho hình ảnh canvas
                    const image = canvas.toDataURL("image/png", 1.0);

                    // Tạo một liên kết tạm thời để tải hình ảnh
                    const link = document.createElement("a");
                    link.href = image;
                    link.download = "qr-thanh-toan.png"; // Đặt tên file ảnh muốn tải xuống
                    link.click();
                })
                .catch((err) => {
                    console.error("Failed to capture the QR image:", err);
                });
        },
        quayLai() {
            try {
                this.statusGioHang = true;
                this.statusTrangChu = false;
                this.statusCuaHangChung = false;
                this.statusTaoBill = false;
            } catch (error) {
                console.error("quayLai error :", error);
            }
        },
        huy() {
            try {
                // Xóa tất cả sản phẩm trong giỏ hàng
                this.gioHang = [];

                // Cập nhật các trạng thái
                if (this.windowWidth > 750) {
                    this.statusGioHang = true;
                    this.statusTrangChu = false;
                    this.statusCuaHangChung = false;
                    this.statusTaoBill = false;
                } else if (this.windowWidth < 750) {
                    this.quayVeTrangMuaHang();
                }
                this.updateSoLuongTrongGioHang();
                //this.quayVeTrangMuaHang();
                //Thay đổi class sản phẩm
                this.divSanPhamClass = "col-md-9 p-0";
            } catch (error) {
                console.error("quayLai error :", error);
            }
        },
        updateSoLuongTrongGioHang() {
            try {
                this.soLuongTrongGioHang = this.gioHang.reduce((tong, sanPham) => {
                    return tong + sanPham.soLuong;
                }, 0);
            } catch (error) {
                console.error("updateSoLuongTrongGioHang error:", error);
            }
        },
        quayVeTrangMuaHang() {
            this.statusGioHang = false;
            this.statusTrangChu = true;
            this.statusCuaHangChung = false;
            this.statusTaoBill = false;
        },
        formatTien(tien) {
            return new Intl.NumberFormat("vi-VN").format(tien).replace(".", ",");
        },
        formatTienKhac(tien) {
            return new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(tien);
        },
        CapNhatTyGia() {
            try {
                var tyGia = localStorage.getItem("objTyGia");
                if (tyGia == null) {
                    this.statusTienViet = false;
                    this.objTyGia = {
                        vietTat: "VND",
                        loaiTien: "Việt Nam Đồng",
                        giaTri: 1,
                    };
                } else {
                    if (tyGia == 1) {
                        this.statusTienViet = false;
                        this.objTyGia = {
                            vietTat: "VND",
                            loaiTien: "Việt Nam Đồng",
                            giaTri: 1,
                        };
                    } else {
                        this.statusTienViet = true;
                        var tyGiaObj = JSON.parse(tyGia);
                        this.objTyGia = {
                            vietTat: tyGiaObj.currency,
                            loaiTien: tyGiaObj.name,
                            giaTri: tyGiaObj.muaCK,
                        };
                    }
                }
            } catch (error) {
                console.error("CapNhatTyGia error:", error);
            }
        },
        LayThongTinLogin() {
            try {
                const jsonThongTinDangNhap = localStorage.getItem("user");
                if (jsonThongTinDangNhap) {
                    this.statusDangNhap = true;
                    const objectDangNhap = JSON.parse(jsonThongTinDangNhap);
                    if (objectDangNhap) {
                        sessionLogin = objectDangNhap.secssion;
                        idLogin = objectDangNhap.id;
                        accOwner = idLogin;
                    }
                } else {
                    this.statusDangNhap = false;
                }
            } catch (error) {
                console.error("CapNhLayThongTinLoginatTyGia error:", error);
            }
        },
        getSoLuongById(id) {
            const item = this.gioHang.find((item) => item.id === id);
            return item ? item.soLuong : 0; // Trả về số lượng nếu tìm thấy, ngược lại trả về 0
        },
        xoaAcTiveSanPham() {
            try {
                for (const sanPham of this.item) {
                    const button = document.getElementById(sanPham.id);
                    if (button) {
                        button.classList.remove("activeSanPham");
                    }
                }
            } catch (error) {
                console.error("xoaAcTiveSanPham error:", error);
            }
        },
        khuyenMai() {
            try {
                if (this.statusKhuyenMai) {
                    this.statusKhuyenMai = false;
                } else {
                    this.statusKhuyenMai = true;
                }
            } catch (error) {
                console.error("khuyenMai error:", error);
            }
        },
        xoaActivceSanPhamBangKhong() {
            try {
                // Create a Set to store the IDs of products in the cart for quick lookup
                const idsInCart = new Set(this.gioHang.map((sanPham) => sanPham.id));

                // Create an array to store IDs of products not in the cart
                const idsNotInCart = [];

                // Iterate over items and check if they are not in the cart
                for (const sanPham of this.item) {
                    if (!idsInCart.has(sanPham.id)) {
                        idsNotInCart.push(sanPham.id); // Add ID to the list
                    }
                }
                for (const sanPhamDaXoa of idsNotInCart) {
                    const button = document.getElementById(sanPhamDaXoa);
                    if (button) {
                        // Ensure the button exists before trying to modify it
                        button.classList.remove("activeSanPham");
                    }
                }
                // You can use idsNotInCart array for further processing if needed
            } catch (error) {
                console.error("xoaActiveSanPhamKhongCoTrongGioHang error:", error);
            }
        },
        xoaAcTiveSanPhamDuocChon(productId) {
            try {
                const item = this.gioHang.find((item) => item.id === productId);
                if (item) {
                    const button = document.getElementById(productId);
                    if (button) {
                        button.classList.remove("activeSanPham");
                    }
                }
            } catch (error) {
                console.error("xoaAcTiveSanPham error:", error);
            }
        },

        async traCuuDiemThuong() {
            var sessionLogin = KEY_SESSION_ID;
            var idLogin = KEY_PHONE_ID;
            const res = await postTraCuuDiemThuong(sessionLogin, idLogin, accOwner, this.khuyenMaiValue, this.idStore)
            console.log(res);
        },
        setBillButton(button) {
            this.setBillButtons = button;
            switch (button) {
                case "tienMat":
                    this.statusTienMat = true;
                    this.statusTheVimass = false;
                    break;
                case "theVimass":
                    this.statusTienMat = false;
                    this.statusTheVimass = true;
                    break;
            }
        },
        capNhatChatLuongAnh() {
            this.chatLuongAnh =
                this.windowWidth > 640 ? "&size=300" : "&size=200";
        },
        updateGiaMon(){
            this.styleGiaMon = 
            this.windowWidth > 640 ? "padding: 2px; width: 100%; word-wrap: break-word;min-height: 39px;max-height: 40px;" : "padding: 2px; width: 100%; word-wrap: break-word;min-height: 55px;max-height: 56px;";
        }
    },
};
</script>