<template>

    <div class ="row m-0 h-100 p-0">
        <div class="col-12 d-flex flex-wrap pt-1 ">

            <div class="d-flex item-category rounded p-2 m-2 fw-bold " @click.prevent="getProduct('',-1)" :class="{ 'activeCate': activeIndex === -1 }" >Tất cả</div>
            <div v-for="(itemCate,index) in listCate" :key="index" class="d-flex item-category rounded p-2 m-2 fw-bold"
             @click.prevent="getProduct(itemCate.id, index)"  :class="{ 'activeCate': activeIndex === index }">{{itemCate.catName}}</div>
        </div>

        <div class="col-12 col-lg-9 p-0" style="height: 80vh; box-shadow: 0 0 3px 0 #dee2e6; overflow-y:auto " >
            <div class="d-flex justify-content-star w-100 flex-wrap" >
            
                <div v-for="(item,index) in listProduct" :key="index" @click.prevent="funcEdit(item, index)"  class="d-flex item-product pt-3">
                    <div class ="d-flex justify-content-center align-items-center flex-column p-2 m-3 rounded item-product__bround w-100"  :class="{ 'acticeSelectProduct': activeItemProductSelect === index }">
                        <div class="d-flex item-product__bround__img">
                            <img :src="getImg(item.image)" class ="img" >
                        </div>
                        <span class ="flex-shrink--1 fw-bold">{{ item.productName }}</span>
                        <span class ="flex-shrink--1 ">{{ item.description }}</span>
                        <span class ="flex-shrink--1 fw-bold" style="color: red;">{{ formatTien(item.gia)}}</span>
                    </div>
                    <div class ="item-product__Delete rounded-circle d-flex justify-content-center algin-item-center" @click="deleteProduct(item)">
                        <i class="fa fa-times-circle icon" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class ="btn-add-category col-12 pt-3 pb-3">
            <button class=" fw-bold fs-5 btn-style w-50" @click.prevent="showComponentAdd"> Thêm sản phẩm </button>
         </div>

        <div class="col-12 col-lg-3 p-0 ">
            <div class ="screen" :style="{'display': isScreen }"> 
                  <CreateProduct v-if ="fromAdd" :parentFuncCreate="reload"/>
                   <EditProduct ref="editValue" v-if ="fromEdit" :itemProductSelect = "selectedItem" :list ="listCate" :parentFuncEdit="reload" />
            </div>
        </div>
    
        <DeleteProductVue v-if="fromDel" :itemDel="selectedItem" :parentFuncDel="reload" />

    </div>
</template>

<script>
  import { imageUrls } from "@/components/js/urlImg";
  import { postLayItem, postReceiveListCategory} from "@/components/js/VposService";
  import CreateProduct from "./CreateProduct.vue";
  import DeleteProductVue from "./DeleteProduct.vue";
  import EditProduct  from "./EditProduct.vue";
export default {
    setup() {   
    },
    components:{
        CreateProduct, DeleteProductVue, EditProduct
    },
    data() {
        return {  
            imageUrls: imageUrls, listProduct:[], listCate:[], fromDel: false, fromEdit:false, fromAdd: true, selectedItem: null, activeIndex: null ,
             activeItemProductSelect: null, isScreen: "", isScreenEdit: ""
        };
    },  mounted() {
        this.getCategory();
        this.getProduct("",-1);

        window.addEventListener('click', this.handleWindowClick);
    
    },
    
    beforeUnmount(){
    // Remove event listener when component is about to be unmounted
      window.removeEventListener('click', this.handleWindowClick);
    },

    methods:{

        async getProduct(category, index) {

            var response;
            var InputIdStore= localStorage.getItem('idCuaHang');
            try {
                if (category) {
                    response = await postLayItem(InputIdStore,category );
                } else {
                    response = await postLayItem(InputIdStore, "");
                }

                console.log("response: " + JSON.stringify(response));

                this.listProduct = response.result.docs;

                this.activeItemProductSelect = null

                this.activeIndex = null;
                this.activeIndex = index;

                this.fromAdd = true;
                this.fromEdit = false;
                if(this.activeIndex == -1){
                    this.activeIndex = index;
            }
                
             console.log("docs ======>" +JSON.stringify(response.result.docs));
                
            } catch (error) {
                console.log("selectCategory catch " + error);
            }
        },
        async getCategory() {
            try{
            var InputIdStore = localStorage.getItem('idCuaHang');
           // console.log("InputIdStore ========> " + this.InputIdStore);
            const response = await postReceiveListCategory(InputIdStore, 0, 1000);
           // console.log("response ========>"+ JSON.stringify(response.result.docs));
            this.listCate = response.result.docs;
            }catch(e){
                console.log("Exception e:",e);
            }
        },
        getImg(image){
          return imageUrls.urlAnh2 + image;
        },
        deleteProduct(item){
          //console.log("item product delete ========> "+ item);
          this.selectedItem = item;
          this.fromDel = true;
        },
        
        handleWindowClick(event) {
          if (this.fromDel && event.target.className ==="hideFromDel posDel-1__1"
            || this.fromDel && event.target.className ==="f-login-style"
            || this.fromDel && event.target.className ==="d-flex justify-content-between"
            || this.fromDel && event.target.className ==="d-flex justify-content-between w-50"
            || this.fromDel && event.target.className ==="m-auto"
            || this.fromDel && event.target.className ==="fa fa-search"
            || this.fromDel && event.target.className ==="m-auto input-search"
            || this.fromDel && event.target.className ==="nav-menu2-vpos"
            || this.fromDel && event.target.className ==="fa fa-bell-o"
            ) {
                    this.fromDel = false;
  
              }
        },  
        reload(idCategory){
            this.getProduct(idCategory);
            this.fromDel = false;
            this.fromAdd = true;
            this.fromEdit = false;
          },
        formatTien(tien) {
            
         return new Intl.NumberFormat("vi-VN").format(tien);
        },
        funcEdit(item, index){
            this.isScreen = "block";
            this.activeItemProductSelect = null;
            this.activeItemProductSelect = index;

           this.fromEdit = true;
           this.fromAdd = false;
           this.selectedItem = item;
        
           setTimeout(() => {
                this.$refs.editValue.getValue();
                  }, 500);
        },
        showComponentAdd(){
          this.isScreen = "block";
        },
        reset(){
            this.getProduct("",-1);
        }        
    }
}
</script>
<style scoped>
.flex-shrink--1{
    flex-shrink: 1
}

span {
  display: inline-block; 
  max-width: 183px; /* hoặc bất kỳ giá trị nào bạn muốn */
  overflow: hidden;
  text-overflow: ellipsis; /* hiển thị "..." nếu vượt quá kích thước */
  white-space: nowrap; /* không ngắt dòng */
}
.item-category{
    /* color: white; background-color: rgb(63, 63, 175); cursor: pointer; */
    color: #2b2525; background-color: #e5e5e5; cursor: pointer; 
}
.item-category:hover {
  background-color: #0d30979e;
  color: white;
}
.item-product__bround{
background-color: white; width: 50%;border: 3px solid #f1efef00; box-shadow: 0 0 15px #c1ced5;
}

.item-product__Delete{
    position: absolute;  left: 88%; cursor: pointer; background: white;  top: 25px;
}
.activeCate {
  background-color: #007abb; color: white;
}
.acticeSelectProduct{
    border: 3px solid #dc3545;
}
.item-product__bround__img{
  background-color: white; flex-shrink: 1; width: 100%;height: 200px;
}
.img{
    width: 100%; height: auto;
     /* max-height:200px; */
}


@media (max-width: 767.98px){
    .item-product{
        position: relative; cursor: pointer; width: 50%; justify-content: center;
    }
    .item-product__bround__img{
        height: auto;
        min-height: 150px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .item-product{
        position: relative; cursor: pointer; width: 33.3%; justify-content: center;
    }
    .icon{
         font-size: 2rem; color: red;
    }
}

@media (min-width: 992px) and (max-width: 1299.98px) {
    .item-product{
        position: relative; cursor: pointer; width: 25%; justify-content: center;
        
    }

}

@media (min-width: 1300px) {
    .item-product{
        position: relative; cursor: pointer; width: 20%; justify-content: center;
        
    }

}
</style>