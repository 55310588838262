<template>
    <div class="inputWithIcon">
      <input type="text" placeholder="Nhập code OTP" v-model="otp" maxlength ="6"/>
      <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
    </div>

    <div>
        <span class ="fs-5 " id ="notificationText">{{notification }}</span>
    </div>
    <button @click="comfirmOTP" class="btn int btn-outline-primary mt-5">
       Xác nhận
    </button>

</template>
<script>
import {postComfrimPhoneOTP } from '@/components/js/VposService';
export default {
    props: ['phoneProp'],
    data() {
        return {  
            otp: "",
            notification:""
        };
    },
 
    methods:{

        async comfirmOTP(){
        try{
            const response = await postComfrimPhoneOTP(this.phoneProp, this.otp);
          //  alert("response ========>"+ JSON.stringify(response));
           
            if( response.msgCode == 1){
                this.notification = response.msgContent;
                this.$router.push("/vpos");
            }else{
                this.notification = "Thất bại";
            }
           

            }catch(e){
                console.log("Exception e:",e);
            }
        },
    
    }
}
</script>
