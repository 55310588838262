<template>
  <div class="container-login d-flex flex-column">
    <form class="content"  v-if="fromDKByEmail">
      <img :src="imageUrls.logoVpassXanh" class="p-5" style="width: 300px" />
      <br />
      <div class="inputWithIcon">
        <input
          type="text"
          placeholder="Nhập email"
          v-model="email"
        />
        <i class="fa fa-envelope fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <div class="inputWithIcon">
        <input type="password" placeholder="Mật khẩu đăng nhập (6 - 25 ký tự) " v-model="pass"/>
        <i class="fa fa-lock fa-lg fa-fw" aria-hidden="true"></i>
      </div>
      <br />
      <button @click="registerByEmail" type="submit " class="btn int btn-outline-primary mt-5">
        Đăng ký
      </button>
    </form>
  </div>
  <div >
      <span class ="fs-5 ">{{ Notification }}</span>
  </div>
  <div v-if="fromComfrimOTP">
    <comfirmOTP/>
  </div>
 
</template>

<script>
import { imageUrls} from "@/components/js/urlImg";
// import {getIpWan} from "@/components/js/VposService";
import {getIpWan, postResgisterByEmail} from "@/components/js/VposService";
import comfirmOTP from "@/views/VPOS/LoginEmail/confirmOTP.vue";
export default{
   components: { comfirmOTP },
    data() {
      return {
        imageUrls: imageUrls,
        email: "",
        pass: "",
        ipWan:"",
        fromDKByEmail: true,
        fromComfrimOTP: false,
        Notification: ""
      };
  },
  mounted(){
    this.getIpWan();
  },
  methods:{
    async registerByEmail(event){
    
      try{
        event.preventDefault();
        const response = await postResgisterByEmail(this.email, this.pass, this.ipWan);
      //  alert("postResgisterByEmail res ==========> " + JSON.stringify(response));
        this.Notification = response.msgContent;
        this.fromDKByEmail = false;
        this.fromComfrimOTP = true;
      }catch(e){
        console.log("Exception e:",e);
      }
    },
    async getIpWan(){

        try{
          const res = await getIpWan();
          this.ipWan = res;
          //alert("postResgisterByEmail res ==========> " + this.ipWan);
        }catch(e){
          console.log("Exception e:",e);
        }
    }
  }
}
</script>