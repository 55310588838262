<template>
  <main class="hoc container-fluid clear" style="padding: 0">
    <header>
      <label for="toggle-1" class="toggle-menu"
        ><ul>
          <li></li>
          <li></li>
          <li></li></ul
      ></label>
      <input type="checkbox" id="toggle-1" />
      <nav class="nav_center">
        <ul style="list-style: none">
          <li class="hethong topmenuactive">
            <a class="urlHeThong" href="">Thống kê</a>
          </li>
        </ul>
      </nav>
      <div class="menu_right" style="display: flex">
        <span class="p-2"> <strong>Vienchuvan</strong></span>
        <span
          ><a id="logoutVpass" style="color: #ffffff" href="#"
            ><img
              loading="lazy"
              style="width: 35px; height: 35px"
              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1703847431836IT3rF"
          /></a>
        </span>
      </div>
    </header>
  </main>
  <div class="container-fluid" style="height: 600px; overflow: auto">
    <div class="col-12 col-md-12 p-3">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="d-flex justify-content-between">
            <select class="boldtext" style="height: 35px; width: 48%">
              <option>Tất cả</option>
            </select>
            <select
              class="boldtext"
              id="idSelectThietBi"
              style="height: 35px; width: 48%"
            >
              <option value="">Tất cả</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="w-100 d-flex justify-content-between">
            <div class="w-50 d-flex">
              <span class="p-2"> Từ </span>
              <!-- @*<input id="timeTo" type="date" style="height: 35px;" value="">*@ -->
              <label for="fromDate"></label>
              <input type="text" id="fromDate" style="height: 35px" value="" />
            </div>
            <div class="w-50 d-flex">
              <span class="p-2"> Đến </span>
              <!-- @*<input id="timeDate" type="date" style="height:35px; ">*@ -->
              <label for="toDate"></label>
              <input type="text" id="toDate" style="height: 35px" value="" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="w-100 d-flex">
            <div class="w-50">
              <input
                class="w-100"
                placeholder="Nhập thông tin tìm kiếm "
                style="height: 35px"
              />
            </div>
            <div class="w-50 ms-lg-2">
              <button
                id="btnTraCuu"
                type="submit"
                style="
                  height: 35px;
                  border: none;
                  background: #00caff;
                  width: 30%;
                  border-radius: 10px;
                  color: white;
                "
              >
                Tra cứu
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="d-flex phanTrang" id="phanTrangDataGiaoDich"></div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-12" style="height: auto">
      <div class="framer_table_cks">
        <table
          cellspacing="0"
          border="0"
          v-if="listThongKe && listThongKe.length"
        >
          <tbody class="tbody_table_cks">
            <tr
              style="
                background: #85c8eb;
                color: white;
                text-align: center;
                height: 30px;
                line-height: 30px;
              "
            >
              <td class="td_bg_1 border_buttom_td"><b>STT</b></td>
              <td class="td_bg_2 border_buttom_td"><b>Họ tên </b></td>
              <td class="td_bg_1 border_buttom_td"><b>Ngày</b></td>

              <td class="td_bg_1 border_buttom_td"><b>TG đầu</b></td>
              <td class="td_bg_1 border_buttom_td"><b>TG cuối</b></td>
              <td class="td_bg_3 border_buttom_td"><b> Tổng giờ</b></td>
              <td class="td_bg_3 border_buttom_td"><b>Thời gian khác</b></td>
            </tr>
          </tbody>
          <tbody
            class="tbody_table_cks"
            id="dataTab"
            v-for="itemThongKe in listThongKe"
            :key="itemThongKe.maGD"
          >
            {{
              genListThongKe
            }}
            <tr>
              <td class="td_bg_1 border_buttom_td"><b>{{  }}</b></td>
              <td class="td_bg_2 border_buttom_td">
                <b>{{ decodeBase64(itemThongKe.accName) }}</b>
              </td> 
              <td class="td_bg_1 border_buttom_td">
                <b>{{ getDate(itemThongKe.thoiGianDen) }}</b>
              </td>

              <td class="td_bg_1 border_buttom_td">
                <b>{{ getTimeddmmyyyyCachHHmm(itemThongKe.thoiGianDen) }}</b>
              </td>
              <td
                class="td_bg_1 border_buttom_td"
                v-if="itemThongKe.thoiGianVe === 0"
              >
                <b> </b>
              </td>
              <td class="td_bg_1 border_buttom_td" v-else>
                <b> {{ getTimeddmmyyyyCachHHmm(itemThongKe.thoiGianVe) }} </b>
              </td>
              <td
                class="td_bg_3 border_buttom_td"
                v-if=" itemThongKe.thoiGianDen === 0 || itemThongKe.thoiGianVe === 0   "
              >
                <b> </b>
              </td>
              <td class="td_bg_3 border_buttom_td" v-else>
                <b>
                  {{
                    getTongTime(itemThongKe.thoiGianDen, itemThongKe.thoiGianVe)
                  }}</b
                >
              </td>
              <td class="td_bg_3 border_buttom_td" >
                
                <b v-for="itemTime in listTime"  :key="itemTime.maRaVao">{{ getTimeddmmyyyyCachHHmm(itemTime.thoiGianGhiNhan)}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { apiThongKeVpass } from "@/components/listService";
import axios from "axios";
import {
  getDate,
  getTimeddmmyyyyCachHHmm,
  tongTime,
} from "@/components/VimassCrop";
export default {
  data() {
    return {
      listThongKe: [],
      listTime: [],
      genListThongKe: "",
      dateToday: "",
      timeIn: "",
      timeOut: "",
    };
  },
  created() {
    this.getListThongKe();
  },
  methods: {
    async getListThongKe() {
      try {
        const currentTime = new Date().getTime();
        const response = await axios.post(apiThongKeVpass, {
          funcId: 119,
          currentime: currentTime,
          data: '{"timeTo":1729443600000,"timeFrom":1729529999999,"offset":0,"limit":100,"key":"All","cks":"cedd26a0d4a792d67afa84cfc4bade7e","typeFace":0}',
          device: 2,
          listIdDiem: "",
        });
        var base64 = response.data.result.value;

        var decodedString = atob(base64);
        decodedString = JSON.parse(decodedString);

        this.listThongKe = decodedString;
        // this.listTime  decodedString.hisOnDay;
        for (var i = 0; i < decodedString.length; i++) {
          var data = decodedString[i];
          this.listTime = JSON.parse(data.hisOnDay);
        }
      } catch (error) {
        console.log("Error" + error);
      }
    },
    getTongTime(timeIn, timeOut) {
      return tongTime(timeIn, timeOut);
    },
    getDate(date) {
      date = getDate(date);
      return date;
    },
    getTimeddmmyyyyCachHHmm(time) {
      time = getTimeddmmyyyyCachHHmm(time);
      return time;
    },
    decodeBase64(encodedString) {
      encodedString = decodeURIComponent(escape(encodedString));
      return encodedString;
    },
  },
};
</script>
