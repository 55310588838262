<template>
    <header>
      <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
        ><ul>
          <li></li>
          <li></li>
          <li></li></ul
      ></label>
      <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
      <nav
        class="nav_center"
        style="padding: 0"
        :style="{ display: isMenuVisible ? 'block' : 'none' }"
      >
        <ul style="list-style: none">
          <li>
            <a id="giaiPhapChuyenDoiSo" href="">{{
              currentLanguage.footer_text_giaiPhapChuyenDoiSo
            }}</a>
          </li>
          <li>
            <a id="chuongTrinhThiDiemVaTaiTro" href=""
              >{{ currentLanguage.header_text_theDaNang_CTThiDiem }} &
              {{ currentLanguage.header_text_theDaNang_CTTaiCho }}</a
            >
          </li>
          <li>
            <a id="giaoDuc" href="">{{
              currentLanguage.header_text_diemThanhToan_giaoDuc
            }}</a>
          </li>
  
          <li>
            <a id="yTe">{{ currentLanguage.header_text_theDaNang_phoCap_yte }}</a>
          </li>
          <li>
            <a id="giaoThongVaDichVuCong"
              >{{ currentLanguage.header_text_diemThanhToan_diChuyen }} &
              {{ currentLanguage.header_text_dichVuCong }}</a
            >
          </li>
          <li>
            <a id="diemThanhToanVaDinhDanh">{{
              currentLanguage.footer_text_diemGiaoDich
            }}</a>
          </li>
  
          <li>
            <a id="thietBiVaDichVu"
              >{{ currentLanguage.footer_text_thietBi }} &
              {{ currentLanguage.footer_text_dichVu }}</a
            >
          </li>
  
          <li>
            <a class="congTy">{{ currentLanguage.footer_text_cty }}</a>
          </li>
        </ul>
      </nav>
      <div class="menu_right" style="display: flex">
        <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
          ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
        </a>
        <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
        </a>
        <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
        </a>
        <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
          ><img style="width: 25px" src="img/img-item-vimass-2022/co/kr.jpg" />
        </a>
        <!-- <a onclick="changeLang('hi')" class="lang"
            ><img style="width: 25px" src="img/img-item-vimass-2022/co/hi.png" />
          </a> -->
        <span
          class="clickShow"
          style="color: #ffffff; cursor: pointer; margin: auto; padding: 5px"
          ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
        ></span>
        <div class="showCo" style="display: none; margin: auto">
          <a onclick="changeLang('us')" class="lang"
            ><img src="img/img-item-vimass-2022/co/indo.jpg" />
          </a>
          <a onclick="changeLang('us')" class="lang"
            ><img src="img/img-item-vimass-2022/co/tl.png" />
          </a>
          <a onclick="changeLang('us')" class="lang"
            ><img src="img/img-item-vimass-2022/co/thailan.png" />
          </a>
          <a onclick="changeLang('us')" class="lang"
            ><img src="img/img-item-vimass-2022/co/hu.jpg" />
          </a>
        </div>
        <a id="urlHome" href="/" style="margin: 0 10px"
          ><img
            style="width: 35px; height: 35px"
            src="img/home-icon-white-8.jpg"
          />
        </a>
      </div>
    </header>
    <div class="wrapper row3">
      <main class="hoc container-fluid clear bv">
        <div style="min-height: 500px" class="padding-top15">
          <div class="title-bai-viet" v-html="titleHeThong"></div>
          <div v-html="contentHeThong"></div>
        </div>
      </main>
    </div>
    <FooterView></FooterView>
  </template>
  
  <script>
  import { languages } from "@/components/languages";
  import axios from "axios";
  import { apiChiTietBaiViet } from "@/components/listService";
  import FooterView from "@/views/Vimass/Footer/FooterView.vue";
  export default {
    name: 'PolicyVpos', 
    components: {
      FooterView,
    },
    data() {
      return {
        currentLanguage: languages.vi,
        isMenuVisible: false,
  
        lang: "",
        base64: "",
        base64Title: "",
        //title
        titleHeThong: "",
        //content
        contentHeThong: "",
        hasMounted: false,
      };
    },
  
    mounted() {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      } else {
        this.isMenuVisible = true;
      }
  
      const lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Bài viết";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Article details";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 帖子";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 게시물";
      }
      this.lang = lang;

      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("giaiPhapChuyenDoiSo")
        .setAttribute("href", lang + "/giaiphapchuyendoiso");
      document
        .getElementById("chuongTrinhThiDiemVaTaiTro")
        .setAttribute("href", lang + "/chuongtrinhthidiemvataitro");
      document.getElementById("giaoDuc").setAttribute("href", lang + "/giaoduc");
      document.getElementById("yTe").setAttribute("href", lang + "/y-te");
      document
        .getElementById("giaoThongVaDichVuCong")
        .setAttribute("href", lang + "/linhvuc");
      document
        .getElementById("diemThanhToanVaDinhDanh")
        .setAttribute("href", lang + "/diemthanhtoanvadinhdanh");
      document
        .getElementById("thietBiVaDichVu")
        .setAttribute("href", lang + "/thietbivadichvu");
  
      if (this.$refs.manHinhChinh) {
        const imgElement = this.$refs.manHinhChinh;
        imgElement.addEventListener("click", this.handleClick);
        console.log("Ref tồn tại");
      } else {
        console.log("Ref không tồn tại");
      }
    },
    created() {
      const savedLanguage = localStorage.getItem("languages");
      if (savedLanguage && languages[savedLanguage]) {
        this.currentLanguage = languages[savedLanguage];
      }
   
      this.performPostRequest("1713257571146OqEHz");
    },
  
    methods: {
      handleClick() {
        // Xử lý sự kiện click ở đây
        console.log("Đã click vào màn hình chính");
      },
  
      toggleMenu() {
        this.isMenuVisible = true;
      },
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
        window.location.reload();
      },
  
      async performPostRequest(id) {
        this.lang = localStorage.getItem("languages");
  
        try {
          // Thực hiện POST request
          const response = await axios.post(apiChiTietBaiViet, {
            id: id,
            langId: "0",
          });
          if (this.lang === "vi") {
            this.base64 = response.data.result.content_vi;
            this.base64Title = response.data.result.title_vi;
          } else if (this.lang === "en") {
            this.base64 = response.data.result.content_en;
            this.base64Title = response.data.result.title_en;
          } else if (this.lang === "cn") {
            this.base64 = response.data.result.content_cn;
            this.base64Title = response.data.result.title_cn;
          } else if (this.lang === "kr") {
            this.base64 = response.data.result.content_kr;
            this.base64Title = response.data.result.title_kr;
          } else {
            this.base64 = response.data.result.content_vi;
            this.base64Title = response.data.result.title_vi;
          }
          var title = atob(this.base64Title);
          var decodedString = atob(this.base64);
  
          this.titleHeThong = decodeURIComponent(escape(title));
          this.contentHeThong = decodeURIComponent(escape(decodedString));
        } catch (error) {
          console.error("Error:", error);
        }
      },
    },
  };
  </script>