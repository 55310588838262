<template>
  <nav class="nav_center2 d-flex w-100 p-0">
    <ul class="" style="list-style: none">
    

      <li
        style="border-right: 1px solid rgb(167 167 167)"
        :class="{ topmenuactive: activeTab === 'SinhTracHoc' }"
        @click="changeTab('SinhTracHoc')"
      >
        <a>{{ currentLanguage.header_text_sinhTracHoc }}</a>
      </li>

      <li
        style="border-right: 1px solid rgb(167 167 167)"
        :class="{ topmenuactive: activeTab === 'PhTuXa' }"
        @click="changeTab('PhTuXa')"
      >
        <a>
          {{ currentLanguage.header_text_phTuXa }}
        </a>
      </li>
      <li
        style="border-right: 1px solid rgb(167 167 167)"
        :class="{ topmenuactive: activeTab === 'UngDung' }"
        @click="changeTab('UngDung')"
      >
        <a>{{ currentLanguage.header_text_ungDung }}</a>
      </li>

      <li
        style="border-right: 1px solid rgb(167 167 167)"
        :class="{ topmenuactive: activeTab === 'Website' }"
        @click="changeTab('Website')"
      >
        <a>{{ currentLanguage.header_text_Website }}</a>
      </li>
 
    </ul>
  </nav>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <div class="wrapper row3">
        <main class="hoc container-fluid clear bv">
          
          <!-- SinhTracHoc -->
          <div
            id="SinhTracHoc"
            v-show="showSinhTracHoc"
            style="min-height: 500px"
            class="padding-top15"
          >
          <div class="title-bai-viet" v-html="titleSinhTracHoc"></div>
            <div v-html="contentSinhTracHoc"></div>
       
          </div>
          <!-- PhTuXa -->
          <div
            id="PhTuXa"
            v-show="showPhTuXa"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
          <div class="title-bai-viet" v-html="titlePhTuXa"></div>

            <div v-html="contentPhTuXa"></div>
            <!-- <HTPhTuXaVue></HTPhTuXaVue> -->
          </div>
          <!-- UngDung -->
          <div
            id="UngDung"
            v-show="showUngDung"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <UngDung></UngDung>
            <div class="title-bai-viet" v-html="titleUngDung"></div>
            <div v-html="contentUngDung"></div>
          </div>
          <!-- Website -->
          <div
            id="Website"
            v-show="showWebsite"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleWebsite"></div>
            <div v-html="contentWebsite"></div>
          </div>
          <div
            id="HuongDan"
            v-show="showHuongDan"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleHuongDan"></div>
            <div v-html="contentHuongDan"></div>
          </div>
        </main>
      </div>
    </main>
  </div>
</template>
  <script>
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";

export default {

  data() {
    return {
      activeTab: "SinhTracHoc",

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showPhTuXa: false,
      showSinhTracHoc: true,
      showUngDung: false,
      showWebsite: false,
      showHuongDan: false,

      //active tab
      isActiveSinhTracHoc: true,
      isActivePhTuXa: false,
      isActiveUngDung: false,
      isAcitveWebsite: false,
      isActiveHuongDan: false,

      //title
      titleSinhTracHoc: "",
      titlePhTuXa: "",
      titleUngDung: "",
      titleWebsite: "",
      titleHuongDan: "",

      //content
      contentSinhTracHoc: "",
      contentPhTuXa: "",
      contentUngDung: "",
      contentWebsite: "",
      contentHuongDan: "",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("languages");

    // this.performPostRequest("1703131634689t1K2j", "SinhTracHoc", this.lang);
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "SinhTracHoc") {
          this.titleSinhTracHoc = decodeURIComponent(escape(title));
          this.contentSinhTracHoc = decodeURIComponent(escape(decodedString));
        } else if (section === "PhTuXa") {
          this.titlePhTuXa = decodeURIComponent(escape(title));
          this.contentPhTuXa = decodeURIComponent(escape(decodedString));
        } else if (section === "UngDung") {
          this.titleUngDung = decodeURIComponent(escape(title));
          this.contentUngDung = decodeURIComponent(escape(decodedString));
        } else if (section === "Website") {
          this.titleWebsite = decodeURIComponent(escape(title));
          this.contentWebsite = decodeURIComponent(escape(decodedString));
        } else if (section === "HuongDan") {
          this.titleHuongDan = decodeURIComponent(escape(title));
          this.contentHuongDan = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    changeTab(tab) {
      this.lang = localStorage.getItem("languages");
   if (tab === "PhTuXa") {

        this.isActiveSinhTracHoc = false;
        this.isActivePhTuXa = true;
        this.isActiveUngDung = false;
        this.isAcitveWebsite = false;
        this.isActiveHuongDan = false;
        this.performPostRequest("1729671035364R3b73", "PhTuXa", this.lang);
      } else if (tab === "SinhTracHoc") {

        this.isActiveSinhTracHoc = true;
        this.isActivePhTuXa = false;
        this.isActiveUngDung = false;
        this.isAcitveWebsite = false;
        this.isActiveHuongDan = false;
        this.performPostRequest("1729670885976CZ7q7", "SinhTracHoc", this.lang);
      } else if (tab === "UngDung") {

        this.isActiveSinhTracHoc = false;
        this.isActivePhTuXa = false;
        this.isActiveUngDung = true;
        this.isAcitveWebsite = false;
        this.isActiveHuongDan = false;
        this.performPostRequest(
          "17296711120278iwLK",
          "UngDung",
          this.lang
        );
      } else if (tab === "Website") {

        this.isActiveSinhTracHoc = false;
        this.isActivePhTuXa = false;
        this.isActiveUngDung = false;
        this.isAcitveWebsite = true;
        this.isActiveHuongDan = false;
        this.performPostRequest(
          "1729671170791Lg7RF",
          "Website",
          this.lang
        );
      } else if (tab === "HuongDan") {

        this.isActiveSinhTracHoc = false;
        this.isActivePhTuXa = false;
        this.isActiveUngDung = false;
        this.isAcitveWebsite = false;
        this.isActiveHuongDan = true;
        this.performPostRequest("17014203345248QarX", "HuongDan", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab
      this.showSinhTracHoc = tab === "SinhTracHoc";
      this.showPhTuXa = tab === "PhTuXa";
      this.showUngDung = tab === "UngDung";
      this.showWebsite = tab === "Website";
      this.showHuongDan = tab === "HuongDan";
    },
  },
};
</script>
<style scoped>
.ul_menu {
  box-shadow: 0 0 15px #c1ced5;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ul_menu li {
  background: white;
  border-left: 1px solid #2080b4af;
  color: rgb(92, 92, 145);
}
.ul_menu li:first-child a:hover {
  border-radius: 5px 0 0 5px;
  color: white;
}
.ul_menu li:last-child a:hover {
  border-radius: 0px 5px 5px 0px;
  color: white;
}
.ul_menu li a:hover {
  color: white;
  background: #4da9e0;
}
.ul_menu li a {
  color: #000;
}
.ul_menu li:first-child {
  border-left: none !important;
  border-radius: 5px 0 0 5px;
}

.ul_menu li:last-child {
  border-radius: 0px 5px 5px 0px;
}
.topmenuactive a {
  color: white !important;
}
</style>