export const imageUrlsChiTietCuaHang = {
  logoQuocKi: "img/vpos/iconTrangChu/quocki.png",
  duongKe: "img/vpos/imgChiTietCuaHang/duongke.png",
  gioHang2: "img/vpos/imgChiTietCuaHang/icongiahang.png",
  gioHang: "img/vpos/imgChiTietCuaHang/giohangnho.png",
  cuaHangChung: "img/vpos/imgChiTietCuaHang/merchant.png",
  trangChu: "img/vpos/imgChiTietCuaHang/trangchu.png",
  dauTru: "img/vpos/imgChiTietCuaHang/dautru.png",
  dauCong: "img/vpos/imgChiTietCuaHang/daucong.png",
  vietqr: "img/vpos/imgChiTietCuaHang/vietqr.png",
  bidv: "img/vpos/imgChiTietCuaHang/bidv.png",
  vimass: "img/vpos/imgChiTietCuaHang/vimass.png",
  inngan: "img/vpos/imgChiTietCuaHang/inngan.png",
  indai: "img/vpos/imgChiTietCuaHang/indai.png",
  chiase: "img/vpos/imgChiTietCuaHang/chiase.png",
  taixuong: "img/vpos/imgChiTietCuaHang/taixuong.png",
 
};
