<template>
    <div class ="posDel-1">
      <div class ="hideFromDel posDel-1__1">

        <form class ="posDel-1__2">
          <span class ="fs-3 fw-bold"> Xóa "{{itemDel.productName}}"</span> 
          <div class="inputWithIcon">
            <input type="text" placeholder="Nhập 6 số token"  maxlength="6" v-model = "inputToken" />
          <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
          </div>
          <div class ="span--style-h">
              <span class ="fs-5 ">{{notification}} </span> 
          </div>

          <button class="w-50 fs-5 rounded-1 fw-bold btn-style" @click.prevent="DeleteCategory"> Thực hiện </button>
        </form>
      </div>
    </div>
</template>
<script>
import {postDeleteProduct} from "@/components/js/VposService";
export default {
 
    data(){ 
        return {  
            notification: "", inputToken:"",distanceH: 70
      };
    },
    props:{
        itemDel: null, parentFuncDel:{
            type: Function,
            required: true
      }  
    },
    // computed: {
    //   dynamicHeight() {
    //     // sử dụng calc()
    //     return `calc(100% - ${this.distanceH}px)`;
    //   }
    // }
    // ,
    methods:{

      async DeleteCategory(){
        try{
            const response = await postDeleteProduct(this.inputToken, this.itemDel);
         //  console.log("response DeleteCategory:"+ JSON.stringify(response));
            if(response.msgCode == 1){
                this.notification = response.msgContent;
                setTimeout(() => {
                   this.notification ="";
                   this.parentFuncDel(this.itemDel.idCate);
                }, 2000);
            }else{
                this.notification = response.msgContent;
            }
            }catch(e){
                console.log("Exception e:",e);
        }
      },
    }


 
}
</script>

<style scoped>
.posDel-1{
  position: absolute; background: rgba(13, 13, 14, 0.52); width: 100%; height: 100%;
}
.posDel-1__1{
  width: 100%; height: 100%; z-index: 1; display: flex;  justify-content: center;
}
.posDel-1__2{
    background-color: white; padding: 25px; border-radius: 15px; min-width: 329px; height: 223px; margin-top: 100px;
}
</style>