<template>
    <div class ="posDel-1" :style="{ height: dynamicHeight }">
      <div class ="hideFromEdit posDel-1__1">
        <form class ="posDel-1__2">
          <span class="fs-3 fw-bold">Xóa danh mục "{{itemdDel.catName}}" </span>
          <div class="inputWithIcon">
            <input type="text" placeholder="Nhập 6 số token" v-model="inputToken" maxlength="6" />
          <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
          </div>
          <div class ="span--style-h">
              <span class ="fs-5">{{ notification }} </span>
          </div>
          <button @click.prevent="DeleteCategory" class="w-50 fs-5  rounded-1 fw-bold btn-style">
            Thực hiện
          </button>
        
        </form>
      </div>
    </div>
</template>

<script>
  import {postEditCategory} from "@/components/js/VposService";
  export default {
    props: {
        itemdDel:{
          type: Object,
          default: null
      },  parentFuncDel:{
        type: Function,
        required: true
      }
    }, 
 
    data() {
      return {  
        inputToken: "",notification: "", distanceH: 70
      };
    },
    mounted() {
     // console.log("this.itemdDel 1=======> " +JSON.stringify(this.itemdDel));
    //this.inputCategotyName = this.item;
  },
  computed: {
    dynamicHeight() {
      // sử dụng calc()
      return `calc(100% - ${this.distanceH}px)`;
    }
  },
  methods:{
    reset(){
            this.notification = "";
        },
      async DeleteCategory(){
      try{
        
        const response = await postEditCategory(this.inputToken, this.itemdDel.idStore,this.itemdDel.id,this.itemdDel.catName, 2);
          //console.log("response ========>"+ JSON.stringify(response));
          // alert("response ========>"+ JSON.stringify(response));
          if(response.msgCode == 1){
             this.notification = response.msgContent;
                setTimeout(() => {
                  this.notification ="";
                  this.inputToken ="";
                  this.parentFuncDel();
                }, 2000);
          }else{
            this.notification = response.msgContent;
          }
          }catch(e){
              console.log("Exception e:",e);
          }
          
      },
     
   }
  }
</script>
<style scoped>
.posDel-1{
  position: absolute; background: rgba(13, 13, 14, 0.52); width: 100%; top: 70px;
}
.posDel-1__1{
   width: 100%; height: 100%; z-index: 1; display: flex;  justify-content: center;
}
.posDel-1__2{
  background-color: white;padding: 32px; border-radius: 18px; min-width: 391px; height: 236px; margin-top: 100px;
}
</style>