<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center w-left" 
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <!-- <li
          :class="{ topmenuactive: activeTab === 'gioiThieu' }" -->
           <li @click="changeTab('gioiThieu')">
          <a id="urlGioithieu" href="qramthanh#gioithieu/">{{
            currentLanguage.header_text_QRAmThanh
          }}</a>
        </li>
        
        <!-- <li
          :class="{ topmenuactive: activeTab === 'huongdan' }"
          @click="changeTab('huongdan')"
        >
          <a id="urlHuongDan" href="qramthanh#huongdan/">{{
            currentLanguage.header_text_huongDan
          }}</a>
        </li> -->

      
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
    <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>

    <a @click="changeLanguage('ja')" class="lang" style="padding: 5px"
      ><img style="width: 25px ;" src="img/img-item-vimass-2022/co/ja.jpg" />
    </a>
 
    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
  
  </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
     
          <!-- gioi thieu -->
          <div
            id="gioiThieu"
            v-show="showGioiThieu"
            style="min-height: 500px"
            class="padding-top15"
          >
            <!-- <div class="title-bai-viet" v-html="titleGioiThieu"></div> -->
            <div v-html="contentGioiThieu"></div>
          </div>

        <!-- DiemThanhToan-->
        <!-- <div
            id="huongdan"
            v-show="showTutorial"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
            >
            <div class="title-bai-viet" v-html="titleTutorial"></div>
            <div v-html="contentTutorial"></div>
          </div> -->


       
    </main>
  </div>
  <FooterView></FooterView>
</template>
  <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
export default {
  components: {
    FooterView,
  },
  data() {
    return {
      activeTab: "gioiThieu",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showGioiThieu: true,
      showTutorial: false,

      //title
      titleGioiThieu: "",
      titleTutorial: "",

      //content
      contentGioiThieu: "",
      contentTutorial:"",

      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Nhận tiền bằng QR âm thanh";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Receive money with sound QR";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 通过音频二维码收款";
      }else if (this.lang == "kr") {
        document.title = "Vimass.vn - 오디오 QR로 돈 받기";
      }
      this.performPostRequest("1721206734691pXk6Q", "gioiThieu", this.lang);

      window.location.href = this.lang + "/qramthanh#gioithieu/";
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlGioithieu")
        .setAttribute(
          "href",
          this.lang + "/qramthanh#gioithieu/"
        );
      // document
      //   .getElementById("urlHuongDan")
      //   .setAttribute(
      //     "href",   
      //     this.lang + "/qramthanh#huongdan/"
      //   );
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        }else{
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "gioiThieu") {
          this.titleGioiThieu = decodeURIComponent(escape(title));
          this.contentGioiThieu = decodeURIComponent(escape(decodedString));
        }  else if (section === "huongdan") {
          this.titleTutorial = decodeURIComponent(escape(title));
          this.contentTutorial = decodeURIComponent(escape(decodedString));
        }
     
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {    
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

      if (tab === "gioiThieu") {
        this.showGioiThieu = true;
        this.showTutorial = false;
        this.performPostRequest("1721206734691pXk6Q", "gioiThieu", this.lang);
      } 
      else if (tab === "huongdan") {
  
        this.showGioiThieu = false;
        this.showTutorial = true;
        this.performPostRequest("1721987020054fqn82", "huongdan",this.lang);
      } 

      this.activeTab = tab; // Cập nhật giá trị activeTab

    },
  },
};
</script>
<style scoped>

    @media (max-width: 991.98px) { 
      .w-left{
        width:100%!important;
      }
    }


    @media (min-width: 992px) { 
      .w-left{
        width:50%!important;
      }
    }

</style>
  
   