<template>
  <div class="container-fluid">
    <div class="row">
      <!-- <header-vpos></header-vpos> -->
      <header class="p-0">
        <label for="toggle-1" class="toggle-menu" @click="toggleMenu">
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </label>
        <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
        <nav
          class="nav_center"
          style="padding: 0"
          :style="{ display: isMenuVisible ? 'block' : 'none' }"
        >
          <ul style="list-style: none">
            <li
              :class="{ topmenuactive: activeTab === 'qrAmThanh' }"
              @click="changeTab('qrAmThanh')"
            >
              <a id="urlQRamThanh" href="pos#qramthanh">
                {{ currentLanguage.body_text_thietbi_tb_Ungdung }}
              </a>
            </li>

            <li
              :class="{ topmenuactive: activeTab === 'posvimass' }"
              @click="changeTab('posvimass')"
            >
              <a id="urlPos" href="pos#posvimass">pos.vimass.vn</a>
            </li>

            <li
              :class="{ topmenuactive: activeTab === 'CuaHangMau' }"
              @click="changeTab('CuaHangMau')"
            >
              <a id="urlBai1" href="pos#CuaHangMau/">{{
                currentLanguage.header_text_diemThanhToan_cuaHangMau
              }}</a>
            </li>
            <li
              :class="{ topmenuactive: activeTab === 'gioiThieu' }"
              @click="changeTab('gioiThieu')"   >
              <a id="urlGioithieu" href="pos#thuchiho/">{{
                currentLanguage.header_text_thuChiHoQuaAPI
              }}</a>
            </li>
            <li
              :class="{ topmenuactive: activeTab === 'DiemThanhToan' }"
              @click="changeTab('DiemThanhToan')"
            >
              <a id="DiemThanhToan" href="pos#hopdong/">{{
                currentLanguage.body_HopDong_text_title
              }}</a>
            </li>
            <li
              :class="{ topmenuactive: activeTab === 'LienHe' }"
              @click="changeTab('LienHe')"
            >
              <a id="urlBai6" href="pos#LienHe/">{{
                currentLanguage.header_text_lienHe
              }}</a>
            </li>
          </ul>
        </nav>
        <div class="menu_right" style="display: flex">
          <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
            ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
          </a>
          <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
            ><img
              style="width: 25px"
              src="img/img-item-vimass-2022/co/us.png"
            />
          </a>
          <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
            ><img
              style="width: 25px"
              src="img/img-item-vimass-2022/co/cn.jpg"
            />
          </a>
          <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
            ><img
              style="width: 25px"
              src="img/img-item-vimass-2022/co/kr.jpg"
            />
          </a>
          <a @click="changeLanguage('ja')" class="lang" style="padding: 5px">
            <img style="width: 25px" src="img/img-item-vimass-2022/co/ja.jpg" />
          </a>

          <!-- <a onclick="changeLang('hi')" class="lang"
            ><img style="width: 25px" src="img/img-item-vimass-2022/co/hi.png" />
          </a> -->
          <span
            class="clickShow"
            style="color: #ffffff; cursor: pointer; margin: auto; padding: 5px"
            ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
          ></span>
          <div class="showCo" style="display: none; margin: auto">
            <a onclick="changeLang('us')" class="lang"
              ><img src="img/img-item-vimass-2022/co/indo.jpg" />
            </a>
            <a onclick="changeLang('us')" class="lang"
              ><img src="img/img-item-vimass-2022/co/tl.png" />
            </a>
            <a onclick="changeLang('us')" class="lang"
              ><img src="img/img-item-vimass-2022/co/thailan.png" />
            </a>
            <a onclick="changeLang('us')" class="lang"
              ><img src="img/img-item-vimass-2022/co/hu.jpg" />
            </a>
          </div>
          <a id="urlHome" href="/" style="margin: 0 10px"
            ><img
              style="width: 35px; height: 35px"
              src="img/home-icon-white-8.jpg"
            />
          </a>
        </div>
      </header>
      <div class="col-12 col-md-12">
        <div class="container-fluid p-2">
          <div class="row">
            <!-- <SearchStore v-if="phoneSearch"></SearchStore> -->
            <div
              id="gioiThieu"
              v-show="showGioiThieu"
              style="min-height: 500px"
              class="padding-top15"
            >
              <div class="title-bai-viet" v-html="titleGioiThieu" ></div>
              <div v-html="contentGioiThieu"  style="text-align: start"></div>
            </div>
            <div
              id="DiemThanhToan"
              v-show="showDiemThanhToan"
              style="min-height: 500px; background-image: none !important"
              class="padding-top15"
            >
              <div class="title-bai-viet" v-html="titleDiemThanhToan"  style="text-align: start"></div>
              <div v-html="contentDiemThanhToan"  style="text-align: start"></div>
            </div>
            <!-- DiemDinhDanh -->
            <div
              id="DiemDinhDanh"
              v-show="showDiemDinhDanh"
              style="min-height: 500px; background-image: none !important"
              class="padding-top15"
            >
              <div class="title-bai-viet" v-html="titleDiemDinhDanh"></div>
              <div v-html="contentDiemDinhDanh"></div>
            </div>
            <div
              id="qrAmThanh"
              v-show="showQRAmThanh"
              style="min-height: 500px; background-image: none !important"
            >
              <!-- <div class="title-bai-viet" v-html="titleQRamThanh"></div> -->
              <div v-html="contentQRamThanh" style="text-align: start"></div>
            </div>

            <div
              id="posvimass"
              v-show="showPos"
              style="min-height: 500px; background-image: none !important"
            >
              <!-- <div class="title-bai-viet" v-html="titlePos"></div> -->
              <div v-html="contentPos" style="text-align: start"></div>
            </div>
            <div
              id="cuaHangMau"
              v-show="showCuaHangMau"
              style="min-height: 500px; background-image: none !important"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <!-- Loop through the first half of items -->
                    <div
                      class="card dark"
                      style="margin: 1em auto; max-width: 60em"
                      v-for="(itemLinhVuc, index) in items.slice(
                        0,
                        Math.ceil(items.length / 2)
                      )"
                      :key="index"
                    >
                      <a
                        :href="itemLinhVuc.url !== '#' ? itemLinhVuc.url : null"
                        target="__blank"
                      >
                        <div class="d-flex">
                          <img
                            style="width: 15%"
                            :src="itemLinhVuc.imgLinhVuc"
                            class="card-img-top"
                          />

                          <div class="card-body">
                            <div class="text-section m-auto d-flex flex-column">
                              <h5 class="card-title" style="color: black">
                                {{ itemLinhVuc.title }}
                              </h5>
                              <p
                                class="card-text"
                                style="color: black; line-height: 1.3"
                              >
                                {{ itemLinhVuc.moTa }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div
                      class="card dark"
                      style="margin: 1em auto; max-width: 60em"
                      v-for="(itemLinhVuc, index) in items.slice(
                        Math.ceil(items.length / 2)
                      )"
                      :key="index"
                    >
                      <a
                        :href="itemLinhVuc.url !== '#' ? itemLinhVuc.url : null"
                        target="__blank"
                      >
                        <div class="d-flex">
                          <img
                            style="width: 15%"
                            :src="itemLinhVuc.imgLinhVuc"
                            class="card-img-top"
                          />

                          <div class="card-body">
                            <div class="text-section m-auto d-flex flex-column">
                              <h5 class="card-title" style="color: black">
                                {{ itemLinhVuc.title }}
                              </h5>
                              <p
                                class="card-text"
                                style="color: black; line-height: 1.3"
                              >
                                {{ itemLinhVuc.moTa }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="lienhe"
              v-show="showLienHe"
              style="min-height: 500px; background-image: none !important"
            >
              <div class="title-bai-viet" v-html="titleLienHe"></div>
              <div v-html="contentLienHe" style="text-align: start"></div>
            </div>
            <ul class="mt-3" v-if="riengChecked">
              <li v-for="store in listCuaHang" :key="store.id" class="p-2">
                <div
                  class="w-100 p-3"
                  style="
                    height: 45px;
                    background-color: rgb(239 239 239);
                    border-radius: 10px;
                    border-bottom: 2px rgb(185 185 185) solid;
                    text-align: start;
                    cursor: pointer;
                  "
                >
                  <span style="font-weight: 600; margin-left: 20px"
                    >{{ store.storeName }}
                  </span>
                  | <span> {{ store.storeAdd }}</span>
                </div>
              </li>
            </ul>
            <div class="m-auto" v-if="chungChecked"></div>
            <CreateStore class="d-none"></CreateStore>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 p-0 d-none" style="background-color: #f8f8f8">
        <VposLoginVue v-if="!Login"></VposLoginVue>
        <MainMenuVposVue v-if="menuThongTin"></MainMenuVposVue>
        <VposRegister v-if="register"></VposRegister>
      </div>
    </div>
  </div>
  <FooterView></FooterView>
</template>
<script>
import { imageUrls } from "@/components/js/urlImg";
import VposLoginVue from "../Accounts/VposLogin.vue";
import MainMenuVposVue from "./MainMenuVpos.vue";
import VposRegister from "../Accounts/VposRegister.vue";

import CreateStore from "../DashHome/CreateStore.vue";
import { languages } from "@/components/languages";
import { postLayTiGia } from "@/components/js/VposServiceDanh";
import axios from "axios";
import { apiChiTietBaiViet } from "@/components/listService";
import FooterView from "@/views/Vimass/Footer/FooterView.vue";

export default {
  components: {
    MainMenuVposVue,
    VposLoginVue,
    VposRegister,
    CreateStore,
    FooterView,
  },
  data() {
    return {
      activeTab: "qrAmThanh",
      isMenuVisible: false,
      imageUrls: imageUrls,
      currentLanguage: languages.vi,
      Login: false,
      menuThongTin: false,
      register: false,
      user: "",
      listCuaHang: [],
      bannerQuangCao: true,
      phoneSearch: true,
      slideWeb: true,

      showQRAmThanh: true,
      isActiveQRAmThanh: true,

      showCuaHangMau: false,
      isActiveCuaHangMau: false,

      showLienHe: false,
      isActiveLienHe: false,

      showPos: false,
      isActivePos: false,

      titlePos: "",
      contentPos: "",
      titleQRamThanh: "",
      contentQRamThanh: "",
      titleLienHe: "",
      contentLienHe: "",
      titleCuaHangMau: "",
      contentCuaHangMau: "",

      //show
      showGioiThieu: false,
      showDiemThanhToan: false,
      showDiemDinhDanh: false,

      //active tab
      isActiveGioiThieu: true,
      isActiveDiemThanhToan: false,
      isActiveDiemDinhDanh: false,

      //title
      titleGioiThieu: "",
      titleDiemThanhToan: "",
      titleDiemDinhDanh: "",

      //content
      contentGioiThieu: "",
      contentDiemThanhToan: "",
      contentDiemDinhDanh: "",

      docId: "1TGA7zcPFDEqr0JcSZUxadu7EMPuFwgJo", // ID của tài liệu Google Docs
      embeddedUrl: "",

      items: [],
    };
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  mounted() {
    this.fetchData();
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Vimass POS";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Vimass POS";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - Vimass POS";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - Vimass POS";
      }
      this.checkUrl();

      this.performPostRequest("1721206734691pXk6Q", "qrAmThanh", this.lang);
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlPos")
        .setAttribute("href", this.lang + "/pos#posvimass/");
      document
        .getElementById("urlQRamThanh")
        .setAttribute("href", this.lang + "/pos#qramthanh/");
      document
        .getElementById("urlBai1")
        .setAttribute("href", this.lang + "/pos#cuahangmau/");

      document
        .getElementById("urlBai6")
        .setAttribute("href", this.lang + "/pos#lienhe/");

      document
        .getElementById("urlGioithieu")
        .setAttribute("href", this.lang + "/pos#thuchiho");
      document
        .getElementById("DiemThanhToan")
        .setAttribute("href", this.lang + "/pos#hopdong");
    }
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkUrl() {
      if (window.location.href.indexOf("cuahangmau") > -1) {
        this.changeTab("CuaHangMau"); // Gọi hàm changeTab với giá trị hash (vd: 'CuaHangMau')
      } else {
        window.location.href = this.lang + "/pos#qramthanh/";
      }
    },
    async fetchData() {
      this.apiUrl =
        "https://sheets.googleapis.com/v4/spreadsheets/1EArWilSVZmk23GG5AlCoVov-e1nbVdxbNdNTENzdFwQ/values/jsonUrlLinhVuc?key=AIzaSyB6KdsUEG02YYtkYDjFlVA-HrSvs_PULh4";

      try {
        const response = await axios.get(this.apiUrl);
        const lang = localStorage.getItem("languages");

        this.items = response.data.values
          .map((row) => {
            if (row[4] && lang && row[4].trim() === lang.trim()) {
              return {
                title: row[0],
                url: row[1],
                imgLinhVuc: row[2],
                moTa: row[3],
              };
            }
          })
          .filter((item) => item !== undefined);

        console.log("this.items ", this.items);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },
    async layTyGia() {
      await postLayTiGia();
    },

    handleDataReceived() {
      var data = localStorage.getItem("dataCuaHang");
      this.listCuaHang = JSON.parse(data);
    },
    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section

        if (section === "gioiThieu") {
          this.titleGioiThieu = decodeURIComponent(escape(title));
          this.contentGioiThieu = decodeURIComponent(escape(decodedString));
        } else if (section === "DiemThanhToan") {
          this.titleDiemThanhToan = decodeURIComponent(escape(title));
          this.contentDiemThanhToan = decodeURIComponent(escape(decodedString));
        } else if (section === "DiemDinhDanh") {
          this.titleDiemDinhDanh = decodeURIComponent(escape(title));
          this.contentDiemDinhDanh = decodeURIComponent(escape(decodedString));
        } else if (section === "posvimass") {
          this.titlePos = decodeURIComponent(escape(title));
          this.contentPos = decodeURIComponent(escape(decodedString));
        } else if (section === "qrAmThanh") {
          this.titleQRamThanh = decodeURIComponent(escape(title));
          this.contentQRamThanh = decodeURIComponent(escape(decodedString));
        } else if (section === "CuaHangMau") {
          this.titleCuaHangMau = decodeURIComponent(escape(title));
          this.contentCuaHangMau = decodeURIComponent(escape(decodedString));
        } else if (section === "LienHe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        }
        // else if (section === "congTacVien") {
        //   this.titleCongTacVien = decodeURIComponent(escape(title));
        //   this.contentCongTacVien = decodeURIComponent(escape(decodedString));
        // }
        // else if (section === "HuongDan") {
        //   this.titleHuongDan = decodeURIComponent(escape(title));
        //   this.contentHuongDan = decodeURIComponent(escape(decodedString));
        // }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

      if (tab === "qrAmThanh") {
        this.isActivePos = false;
        this.isActiveQRAmThanh = true;
        this.isActiveCuaHangMau = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1721206734691pXk6Q", "qrAmThanh", this.lang);
      } else if (tab === "posvimass") {
        this.isActivePos = true;
        this.isActiveQRAmThanh = false;
        this.isActiveCuaHangMau = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1724726873228uzMXd", "posvimass", this.lang);
      } else if (tab === "CuaHangMau") {
        this.isActivePos = false;
        this.isActiveQRAmThanh = false;
        this.isActiveCuaHangMau = true;
        this.isActiveDiemDinhDanh = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1716540789699k5Tqp", "CuaHangMau", this.lang);
      } else if (tab === "LienHe") {
        this.isActivePos = false;
        this.isActiveQRAmThanh = false;
        this.isActiveCuaHangMau = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveLienHe = true;

        this.performPostRequest("1690272053288yg3c7", "LienHe", this.lang);
      } else if (tab === "gioiThieu") {
        this.isActivePos = false;
        this.isActiveQRAmThanh = false;
        this.isActiveCuaHangMau = false;
        this.isActiveGioiThieu = true;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1719997599950aUoQY", "gioiThieu", this.lang);
      } else if (tab === "DiemThanhToan") {
        this.isActiveGioiThieu = false;
        this.isActivePos = false;
        this.isActiveQRAmThanh = false;
        this.isActiveCuaHangMau = false;
        this.isActiveDiemThanhToan = true;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.performPostRequest(
          "17200780550769PnFB",
          "DiemThanhToan",
          this.lang
        );
      } else if (tab === "DiemDinhDanh") {
        this.isActiveGioiThieu = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = true;
        this.isActiveLienHe = false;
        this.performPostRequest(
          "1684483847412mqp7d",
          "DiemDinhDanh",
          this.lang
        );
      }

      this.activeTab = tab;
      this.showPos = tab === "posvimass";
      this.showQRAmThanh = tab === "qrAmThanh";
      this.showCuaHangMau = tab === "CuaHangMau";
      this.showGioiThieu = tab === "gioiThieu";
      this.showDiemThanhToan = tab === "DiemThanhToan";
      this.showDiemDinhDanh = tab === "DiemDinhDanh";
      this.showLienHe = tab === "LienHe";
    },
  },
};
</script>
<style>
.dropbtn {
  background-color: #007abb;
  color: white;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #007abb !important;
}

.card {
  max-width: 30em;
  flex-direction: row;
  background-color: #696969;
  border: 0;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  margin: 3em auto;
}

.card.dark {
  color: #fff;
}

.card.card.bg-light-subtle .card-title {
  color: dimgrey;
}

.card img {
  max-width: 25%;
  margin: auto;
  padding: 0.5em;
  border-radius: 0.7em;
}

.card-body {
  display: flex;
  justify-content: space-between;
}

.text-section {
  max-width: 90%;
}

.cta-section {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.cta-section .btn {
  padding: 0.3em 0.5em;
  /* color: #696969; */
}

.card.bg-light-subtle .cta-section .btn {
  background-color: #898989;
  border-color: #898989;
}

.ytp-right-controls .ytp-subtitles-button {
  display: none !important;
}
/* iframe {
    pointer-events: none!important;;
} */

@media (min-width: 992px) {
  #qrAmThanh .vdeoamthanh {
    width: 100% !important;
    display: flex;
    flex-wrap: nowrap;
  }
  #qrAmThanh .vdeoamthanh .vdtrai {
    width: 32% !important;
  }
  #qrAmThanh .vdeoamthanh .vdtrai iframe {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    height: 320px;
  }
  #qrAmThanh .vdeoamthanh .vdnoidung {
    width: 68% !important;
    margin-left: 3px;
  }

  #posvimass .vdeoamthanh {
    width: 100% !important;
    display: flex;
    flex-wrap: nowrap;
  }
  #posvimass .vdeoamthanh .vdtrai {
    width: 77% !important;
    margin-left: 3px;
  }
  #posvimass .vdeoamthanh .vdtrai iframe {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    height: 800px;
  }
  #posvimass .vdeoamthanh .vdnoidung {
    width: 22% !important;
    margin-left: 3px;
    font-size: 18px;
  }
}

@media (max-width: 991.99px) {
  #qrAmThanh .vdeoamthanh {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
  }
  #qrAmThanh .vdeoamthanh .vdtrai {
    width: 100% !important;
  }
  #qrAmThanh .vdeoamthanh .vdtrai iframe {
    width: 100%;
    height: 220px;
    max-width: 560px;
    margin: 0 auto;
  }
  #qrAmThanh .vdeoamthanh .vdnoidung {
    width: 100% !important;
  }

  #posvimass .vdeoamthanh {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
  }
  #posvimass .vdeoamthanh .vdtrai {
    width: 100% !important;
  }
  #posvimass .vdeoamthanh .vdtrai iframe {
    width: 100%;
    margin: 0 auto;
    height: 800px;
    height: 220px;
  }
  #posvimass .vdeoamthanh .vdnoidung {
    width: 100% !important;
    font-size: 18px;
  }
}

@media (max-width: 475px) {
  .card {
    font-size: 0.9em;
  }
}
</style>