<template>
  <header class="pt-2 pb-2">
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center ">
           <span class="fs-3 fw-bold txt-white span-style">Vimass POS</span>
        </div>
        <div class="d-flex align-items-center" >
            <a @click="selectItem(810)" class="m-1"
              ><img class="img-lang"  src="img/img-item-vimass-2022/co/vi-VN.gif" />
            </a>
            <a @click="selectItem(0)" class="m-1"
              ><img class="img-lang"  src="img/img-item-vimass-2022/co/us.png" />
            </a>
            <a @click="selectItem(10)" class="m-1"
              ><img class="img-lang"  src="img/img-item-vimass-2022/co/cn.jpg" />
            </a>
            <a @click="selectItem(3)" class="m-1"
              ><img class="img-lang" src="img/img-item-vimass-2022/co/kr.jpg" />
            </a>
            <a class="ms-2" @click="chuyenHome()">
              <img style="width: 35px; height: 35px" src="img/home-icon-white-8.jpg"/>
          </a>
        </div>
      </div>
  </header>

</template>
<script>
import { imageUrls } from "@/components/js/urlImg";
import { languages } from "@/components/js/langVpos";
// import VposLogin from "../../Accounts/VposLogin.vue";
// import MainMenuVpos from "../MainMenuVpos.vue";
import {
  geocodeAddress,
  KEY_CHONTYGIA,
  KEY_PHONE_ID,
  KEY_SESSION_ID,
  postLayDsStoreCongCong,
} from "@/components/js/VposService";
// import TyGiaCompoment from "./TyGiaCompoment.vue";

export default {
  components: { 
    // VposLogin, 
    // MainMenuVpos, 
    // TyGiaCompoment
   },
  data() {
    return {
      showFromLogin: false,
      imageUrls: imageUrls,
      currentLanguage: languages.vi,
      Login: true,
      menuThongTin: false,
      register: false,
      user: "",
      listCuaHang: [],
      bannerQuangCao: true,
      myStore: false,
      name: "",
      email: "",
      avatar: "",
      lang: "",
      phone: "",
      timeUpdateTyGia: "",
      tyGiaNgoaiTe: [],
      isMenuVisible: false,
      viewTyGia: "",
      listStore: [],
      searchQuery: "",
      showDropdown: false,
      showTyGia: true,
      menuWeb: true,
      searchCuaHangTheoTen: true,
      searchCuaHangTheoToaDo: false,
      lat: "",
      lng: "",
    };
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    // const lang = this.$route.params.lang;
    this.lang = savedLanguage;
    if (languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }

    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  computed: {
    filteredStores() {
      return this.listStore.filter(
        (store) =>
          store.storeName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          store.VMStoreIndex.toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          store.storeAdd
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
      );
    },
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    window.addEventListener("click", this.handleWindowClick);
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    this.checkLogin();
    this.handleDataReceived();
    this.getUser();
    if (this.lang == "vi") {
      this.viewTyGia = "";
    } else {
      this.viewTyGia = KEY_CHONTYGIA;
    }
    // this.searchToaDo()
  },
  beforeUnmount() {
    // Remove event listener when component is about to be unmounted
    window.removeEventListener("click", this.handleWindowClick);
    window.removeEventListener("resize", this.checkScreenSize);
  },

  methods: {
    async searchToaDo() {
      const res = await geocodeAddress();
      console.log(" res : "+ res.latitude);
      this.lat = res.latitude;
      this.lng = res.longitude;
    },

    showSearchToaDo() {
      (this.searchCuaHangTheoTen = false), (this.searchCuaHangTheoToaDo = true);
    },
    showSearch() {
      (this.searchCuaHangTheoTen = true), (this.searchCuaHangTheoToaDo = false);
    },
    checkScreenSize() {
      if (window.innerWidth >= 768) {
        this.showTyGia = false;
        this.menuWeb = true;
      } else {
        this.showTyGia = true;
        this.menuWeb = false;
      }
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    selectItem(index) {
      this.selectedIndex = index;
      localStorage.setItem("selectedIndex", index);
      if (index === 810) {
        localStorage.setItem("objTyGia", 1);
        this.changeLanguage("vi");
      } else if (index === 0 || index === 3) {
        // this.changeLanguage(this.getLanguage(this.tyGiaNgoaiTe[index]));
        this.changeLanguage("en");
      } else if (index === 10) {
        // this.changeLanguage(this.getLanguage(this.tyGiaNgoaiTe[index]));
        this.changeLanguage("cn");
      } else if (index === 13) {
        // this.changeLanguage(this.getLanguage(this.tyGiaNgoaiTe[index]));
        this.changeLanguage("kr");
      }
    },
    getLanguage(item) {
      localStorage.setItem("objTyGia", JSON.stringify(item));
      var chonTyGia =
        "1 " + item.currency + " = " + item.muaCK.toLocaleString() + " VND";
      localStorage.setItem("chonTyGia", chonTyGia);
      switch (item.currency) {
        case "USD":
        case "EUR":
          return "en";
        case "CNY":
          return "cn";
        case "JPY":
          return "en";
        case "KRW":
          return "kr";
        default:
          return "en";
      }
    },
    selectStore(store) {
      this.searchQuery = store.storeName + " - " + store.storeAdd;
      localStorage.setItem("objThongTinCuaHang", JSON.stringify(store));

      window.location.href = "vpos/o?idStore=" + store.id + "&idBanAn=2";
      this.showDropdown = false;
    },
    handleInput() {
      if (this.searchQuery.includes(" ") || this.searchQuery.length >= 5) {
        this.searchCuaHangTheoQuyen();
      }
    },
    handleEnterKey() {
      this.searchCuaHangTheoQuyen();
    },
    async searchCuaHangTheoQuyen() {
      this.showDropdown = true;
      var secssionLogin = KEY_SESSION_ID;
      var idLogin = KEY_PHONE_ID;
      if (secssionLogin && idLogin) {
        const res = await postLayDsStoreCongCong(
          secssionLogin,
          idLogin,
          this.searchQuery,
          21.002671280706423,
          105.82120178242792
        );
        this.listStore = res.result.docs;
        console.log(res);
      } else {
        const res = await postLayDsStoreCongCong(
          "",
          "",
          this.searchQuery,
          21.002671280706423,
          105.82120178242792
        );
        this.listStore = res.result.docs;
        console.log(res);
      }
    },

    chuyenHome() {
      this.$router.push("/" + this.lang);
    },
    getCurrencyImage(currency) {
      return this.currencyImages[currency];
    },
    getNgonNgu(currency) {
      return this.ngonNgu[currency];
    },

    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },
    formattedPrice(tyGiaVND) {
      if (tyGiaVND !== undefined && tyGiaVND !== null) {
        return tyGiaVND.toLocaleString();
      } else {
        return ""; // Hoặc giá trị mặc định khác phù hợp với ứng dụng của bạn
      }
    },

    checkLogin() {
      this.user = localStorage.getItem("user");
      if (this.user == "" || this.user == null) {
        this.Login = true;
        this.menuThongTin = false;
      } else {
        this.menuThongTin = true;
        this.Login = false;
      }
    },
    handleDataReceived() {
      var data = localStorage.getItem("dataCuaHang");
      this.listCuaHang = JSON.parse(data);
    },
    async getUser() {
      const dataFromLocalStorage = localStorage.getItem("user");
      if (dataFromLocalStorage) {
        try {
          const parsedData = JSON.parse(dataFromLocalStorage);
          this.name = parsedData.nameAlias;
          this.avatar = parsedData.avatar;
          this.phone = parsedData.phone;
          this.email = parsedData.email;
        } catch (error) {
          console.error("Error parsing data from localStorage:", error);
        }
      } else {
        console.warn("No data found in localStorage");
      }
    },
    showPopupLogin() {
      this.showFromLogin = true;
      console.log("show 1 ==> " + this.showFromLogin);
      this.$refs.childRef.dangNhap();
    },
    handleWindowClick(event) {
      ///  console.log('Trạng thái trước khi thay đổi:' + event.target.className , this.showFromLogin);
      //iconTyGia
      if (
        (this.showFromLogin && event.target.className === "f-login-style") ||
        (this.showFromLogin &&
          event.target.className === "d-flex justify-content-between") ||
        (this.showFromLogin &&
          event.target.className === "d-flex justify-content-between w-50") ||
        (this.showFromLogin && event.target.className === "m-auto") ||
        (this.showFromLogin && event.target.className === "fa fa-search") ||
        (this.showFromLogin &&
          event.target.className === "m-auto input-search") ||
        (this.showFromLogin && event.target.className === "nav-menu2-vpos") ||
        (this.showFromLogin && event.target.className === "fa fa-bell-o") ||
        (this.showFromLogin && event.target.className === "f-login-from")
      ) {
        // Close the popup if click occurred outside of it
        this.showFromLogin = false;
      }
      // console.log('Trạng thái sau khi thay đổi:', this.showFromLogin);
    },
  },
};
</script>

<!-- <style>
@import "@";
</style> -->

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content-search {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  /* min-width: 100%; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  margin-top: 55px;
  /* height: 700px; */
  overflow: auto;
  color: black;
  line-height: 3;
}

.dropdown:hover .dropdown-content-search {
  display: block;
  cursor: pointer;
}

.f-login-style {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.f-login-from {
  max-height: auto !important;
  border-radius: 25px;
  display: flex;
}

.container {
  position: relative;
}

.center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.dropbtn {
  background-color: #007abb;
  color: white;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white !important;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(200, 200, 200, 0.2);
  z-index: 2;
  left: -120px;
  height: 500px;
  overflow: auto;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px #b4b7b9 solid;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #007abb !important;
}
.img-lang{
  width: 25px; height: 20px;
}
.txt-white{
  color: white;
}
</style>



