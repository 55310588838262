<template>
  <div class="container-md">
    <div class="row h-100">
      <div class="col-12 col-md-9 min-vh-86 p-2 p-md-3" style="background-color: white; box-shadow: rgb(222, 226, 230) 0px 0px 3px 0px; height: 80vh; overflow-y: auto; ">
        <div v-for="(item,index) in docs" :key="index" :item="item" class="rounded ps-2 pe-2 mt-2 pt-3 pb-3 itemCategory">
              <div class ="me-1 itemCategory__name">
                  <span class="fw-bold fs-5 " style="display: flex; justify-content: center; align-items: center">{{ item.catName }}</span>
              </div>
              <div class ="itemCategory__func">
                <div class ="itemCategory__func__btn me-1 rounded" @click.prevent="EditItemCategory(item)">
                  <img :src="imageUrls.iconThayDoi">
              </div>
                <div class="itemCategory__func__btn rounded" @click.prevent="DelteItemCategory(item)">  
                  <!-- <img :src="imageUrls.iconDele"> -->
                  <i class="fa fa-times-circle icon" aria-hidden="true"></i>
                </div>
              </div>
          </div>
      </div>
      <div class="col-12 col-md-3 p-0 p-md-3 from-add-cat--stlye"> 

        <div class ="btn-add-category">
            <button class=" fw-bold fs-5 btn-style w-50" @click.prevent="showComponentAdd"> Thêm danh mục </button>
        </div>

        <div class ="screen" :style="{'display': isScreen }"> 
          <CreateCategory :parentFuncCreate="reload"/>
        </div>
      </div>
    </div>
  </div>

   <div v-if = "fromEdit" class ="p-0">
      <EditCategory :item="selectedItem" :parentFuncEdit="reload" ref="refEdit"></EditCategory>
   </div>

   <div v-if = "fromDelete" class ="p-0" >
      <DeleteCategory :itemdDel="selectedItem" :parentFuncDel="reload"></DeleteCategory>
   </div>
  
</template>
  
  <script>

  import {postReceiveListCategory} from "@/components/js/VposService";
  import CreateCategory from './CreateCategory.vue';
  import EditCategory from './EditCategory.vue';
  import DeleteCategory from "./DeleteCategory.vue";
  import { imageUrls } from "@/components/js/urlImg";

    export default {

      components:{
        CreateCategory, EditCategory, DeleteCategory
      },
      data() {
        return {  
          offset: 0, limit: 100, InputIdStore: "", docs: [], fromEdit: false, fromDelete: false, selectedItem: null, imageUrls: imageUrls, isScreen: ""
        };
    },
    mounted(){
      this.ReceiveListCategory();
      // Add event listener for window clicks
    window.addEventListener('click', this.handleWindowClick);
   
    },
    beforeUnmount(){
    // Remove event listener when component is about to be unmounted
      window.removeEventListener('click', this.handleWindowClick);
    },

    methods:{
        EditItemCategory(item){
          this.selectedItem = item;
          this.fromEdit = true;
          this.fromDelete = false;
          setTimeout(() => {
            this.$refs.refEdit.getCateName();
                  }, 500);
        },
      
        DelteItemCategory(item){
          this.selectedItem = item;
          this.fromEdit = false;
          this.fromDelete = true;
        },
        async ReceiveListCategory(){
        try{
            this.InputIdStore = localStorage.getItem('idCuaHang');
           // console.log("InputIdStore ========> " + this.InputIdStore);
            const response = await postReceiveListCategory(this.InputIdStore, this.offset, this.limit);
           // console.log("response ========>"+ JSON.stringify(response.result.docs));
            this.docs = response.result.docs;
            return response.result.docs;
            }catch(e){
                console.log("Exception e:",e);
            }
        },
        handleWindowClick(event) {
          if (this.fromEdit && event.target.className ==="hideFromEdit posEdit-1__1"
            || this.fromEdit && event.target.className ==="f-login-style"
            || this.fromEdit && event.target.className ==="d-flex justify-content-between"
            || this.fromEdit && event.target.className ==="d-flex justify-content-between w-50"
            || this.fromEdit && event.target.className ==="m-auto"
            || this.fromEdit && event.target.className ==="fa fa-search"
            || this.fromEdit && event.target.className ==="m-auto input-search"
            || this.fromEdit && event.target.className ==="nav-menu2-vpos"
            || this.fromEdit && event.target.className ==="fa fa-bell-o") {
                    this.fromEdit = false;
          }

          if (this.fromDelete && event.target.className ==="hideFromEdit posDel-1__1"
            || this.fromDelete && event.target.className ==="f-login-style"
            || this.fromDelete && event.target.className ==="d-flex justify-content-between"
            || this.fromDelete && event.target.className ==="d-flex justify-content-between w-50"
            || this.fromDelete && event.target.className ==="m-auto"
            || this.fromDelete && event.target.className ==="fa fa-search"
            || this.fromDelete && event.target.className ==="m-auto input-search"
            || this.fromDelete && event.target.className ==="nav-menu2-vpos") {
                this.fromDelete = false;
          }

          if(event.target.className === "from-align--style"){
           
            this.isScreen = "none";
          }
        },  
        reload(){
            setTimeout(() => {
               this.ReceiveListCategory();
            }, 1000);
            this.fromEdit = false;
            this.fromDelete = false;
          },
        showComponentAdd(){
          this.isScreen = "block";
        }    

      }
  }
  </script>
  <style scoped>
  .itemCategory{
    display: flex; background-color: white; height: auto; max-height: auto; box-shadow: 0 0 15px #c1ced5;
  }
  .itemCategory__name{
    flex-grow: 5; justify-content: flex-start; display: flex; width: 700px; cursor:pointer
  }
  .itemCategory__func{
    display: flex; flex-wrap: nowrap;flex-grow: 1;
  }
  .itemCategory__func__btn{
    width: 30px; flex-grow: 1; display: flex; justify-content: center; align-items: center; color: white; cursor: pointer;
  }

  /* .from-Edit{
    position: absolute;
    width: 100%;
    height: 100%;
  } */
  .min-vh-86{
    min-height: 86vh;
    height: auto;
    
  }



  </style>