<template>
     <p id="demo"></p>
</template>
<script>
export default {
   
  name: 'RedirectComponent',
  mounted() {
    var str = window.navigator.userAgent;
    var str1 = "https://vimass.vn/vi/giaiphapchuyendoiso#vpos/"; // Default URL

    if (str.indexOf("Android") != -1) {
      str1 = "https://play.google.com/store/apps/details?id=com.vimass.vpos";
    } else if (str.indexOf("iPhone") != -1) {
      str1 = "https://apps.apple.com/vn/app/vimass-pos/id6477368679";
    }
    
    window.location.href = str1;
    console.log(str);
  }
}

</script>