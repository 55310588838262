<template>
    <div id="hoidongquantri" >
            <div style="width: 100%;margin: 0;display: block;text-align: center;">
                <h6 class="heading">{{currentLanguage.body_text_congty_hdqt_HOIDONGQUANTRI}}</h6>
            </div>
            <div class="group" style="padding-left: 10px;">
                <div class="contentNew">
                    <div class="dvhdqt" style="width: 100%;">


                        <table style="width: 100%;float: left;">
                            <tr style="background: #c3f0f7;">
                                <td style="width: 30%;text-align: center;text-transform: uppercase;font-weight: bold;">{{currentLanguage.body_text_congty_hdqt_HOTEN}}</td>
                                <td style="width: 70%;text-align: center;text-transform: uppercase;font-weight: bold;">{{currentLanguage.body_text_congty_hdqt_CHUCVU}}</td>
                            </tr>
                            <tr style="border-bottom: 1px solid #D7D7D7;">
                                <td style="width: 30%;">
                                    <span class="hoten" style="float: left;">
                                    {{currentLanguage.body_text_congty_hdh_tvt}}
                                    </span>
                                    <img style="width: 150px!important;float: right!important;max-width: fit-content;" src="img/avatar/TVT.jpg">
                                </td>
                                <td style="width: 70%;">
                                    <span style="word-break: break-all;">{{currentLanguage.body_text_congty_hdqt_ChutichHDQTkiemTonggiamdoccongtycophandichvunendidongVietNam}} <a target="_blank" href="https://vimass.vn">https://vimass.vn</a> </span></td>
                            </tr>


                            <tr style="border-bottom: 1px solid #D7D7D7;">
                                <td style="width: 30%;">
                                    <span class="hoten" style="float: left;">
                                    {{currentLanguage.body_text_congty_hdh_lvt}}
                                    </span>
                                    <img style="width: 150px!important;float: right!important;max-width: fit-content;" src="img/avatar/leviettan.JPG">
                                </td>
                                <td style="width: 70%;">
                                    {{currentLanguage.body_text_congty_hdqt_PhochutichHDQT}}<br>

                                </td>
                            </tr>
                            <tr style="border-bottom: 1px solid #D7D7D7;">
                                <td style="width: 30%;">
                                    <span class="hoten" style="float: left;">
                                    {{currentLanguage.body_text_congty_hdh_thb}}
                                    </span>
                                    <img style="width: 150px!important;float: right!important;max-width: fit-content;" src="img/avatar/THB.jpg">
                                </td>
                                <td style="width: 70%;">
                                {{currentLanguage.body_text_congty_hdqt_ThanhvienHDQT}}
                                </td>
                            </tr>
                            <tr style="border-bottom: 1px solid #D7D7D7;">
                                <td style="width: 30%;">
                                    <span class="hoten" style="float: left;">
                                    {{currentLanguage.body_text_congty_hdh_tvh}}
                                    </span>
                                    <img style="width: 150px!important;float: right!important;max-width: fit-content;" src="img/avatar/tran_viet_hung.jpg">
                                </td>
                                <td style="width: 70%;">
                                    {{currentLanguage.body_text_congty_hdqt_ThanhvienHDQT}}<br>

                                    <a target="_blank" href="https://cafebiz.vn/cau-chuyen-kinh-doanh/tran-viet-hung-cuu-sinh-cambridge-stanford-bo-kinh-te-chon-startup-giao-duc-20151214112003798.chn">https://cafebiz.vn/cau-chuyen-kinh-doanh/tran-viet-hung-cuu-sinh-cambridge-stanford-bo-kinh-te-chon-startup-giao-duc-20151214112003798.chn</a>
                                    <a target="_blank" href="https://www.facebook.com/viethung.tran">https://www.facebook.com/viethung.tran</a>
                                </td>
                            </tr>

                        </table>

                    </div>
                    <div class="dvqd" style="width: 100%;">

                        <ul class="quyetdinh">
                            <li>
                            <a target="_blank" href="https://drive.google.com/file/d/1VInTfiuGyS9IgUqu5eR26GxG_xI_GdlP/view?usp=sharing">
                                <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_Bien_ban_hop_HDQT}} </span>
                            </a>
                         </li>

                           

                            <li>
                                <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=23_7_6_Bien_ban_hop_HDQT.pdf">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay13062023}} </span>
                                </a>
                            </li>

                         

                            <li>
                                <a target="_blank" href="https://web.vimass.vn/vmbank/services/media/getImage?id=nghiquyethdquantri.jpg">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_NghiQuyetHoiDongQuanTri16032023}} </span>
                                </a>
                            </li>


                            <li>
                                <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=23_4_17_Bien_ban_hop_HDQT.pdf">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay17042023}} </span>
                                </a>
                            </li>
                          
                         
                            <li>
                                <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=23_3_17_Bien_ban_Hop_HDQT.pdf">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay17032023}}  </span>
                                </a>
                            </li>
                           

                            <li style="    list-style: none;">

                                <a href="https://vimass.vn/vmbank/services/media/viewFile?id=22_10_6_Bien_ban_hop_HDQT.pdf" target="_blank">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay06102022}}  </span>
                                </a>

                            </li>

                           
                            <li style="list-style: none;">


                                <a href="https://vimass.vn/vmbank/services/media/viewFile?id=22_8_2_Bien_ban_hop_HĐQT.pdf" target="_blank">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay02082022}}  </span>
                                </a>

                            </li>


                            <li style=" list-style: none;">
                                <a href="https://vimass.vn/vmbank/services/media/viewFile?id=Bien_ban_hop_HDQT_31_05_2022.pdf" target="_blank">

                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTngay31052022}}  </span>
                                </a>

                            </li>

                         
                            <li>
                                <a href="https://vimass.vn/vmbank/services/media/viewFile?id=22_4_28_Bien_ban_Hop_HDQT.pdf" target="_blank">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTngay28042022}}  </span>
                                </a>
                            </li>
                           


                         
                            <li>
                                <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=22_2 _21_Bao_cao_cua_CT HDQT_tai_DHDCD_thuong_nien.pdf">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BaocaocuaCTHDQTtaiDHDCDthuongnien21022022}}  </span>
                                </a>
                            </li>


                         
                            <li>
                                <a href="https://vimass.vn/vmbank/services/media/viewFile?id=22_1_25_Bien_ban_hop_HDQT.pdf" target="_blank">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQT2512022}}  </span>
                                </a>
                            </li>

                         
                            <li>
                                <a href="https://vimass.vn/vmbank/services/media/viewFile?id=2021_11_30_Bien_Ban_Hop_HDQT_Vimass.pdf" target="_blank">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay30112021}}  </span>
                                </a>
                            </li>


                            <li>
                                <a href="https://vimass.vn/vmbank/services/media/viewFile?id=21_10_28_Bien_Ban_Hop_HDQT.pdf" target="_blank">
                                    <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay28102021}}  </span>
                                </a>
                            </li>


                        </ul>

                    </div>
                </div>
            </div>
        </div>
</template>
<script>

  import { languages } from "@/components/languages";


  export default {
    components: {
   
     
    },
    data() {
      return {
    
  
        currentLanguage: languages.vi,
        lang: "",
       

      };
    },
    mounted() {
      this.lang = localStorage.getItem("languages");
    },
    created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
    methods: {
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
      window.location.reload();
      },
  
     
   
    },
  };
  </script>