<template>
    <div class ="posEdit-1" :style="{ height: dynamicHeight }">
      <div class ="hideFromEdit posEdit-1__1">
        <form class ="posEdit-1__2">
          <span class ="fs-3 fw-bold">Thay đổi tên mục</span>
          <div class="inputWithIcon">
            <input type="text" placeholder="Tên danh mục" v-model="inputCategotyName" />
          <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
          </div>
          <div class="inputWithIcon">
            <input type="text" placeholder="Nhập 6 số token" v-model="inputToken" maxlength="6" />
          <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
          </div>
          <div class ="span--style-h">
              <span class ="fs-5 ">{{notification}} </span>
          </div>
          <button @click.prevent="EditCategory" class="btn int btn-outline-primary btn-style">
            Thực hiện
          </button>
        
        </form>
      </div>
    </div>
</template>

<script>
  import {postEditCategory} from "@/components/js/VposService";
  export default {
    props: {
      item: Object,
      parentFuncEdit:{
        type: Function,
        required: true
     }
    }, 
    data() {
      return {  
        inputToken: "", inputCategotyName: "", notification: "", distanceH:70
      };
    },
    computed: {
    dynamicHeight() {
      // sử dụng calc()
      return `calc(100% - ${this.distanceH}px)`;
    }
  },
    methods:{

      getCateName(){
      // alert("inputCategotyName ======> " + this.item.catName);
        this.inputCategotyName = this.item.catName;
      },

      async EditCategory(){
      try{
        const response = await postEditCategory(this.inputToken, this.item.idStore,this.item.id,this.inputCategotyName, 1);
          console.log("response ========>"+ JSON.stringify(response));
          if(response.msgCode == 1){
             this.notification = response.msgContent;
            setTimeout(() => {
                this.notification = "";
                this.inputCategotyName = "";
                this.inputToken ="";
                this.parentFuncEdit()
                }, 2000);
           
          }else{
            this.notification = response.msgContent;
          }
          }catch(e){
              console.log("Exception e:",e);
          }
          
      },
   }
  }
</script>
<style scoped>
.posEdit-1{
  position: absolute; background: rgba(13, 13, 14, 0.52); width: 100%; top: 70px;
}
.posEdit-1__1{
  width: 100%; height: 100%;z-index: 1; display: flex;  justify-content: center;
}
.posEdit-1__2{
  background-color: white;padding: 32px; border-radius: 18px; min-width: 391px; height: 288px; margin-top: 100px;
}
</style>