<template>
     <div id="bankiemsoat" >

<div class="group" style="padding-left: 10px;">
    <div class="contentNew">

        <table style="width: 100%;float: left;">
            <tr>
                <td colspan="2" style="text-align: center;font-size: 21px; text-transform: uppercase;">{{currentLanguage.header_text_congTy_banKiemSoat}}</td>
            </tr>
            <tr style="background: #c3f0f7;">
                <td style="width: 30%;text-align: center;text-transform: uppercase;font-weight: bold;">{{currentLanguage.body_text_congty_hdqt_HOTEN}}</td>
                <td style="text-align: center;text-transform: uppercase;font-weight: bold;">{{currentLanguage.body_text_congty_hdqt_CHUCVU}}</td>
            </tr>
            <tr style="border-bottom: 1px solid #D7D7D7;">
                <td style="width: 30%;">
                    <span class="hoten" style="float: left;">
                    {{currentLanguage.body_text_congty_hdh_tnt}}
                    </span>
                    <div style="width: 150px;border: 1px solid;float: right;">
                        <img src="img/avatar/TNT.jpg">
                    </div>
                </td>
                <td>
                    <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_hdqt_truongBanKiemSoat}}</span>
                    <span style="width: 100%;float: left;word-break: break-all;">CEO Mobicast - <a href="https://www.mobicast.vn/vi" target="_blank">https://www.mobicast.vn/vi</a> </span>
                    <span>Email: trung.trann@@gmail.com</span>
                </td>
            </tr>
            <tr style="border-bottom: 1px solid #D7D7D7;">
                <td style="width: 30%;">
                    <span class="hoten" style="float: left;">
                    {{currentLanguage.body_text_congty_hdh_tth}}
                    </span>
                    <div style="width: 150px;border: 1px solid;float: right;">
                        <img src="img/avatar/TTH.png">
                    </div>
                </td>
                <td>
                    <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_hdqt_thanhVienBanKiemSoat}} </span>
                    <span style="width: 100%;float: left;word-break: break-all;">Mrs Việt Nam 2018</span>
                    <span>Email: hienlinhgia@@gmail.com </span>
                </td>
            </tr>

        </table>


    </div>
</div>

</div>
</template>
<script>

  import { languages } from "@/components/languages";


  export default {
    components: {
   
     
    },
    data() {
      return {
    
  
        currentLanguage: languages.vi,
        lang: "",   

      };
    },
    mounted() {
      this.lang = localStorage.getItem("languages");
    },
    created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
    methods: {
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
      window.location.reload();
      },
  

    },
  };
  </script>
