
//utf8
export const getUTF_8 = (name) => {

  var originalString = decodeURIComponent(escape(name));
  return originalString;
};
//date
export const getDate = (date) => {
  var timeShow = new Date(date);
  var options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return timeShow.toLocaleDateString("en-GB", options);
};
//time
export const getTimeddmmyyyyCachHHmm = (time) => {
  var timeShow = new Date(time);
  var options = { hour: "2-digit", minute: "2-digit", hour12: false };
  return timeShow.toLocaleTimeString("en-GB", options);
};

//cout time
export const  tongTime = (thoiGianVao, thoiGianRa)=> {
  let hhmm = "";

  // Create Date objects and set the entry and exit times
  // const dateVao = new Date(thoiGianVao);
  // const dateRa = new Date(thoiGianRa);
  // Calculate the time difference
  const diffMillis = thoiGianRa - thoiGianVao;
  const diffHours = Math.floor(diffMillis / (60 * 60 * 1000));
  const diffMinutes = Math.floor((diffMillis / (60 * 1000)) % 60);
  hhmm = `${diffHours} : ${diffMinutes}`;
  return hhmm;
}
//encode
export const decodeBase64 =(decodedString) =>{
  return atob(decodedString);
}
//lay bai viet
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
export const  performPostRequestBaiVietVpass = async (idBaiViet) =>{
  try {
    // Thực hiện POST request
    const response = await axios.post(
      apiChiTietBaiViet,
      {
        id: idBaiViet,
        langId: "0",
      }
    );

    var base64 = response.data.result.content_vi;
    var base64Title_vi = response.data.result.title_vi;

    var title_vi = atob(base64Title_vi);
    this.title_vi = decodeURIComponent(escape(title_vi));

    var decodedString = atob(base64);
    this.decodedString = decodeURIComponent(escape(decodedString));

  } catch (error) {
    console.error("Error:", error);
  }
}
export const b64EncodeUnicode = (str) => {
  // Trước tiên mã hóa str thành UTF-8, sau đó mã hóa thành Base64
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
  }));
}
