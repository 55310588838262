export const imageUrls = {
  logoVpass: "/img/vpos/logo/vpos.png",
  logoVpassXanh: "/img/vpos/logo/vposXanh.png",
  urlAnh: "https://web.vimass.vn/vmbank/services/media/getImage?id=",
  urlAnh2: "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=",
  iconSetting: "/img/icon/icon-Setting.png",

  //trang chu
  iconBannerVpos: "img/vpos/iconTrangChu/iconBannerVpos.png",
  qrVpos: "img/vpos/iconTrangChu/QRvpos.png",
  downloadIos: "img/vpos/iconTrangChu/ios.png",
  downloadAndroid: "img/vpos/iconTrangChu/android.png",
  iconTyGia: "img/vpos/iconTrangChu/tyGia.png",
  iconcuaHang: "img/vpos/iconTrangChu/cuaHang.png",
  iconSanPham: "img/vpos/iconTrangChu/sanPham.png",
  iconKhuyenMai: "img/vpos/iconTrangChu/khuyenMai.png",
  iconThayDoi: "img/vpos/iconTrangChu/thayDoi.png",
  iconSaoKe: "img/vpos/iconTrangChu/saoKe.png",
  iconTraCuu: "img/vpos/iconTrangChu/search.png",
  iconDele: "img/vpos/iconTrangChu/iconDelete.png",
  iconMap: "img/vpos/iconTrangChu/iconMap.svg",

  //phone
  img1: "img/vpos/imgPhoneVpos/dangKy.png",
  img2: "img/vpos/imgPhoneVpos/dangNhap.png",
  img3: "img/vpos/imgPhoneVpos/taiKhoan.png",
  img3_1: "img/vpos/imgPhoneVpos/cuaHangPhone.png",
  img4: "img/vpos/imgPhoneVpos/taoCuaHang.png",
  img5: "img/vpos/imgPhoneVpos/thayDoi.png",
  img6: "img/vpos/imgPhoneVpos/datHang.png",
  img7: "img/vpos/imgPhoneVpos/thanhToan.png",
  img8: "img/vpos/imgPhoneVpos/saoKe.png",
  img9: "img/vpos/imgPhoneVpos/traCuu.png",

  //webtab

  imgWeb1: "img/vpos/imgWebTabVpos/dangNhapWeb.png",
  imgWeb2: "img/vpos/imgWebTabVpos/banHang.png",
  imgWeb3: "img/vpos/imgWebTabVpos/taoCuaHang.png",
  imgWeb4: "img/vpos/imgWebTabVpos/traCuu.png",
};

export const langBanner = {
  vi: {
    imgWeb1: "img/vpos/imgWebTabVpos/dangNhapWeb.png",
    imgWeb2: "img/vpos/imgWebTabVpos/banHang.png",
    imgWeb3: "img/vpos/imgWebTabVpos/taoCuaHang.png",
    imgWeb4: "img/vpos/imgWebTabVpos/traCuu.png",

    //phone
    img1: "img/vpos/imgPhoneVpos/img_phone_1.png",
    img2: "img/vpos/imgPhoneVpos/dangNhap.png",
    img2_1: "img/vpos/imgPhoneVpos/dangKy.png",
    img3: "img/vpos/imgPhoneVpos/taiKhoan.png",
    img3_1: "img/vpos/imgPhoneVpos/cuaHangPhone.png",
    img4: "img/vpos/imgPhoneVpos/taoCuaHang.png",
    img5: "img/vpos/imgPhoneVpos/thayDoi.png",
    img6: "img/vpos/imgPhoneVpos/datHang.png",
    img7: "img/vpos/imgPhoneVpos/thanhToan.png",
    img8: "img/vpos/imgPhoneVpos/saoKe.png",
    img9: "img/vpos/imgPhoneVpos/traCuu.png",
  },
  en: {
    imgWeb1: "img/vpos/imgWebTabVpos/dangNhapWebEn.png",
    imgWeb2: "img/vpos/imgWebTabVpos/banHangEn.png",
    imgWeb3: "img/vpos/imgWebTabVpos/taoCuaHangEn.png",
    imgWeb4: "img/vpos/imgWebTabVpos/traCuuEn.png",
    //phone
    img1: "img/vpos/imgPhoneVpos/homeEn.jpg",
    img2: "img/vpos/imgPhoneVpos/dangNhapEn.png",
    img2_1: "img/vpos/imgPhoneVpos/dangKyEn.png",
    img3: "img/vpos/imgPhoneVpos/taiKhoanEn.png",
    img3_1: "img/vpos/imgPhoneVpos/cuaHangPhoneEn.png",
    img4: "img/vpos/imgPhoneVpos/taoCuaHangEn.png",
    img5: "img/vpos/imgPhoneVpos/thayDoiEn.jpg",
    img6: "img/vpos/imgPhoneVpos/datHangEn.jpg",
    img7: "img/vpos/imgPhoneVpos/thanhToanEn.png",
    img8: "img/vpos/imgPhoneVpos/saoKeEn.png",
    img9: "img/vpos/imgPhoneVpos/Tracuu.png",
  },
};
