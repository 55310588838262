export const languages = {
  vi: {
    header_menuCuaHang: "Cửa hàng",
    header_TyGia: "Tỷ giá",
    header_SanPham: "Sản phẩm",
    header_taoThayDoi: "Tạo/ Thay đổi",
    header_KhuyenMai: "Khuyến mại",
    header_SaoKe: "Sao kê",
    header_TraCuu: "Tra cứu",
    header_DangNhap: "Đăng nhập",
    title_VimassPOS: "Vimass POS ",
    title_link_pos: "pos.vimass.vn ",
    title_UngDung: "Ứng dụng",
    titleTaiUngDung: "Tải ứng dụng",
    title_trenmaytinh: " trên máy tính",
    titleDangNhap: "Đăng nhập",
    titleDangKy: "Đăng ký",
    titleSanPham: "Sản phẩm",
    titleBanHang: " Bán hàng",
    titleThanhToan: "Thanh toán",
    titleTaoCuaHang: "Tạo cửa hàng",
    titleThayDoiThongTin: "Thay đổi thông tin",
    titleSaoKe: "Sao kê",
    titleTraCuu: "Tra cứu",
    titleTaiKhoan: "Tài khoản",
    titleDatHang: "Đặt hàng",
    menu_KhuyenMai: "Khuyến mại",
    titleCapNhat: "Cập nhật",
    titleDongVietNam:"Việt Nam Đồng",
    //web
    contentDangKyWeb:
      "Nhập số điện thoại, mật khẩu đăng ký và mật khẩu token để đăng ký tài khoản",
    contentDangNhapWeb:
      "Nhập số điện thoại và mật khẩu để đăng nhập trên website",
    contentSanPhamWeb:
      "Hiển thị toàn bộ sản phẩm, dịch vụ của cửa hàng Khách hàng quét QR để gọi món, chọn sản phẩm, dịch vụ, số lượng để tạo đơn hàng",
    contentThanhToanWeb:
      "Tất cả ứng dụng ngân hàng, ví điện tử đều có thể quét QR thanh toán  hoặc đứng trước camera xác thực mặt để thanh toán bằng thẻ Vimass",
    contentTaoCuaHangWeb:
      "Tên cửa hàng, địa chỉ, tọa độ, địa điểm, ảnh chụp cửa hàng, tài khoản ngân hàng nhận tiền, hạn mức rút tiền về tài khoản",
    contentThayDoiThongTinWeb: "Thêm, sửa xóa thông tin, danh mục, sản phẩm",
    contentSaoKeWeb:
      "Sao kê toàn bộ giao dịch nhận tiền theo từng bàn, từng cửa hàng từ ngày X đến ngày Y",
    contentTraCuuWeb:
      "Tra cứu giao dịch, doanh thu, dòng tiền theo từng cửa hàng. <br> Thống kê giao dịch, tiền nhận về tài khoản, phí theo ngày, tháng, năm",

    //phone
    title_trendienthoai: "Ứng dụng Vimass POS trên điện thoại",
    contentDangKyPhone:
      "Nhập số điện thoại, mật khẩu đăng ký và mật khẩu token để đăng ký tài khoản",
    contentDangNhapPhone:
      "Nhập số điện thoại và mật khẩu hoặc face ID để đăng nhập",
    contentTaiKhoanPhone:
      "Bao gồm thông tin tài khoản, danh sách cửa hàng, bán hàng, chỉnh sửa thông tin sản phẩm, sao kê, trả cứu, QR cửa hàng",
    contentSanPhamPhone:
      "Hiển thị toàn bộ sản phẩm, dịch vụ của cửa hàng Khách hàng quét QR để gọi món, chọn sản phẩm, dịch vụ, số lượng để tạo đơn hàng",
    contentThanhToanPhone:
      "Tất cả ứng dụng ngân hàng, ví điện tử đều có thể quét QR thanh toán. Hỗ trợ thanh toán chuyển đổi ngoại tệ (USD, EUR, CNY, JPY, KRW)",
    contentTaoCuaHangPhone:
      "Nhập tên cửa hàng, địa chỉ, tọa đổ địa điểm, ảnh chụp cửa hàng, ảnh QR nhận thanh toán và ảnh bảng giá để tạo cửa hàng",
    contentThayDoiThongTinPhone:
      "Thêm, sửa xóa thông tin, danh mục, sản phẩm của hàng",
    contentSaoKePhone:
      "toàn bộ giao dịch nhận tiền theo từng bàn, từng cửa hàng từ ngày X đến ngày Y",
    contentTraCuuPhone:
      "giao dịch, doanh thu, chuyển tiền tự động về tài khoản ngân hàng theo từng cửa hàng. Thống kê giao dịch, tiền nhận về tài khoản, phí theo ngày, tháng, năm",
    contentDatHangPhone:
      "Xác nhận đơn hàng, nhập mã số thuế, email, điện thoại để nhận hóa đơn",
    // footer
    huongDanSuDungWeb: "Hướng dẫn ứng dụng Web",
    huongDanSuDungPhone: "Hướng dẫn ứng dụng điện thoại",
    hopDongAPI: "Hợp đồng dịch vụ thu hộ qua API",
    CongTyVimass: "Công ty cổ phần nền dịch vụ di động Việt Nam",
    giayPhepTGTT: "Giấy phép trung gian thanh toán số 41/GP-NHNN",
    capNhapTyGia: "Thời gian cập nhật",
    chiTietCuaHang_Tong: "Tổng số",
    chiTietCuaHang_maSoThueNhanHoaDon: "Mã số thuế nhận hóa đơn",
    chiTietCuaHang_emailNhanHoaDon: "Email",
    chiTietCuaHang_dienThoai: "Điện thoại",
    chiTietCuaHang_datHang: "Đặt hàng",
    chiTietCuaHang_donHang: "Mã đơn hàng",
    chiTietCuaHang_quayLai: "Quay lại",
    chiTietCuaHang_huy: "Hủy",
    chiTietCuaHang_chuaCoSanPhamNao: "Chưa có sản phẩm nào trong giỏ hàng",
    chiTietCuaHang_donHangMa: "Đơn hàng",
    chiTietCuaHang_khuyenMai: "Khuyến mại",
    chiTietCuaHang_SDTvaEmail: "Số điện thoại / Email",
    chiTietCuaHang_tim: "Tìm",
    chiTietCuaHang_tatCa: "Tất cả",
    conttentPhi : "< 10 phí 2k, từ 10 đến 20 phí 1k, > 20 miễn phí",
    chiTietCuaHang_hoaDon: "Hóa đơn",
    chiTietCuaHang_giaoHang: "Giao hàng",
    chiTietCuaHang_KhuyenMai: "Khuyến mại",
    chiTietCuaHang_dienThoaiGiaoHang: "Điện thoại giao hàng",
    chiTietCuaHang_diaChiGiaoHang: "Địa chỉ giao hàng",
    chiTietCuaHang_phiGiaoHang: "Phí giao hàng",
    chiTietCuaHang_maCuaHang: "Mã cửa hàng: ",
    chiTietCuaHang_tienMat: "Tiền mặt",
    chiTietCuaHang_theVimass: "Thẻ ViMASS",
    chiTietCuaHang_token: "Token",
    chiTietCuaHang_xacNhan: "Xác nhận",

  },
  en: {
    titleDongVietNam:"VIET NAM DONG",
    titleCapNhat: "Update",
    header_menuCuaHang: "Shop",
    header_TyGia: "Exchange rate",
    header_SanPham: "Product",
    header_taoThayDoi: "Create/Change",
    header_Promotions: "Promotion",
    titleTaiKhoan: "Statement",
    header_TraCuu: "Search ",
    header_DangNhap: "Login",
    title_VimassPOS: "Vimass POS ",
    title_link_pos: "pos.vimass.vn ",
    title_UngDung: "Application",
    titleTaiUngDung: "Download the application",
    title_trenmaytinh : "on computers",
    titleDangNhap: "Login",
    titleDangKy: "Register",
    titleSanPham: "Product",
    titleThanhToan: "Payment",
    titleTaoCuaHang: "Create store",
    titleThayDoiThongTin: "Change information",
    titleSaoKe: "Statement",
    titleTraCuu: "Search ",
    titleTaiDhan: "Account",
    titleDatHang: "Order",
    menu_KhuyenMai: "Promotion",
    titleBanHang: " Sales",
    //web
    contentDangKyWeb:
      "Enter phone number, registration password and token password to register account",
    contentDangNhapWeb:
      "Enter phone number and password to log in on the website",
    contentSanPhamWeb:
      "Display all products and services of the store Customers scan QR to order, select products, services, quantity to create orders",
    contentThanhToanWeb:
      "All banking applications and e-wallets can scan QR payments or stand in front of a camera to authenticate your face to pay with Vimass cards,",
    contentTaoCuaHangWeb:
      "Store name, address, coordinates, location, store photo, receiving bank account, withdrawal limit to account",
    contentThayDoiThongTinWeb:
      "Add, edit, delete information, categories, products",
    contentSaoKeWeb:
      "Statement of all cash receipt transactions by each table, each store from day X to day Y",
    contentTraCuuWeb:
      "Look up transactions, revenue, cash flow by each store. <br> Transaction statistics, money received into account, fees by day, month, year",

    //phone
    title_trendienthoai: "Vimass POS application on phone",
    contentDangKyPhone:
      "Enter phone number, registration password and token password to register account",
    contentDangNhapPhone:
      "Enter phone number and password or face ID to log in",
    contentTaiKhoanPhone:
      "Account information, store list, products, change information, statement, search , Save/Delete card",
    contentSanPhamPhone:
      "Display all products and services of the store Customers scan QR to order, select products, services, quantity to create an order",
    contentThanhToanPhone:
      "All banking applications and e-wallets can scan QR payments. Support foreign currency conversion payments (USD, EUR, CNY, JPY, KRW)",
    contentTaoCuaHangPhone:
      "Store name, address, location coordinates, store photo, Receiving bank account, Withdrawal limit to account",
    contentThayDoiThongTinPhone:
      "Add, edit, delete store information, categories, products",
    contentSaoKePhone:
      "all money receiving transactions by each table, each store from day X to day Y",
    contentTraCuuPhone:
      "transactions, revenue, automatic money transfer to bank accounts for each store. Transaction statistics, money received to accounts, fees by day, month, year",
    contentDatHangPhone:
      "Confirm order, enter tax code, email, phone to receive invoice",
    // footer
    huongDanSuDungWeb: "Web application guide",
    huongDanSuDungPhone: "Phone application guide",
    hopDongAPI: "Collection service contract via API",
    CongTyVimass: "Vietnam Mobile-base services Joint Stock Company",
    giayPhepTGTT: "Payment intermediary license No. 41/GP-NHNN",
    capNhapTyGia: "Exchange rates updated at",
    chiTietCuaHang_Tong: "Total",
    chiTietCuaHang_maSoThueNhanHoaDon: "Company code",
    chiTietCuaHang_emailNhanHoaDon: "Email",
    chiTietCuaHang_dienThoai: "Phone",
    chiTietCuaHang_datHang: "Order",
    chiTietCuaHang_donHang: "Order code",
    chiTietCuaHang_quayLai: "Go back",
    chiTietCuaHang_huy: "Cancel",
    chiTietCuaHang_chuaCoSanPhamNao: "There are no products in the cart",
    chiTietCuaHang_donHangMa: "Orders",
    chiTietCuaHang_khuyenMai: "Promotion",
    chiTietCuaHang_SDTvaEmail: "Phone number / Email",
    chiTietCuaHang_tim: "Search",
    chiTietCuaHang_tatCa: "All",
    chiTietCuaHang_hoaDon: "Invoice",
    chiTietCuaHang_giaoHang: "Delivery",
    chiTietCuaHang_KhuyenMai: "Promotion",
    chiTietCuaHang_maCuaHang: "Store code: ",
    chiTietCuaHang_tienMat: "Cash",
    chiTietCuaHang_theVimass: "ViMASS Card",
    chiTietCuaHang_token: "Token",
    chiTietCuaHang_xacNhan: "Confirm",
  },
  cn: {
    titleDongVietNam:"VIET NAM DONG",
    header_menuCuaHang: "商店",
    header_TyGia: "汇率",
    header_SanPham: "产品",
    header_taoThayDoi: "创建/更改",
    header_Promotions: "促销",
    titleTaiKhoan: "声明",
    header_TraCuu: "搜索",
    header_DangNhap: "登录",
    title_VimassPOS: "电脑和平板电脑上的 Vimass POS",
    title_link_pos: "pos.vimass.vn ",
    title_UngDung: "应用",
    titleTaiUngDung: "下载这个软件",
    title_trenmaytinh: " 在电脑上面",
    titleDangNhap: "登录",
    titleDangKy: "注册",
    titleSanPham: "产品",
    titleThanhToan: "付款",
    titleTaoCuaHang: "创建商店",
    titleThayDoiThongTin: "更改信息",
    titleSaoKe: "声明",
    titleTraCuu: "搜索",
    titleTaiDhan: "帐户",
    titleDatHang: "订单",
    menu_KhuyenMai: "促销",
    titleBanHang: "销售",
    //网络
    contentDangKyWeb: "输入电话号码/邮箱、注册密码和令牌密码来注册账户",
    contentDangNhapWeb: "输入电话号码/电子邮件和密码登录网站",
    contentSanPhamWeb:
      "显示店铺所有产品和服务顾客扫描二维码下单，选择产品、服务、数量创建订单",
    contentThanhToanWeb:
      "所有银行应用程序和电子钱包都可以扫描二维码付款或站在摄像头前验证您的脸部以使用 Vimass 卡进行支付，",
    contentTaoCuaHangWeb:
      "店铺名称、地址、坐标、位置、店铺照片、收款银行账户、账户提现限额",
    contentThayDoiThongTinWeb: "添加、编辑、删除信息、类别、产品",
    contentSaoKeWeb: "X天到Y天每张桌子、每家商店的所有现金收据交易报表",
    contentTraCuuWeb:
      "查询每家商店的交易、收入、现金流。<br>交易统计、收到的款项、按日、月、年收费",

    //电话
    title_trendienthoai: "手机上的 Vimass POS 应用程序",
    contentDangKyPhone: "输入电话号码/邮箱、注册密码和token密码来注册账户",
    contentDangNhapPhone: "输入电话号码/电子邮件和密码或面容 ID 登录",
    contentTaiKhoanPhone:
      "账户信息、商店列表、产品、更改信息、声明、搜索、保存/删除卡",
    contentSanPhamPhone:
      "显示店铺所有产品和服务顾客扫描二维码下单，选择产品、服务、数量创建订单",
    contentThanhToanPhone:
      "所有银行应用程序和电子钱包都可以扫描二维码支付。支持外币兑换支付（美元、欧元、人民币、日元、韩元）",
    contentTaoCuaHangPhone:
      "店铺名称、地址、位置坐标、店铺照片、收款银行账户、账户提现限额",
    contentThayDoiThongTinPhone: "添加、编辑、删除商店信息、类别、产品",
    contentSaoKePhone: "从第X天到第Y天每张桌子、每家商店的所有收款交易",
    contentTraCuuPhone:
      "交易、收入、自动转账到每个商店的银行账户。交易统计、账户收到的资金、按日、月、年收取的费用",
    contentDatHangPhone: "确认订单，输入税码、电子邮件、电话以接收发票",
    // 页脚
    huongDanSuDungWeb: "Web应用指南",
    huongDanSuDungPhone: "电话申请指南",
    hopDongAPI: "通过API采集服务合约",
    CongTyVimass: "越南移动基础服务股份公司",
    giayPhepTGTT: "支付中介牌照编号41/GP-NHNN",
    capNhapTyGia: "汇率更新于",
    chiTietCuaHang_Tong: "总计",
    chiTietCuaHang_maSoThueNhanHoaDon: "发票收据税码",
    chiTietCuaHang_emailNhanHoaDon: "发票收据电子邮件",
    chiTietCuaHang_dienThoai: "电话",
    chiTietCuaHang_datHang: "订单",
    chiTietCuaHang_donHang: "订单",
    chiTietCuaHang_quayLai: "回去",
    chiTietCuaHang_huy: "取消",
    chiTietCuaHang_chuaCoSanPhamNao: "购物车中没有产品",
    chiTietCuaHang_donHangMa: "订单",
    chiTietCuaHang_khuyenMai: "促销",
    chiTietCuaHang_SDTvaEmail: "电话号码/电子邮件",
    chiTietCuaHang_tim: "搜索",
    chiTietCuaHang_tatCa: "全部",
    chiTietCuaHang_hoaDon: "发票",
    chiTietCuaHang_giaoHang: "送货",
    chiTietCuaHang_KhuyenMai: "促销",
    chiTietCuaHang_maCuaHang: "商店代码: ",
    chiTietCuaHang_tienMat: "现金",
    chiTietCuaHang_theVimass: "ViMASS 卡",
    chiTietCuaHang_token: "令牌",
    chiTietCuaHang_xacNhan: "确认",
  },
  kr: {
    titleDongVietNam:"VIET NAM DONG",
    header_menuCuaHang: "저장",
    header_TyGia: "환율",
    header_SanPham: "제품",
    header_taoThayDoi: "생성/변경",
    header_Promotions: "프로모션",
    titleTaiKhoan: "진술",
    header_TraCuu: "검색",
    header_DangNhap: "로그인",
    title_VimassPOStrenmaytinh: "컴퓨터와 태블릿의 Vimass POS",
    titleDangNhap: "로그인",
    titleDangKy: "등록",
    titleSanPham: "제품",
    titleThanhToan: "결제",
    titleTaoCuaHang: "스토어 만들기",
    titleThayDoiThongTin: "정보 변경",
    titleSaoKe: "진술",
    titleTraCuu: "검색 ",
    titleTaiDhan: "계정",
    titleDatHang: "주문",
    menu_KhuyenMai: "프로모션",
    titleBanHang: " 판매",
    //편물
    contentDangKyWeb:
      "계정을 등록하려면 전화번호/이메일, 등록 비밀번호, 토큰 비밀번호를 입력하세요.",
    contentDangNhapWeb:
      "웹사이트에 로그인하려면 전화번호/이메일 및 비밀번호를 입력하세요",
    contentSanPhamWeb:
      "상점의 모든 제품과 서비스를 표시합니다. 고객은 QR을 스캔하여 주문하고, 제품, 서비스, 수량을 선택하여 주문을 생성합니다.",
    contentThanhToanWeb:
      "모든 뱅킹 애플리케이션과 전자 지갑은 QR 결제를 스캔하거나 카메라 앞에 서서 얼굴을 인증하여 Vimass 카드로 결제할 수 있습니다.",
    contentTaoCuaHangWeb:
      "상점 이름, 주소, 좌표, 위치, 매장 사진, 수취 은행 계좌, 계좌 출금 한도",
    contentThayDoiThongTinWeb: "정보, 카테고리, 제품 추가, 편집, 삭제",
    contentSaoKeWeb:
      "X일부터 Y일까지 각 테이블, 각 매장별 모든 현금 영수증 거래 내역",
    contentTraCuuWeb:
      "상점별 거래내역, 매출, 현금흐름을 조회합니다. <br> 거래통계, 계좌입금액, 일별, 월별, 연도별 수수료",

    //핸드폰
    title_VimassPOStrendienthoai: "전화상의 Vimass POS",
    contentDangKyPhone:
      "계정을 등록하려면 전화번호/이메일, 등록 비밀번호, 토큰 비밀번호를 입력하세요.",
    contentDangNhapPhone:
      "로그인하려면 전화번호/이메일과 비밀번호 또는 얼굴 ID를 입력하세요",
    contentTaiKhoanPhone:
      "계정정보, 매장목록, 상품, 변경정보, 명세서, 검색, 카드 저장/삭제",
    contentSanPhamPhone:
      "상점의 모든 제품과 서비스를 표시합니다. 고객은 QR을 스캔하여 주문하고, 제품, 서비스, 수량을 선택하여 주문을 생성합니다.",
    contentThanhToanPhone:
      "모든 뱅킹 애플리케이션과 전자 지갑은 QR 결제를 스캔할 수 있습니다. 외화 환전 결제(USD, EUR, CNY, JPY, KRW)를 지원합니다.",
    contentTaoCuaHangPhone:
      "상점명, 주소, 위치좌표, 매장사진, 수취은행계좌, 계좌출금한도",
    contentThayDoiThongTinPhone: "상점 정보, 카테고리, 제품 추가, 편집, 삭제",
    contentSaoKePhone:
      "X일부터 Y일까지 각 테이블, 각 매장별로 모든 돈을 받는 거래",
    contentTraCuuPhone:
      "거래, 수익, 각 상점의 은행 계좌로 자동 이체. 거래 통계, 계좌로 받은 돈, 일별, 월별, 연도별 수수료",
    contentDatHangPhone:
      "주문 확인, 세금 코드 입력, 이메일, 송장 수신 전화번호",
    // 바닥글
    huongDanSuDungWeb: "웹 애플리케이션 가이드",
    huongDanSuDungPhone: "전화 신청 안내",
    hopDongAPI: "API를 통한 수집 서비스 계약",
    CongTyVimass: "베트남 모바일 기반 서비스 합작회사",
    giayPhepTGTT: "결제 중개 라이센스 번호 41/GP-NHNN",
    capNhapTyGia: "환율 업데이트 시간:",
    chiTietCuaHang_Tong: "합계",
    chiTietCuaHang_maSoThueNhanHoaDon: "송장 영수증 세금 코드",
    chiTietCuaHang_emailNhanHoaDon: "송장 영수증 이메일",
    chiTietCuaHang_dienThoai: "전화",
    chiTietCuaHang_datHang: "주문",
    chiTietCuaHang_donHang: "주문",
    chiTietCuaHang_quayLai: "뒤로 돌아가기",
    chiTietCuaHang_huy: "취소",
    chiTietCuaHang_chuaCoSanPhamNao: "장바구니에 제품이 없습니다",
    chiTietCuaHang_donHangMa: "주문",
    chiTietCuaHang_khuyenMai: "프로모션",
    chiTietCuaHang_SDTvaEmail: "전화번호 / 이메일",
    chiTietCuaHang_tim: "검색",
    chiTietCuaHang_tatCa: "모두",
    chiTietCuaHang_hoaDon: "송장",
    chiTietCuaHang_giaoHang: "배달",
    chiTietCuaHang_KhuyenMai: "프로모션",
    chiTietCuaHang_maCuaHang: "상점 코드: ",
    chiTietCuaHang_tienMat: "현금",
    chiTietCuaHang_theVimass: "ViMASS 카드",
    chiTietCuaHang_token: "토큰",
    chiTietCuaHang_xacNhan: "확인",
  },
};
