<template>
  <p id="demo"></p>
</template>
<script>
export default {

mounted() {
 var str = window.navigator.userAgent;
 var str1 = "https://vimass.vn/vi/giaiphapchuyendoiso#vpass/"; // Default URL

 if (str.indexOf("Android") != -1) {
   str1 = "https://play.google.com/store/apps/details?id=com.qr.vpass";
 } else if (str.indexOf("iPhone") != -1) {
   str1 = "https://apps.apple.com/vn/app/vpass/id6448410181";
 }
 
 window.location.href = str1;
 console.log(str);
}
}

</script>