// store/index.js
import Vuex from 'vuex';


const store = new Vuex.Store({
  state: {
    user: null, 
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    loginUser({ commit }, user) {
      console.log("user "+ user);
      commit('setUser', user);
      localStorage.setItem('user', JSON.stringify(user));
    },
    logoutUser({ commit }) {
      commit('clearUser');
      localStorage.removeItem('user');
    },
    checkLocalStorage({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        commit('setUser', user);
      }
    },
  },
  getters: {
    loggedIn: state => !!state.user,
    currentUser: state => state.user,
  },
});
export default store
