<template>
  <div class="group" style="padding-left: 10px">
    <div class="contentNew">
      <ul class="quyetdinh">
        <li style="list-style: none">
          <a
            href="https://drive.google.com/file/d/160QZ2lkEiZFkprtRTCYh8m5PWT2qk3K3/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{ currentLanguage.body_text_congty_codong_CuaHangVaHoTroTHuCHiCuahang }}
            </span>
          </a>
        </li>
        <li style="list-style: none">
          <a
            href="https://drive.google.com/file/d/1mHAjcfsGoTty3lx4b8XQ3UnTLZuaRVfG/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{ currentLanguage.body_text_congty_codong_NghiQuyetHDQTVeTaiTroThiDiem }}
            </span>
          </a>
        </li>
        <li style="list-style: none">
          <a
            href="https://docs.google.com/spreadsheets/d/1vHsMT_uZ4EwHIa9y9CtA71NECRJ8-Oiz/edit?usp=sharing&ouid=106701618316117730835&rtpof=true&sd=true"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{ currentLanguage.body_text_congty_Hoatdongkinhdoanh2024 }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1C6sMGyLjD9aeH6rbiB-_Q5Y4MmWazftd/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Danhsachcodongvavongopngay04052023
              }}
            </span>
          </a>
        </li>
     
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1vIH7RJo4ntVSREZHEmAPOEKEc-SPvc_B/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_ChuCapHoiToanHoc
              }}
            </span>
          </a>
        </li>


        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/18cnmyUOJmpnCBeKeKZV0ERMEsvN7QPJN/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay05082004
              }}
            </span>
          </a>
        </li>
   
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1a3mpWkv237MAt04U8OuO_9M5DaR0uTXY/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_QDBanHanhbotieuchi
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1Ew0CvmiT2hN-8ftcSUzMDxjK611mZW1p/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_ThucHienQuyDinhChinhPhu
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1mnE7Ll17gqI7VWeBOeIMfAijOnVT9Kbz/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DVTGTT
              }}
            </span>
          </a>
        </li>
       
       
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1wLbmQYUopbXefy3ovHrlj4m_4_io59Xa/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_ThongtuNHNN
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1r7FcqKY3IADFkhBrSWwR3eAowpjTnmUH/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay25042024
              }}
            </span>
          </a>
        </li>
      
        
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1pmC8OfM_JoJ873fDtHJTGFj-oBOpLecR/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_HoSoCapDoAnToanHTTTVIMASS
              }}
            </span>
          </a>
        </li>
      
        <li style="list-style: none">
          <a
            target="_blank"
            :href="
              lang === 'vi'
                ? 'https://drive.google.com/file/d/16YSpIdry2RDd_0U3C4gtV34VBnuAvKS3/view?usp=sharing'
                : lang === 'en'
                ? 'https://drive.google.com/file/d/1tOHPNL_lgFy6iXXsc2RUNwMRG9tfOsbq/view?usp=sharing'
                : lang === 'cn'
                ? 'https://drive.google.com/file/d/1tOHPNL_lgFy6iXXsc2RUNwMRG9tfOsbq/view?usp=sharing'
                : lang === 'kr'
                ? 'https://drive.google.com/file/d/1tOHPNL_lgFy6iXXsc2RUNwMRG9tfOsbq/view?usp=sharing'
                : 'https://drive.google.com/file/d/1tOHPNL_lgFy6iXXsc2RUNwMRG9tfOsbq/view?usp=sharing'
            "
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Baocaokiemtoan2023CongTyCoPhanDichVuNenDiDongVietNamn
              }}
            </span>
          </a>
        </li>
       
       
        <li>
          <a
            href="https://drive.google.com/file/d/1ch2fOQ3ayG5Hi07-NoncehexSMEExAzk/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay050224
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1VInTfiuGyS9IgUqu5eR26GxG_xI_GdlP/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{ currentLanguage.body_text_congty_codong_Bien_ban_hop_HDQT }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://docs.google.com/spreadsheets/d/1DMe6WUhxBRTMKYTtjwRHdyE_t1LeWYWx/edit?usp=sharing&ouid=106701618316117730835&rtpof=true&sd=true"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{ currentLanguage.body_text_congty_Hoatdongkinhdoanh }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1jR0PnGjFFYnzspxL-4QoPz51uEROmyXJ/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_231027QuyetDinhThanhLapBanAnToanThongTin 
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1EnTkCe-WjOEd8fzimVofeh5XztEbJ5Xx/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_23_9_5_Quyet_dinh_phan_cong_nhiem_vu
              }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://drive.google.com/file/d/1peJ4hsHkJnqgoQmwJmZSPbvqALWBQk_K/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlanthu25
              }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://drive.google.com/file/d/1Uk-PjeeU3bh3NhpGCcv74AOQlWcSs0EH/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_QuyetdinhgiamgiolamCBNV
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_9_20_Thong_bao_ve_viec_dang_ky_tang_von_dieu_le.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_23_9_20_Thong_bao_ve_viec_dang_ky_tang_von_dieu_le
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1UJ87dnNydfd3XZ1mS1v8BrCZIFj7Nivo/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{ currentLanguage.body_HopDong_text_thongbaogopvon }}
            </span>
          </a>
        </li>
        <li></li>

        <li style="list-style: none">
          <a
            href="https://drive.google.com/file/d/1HNsLjxIi7zshmTKDlcSJvopMqX6xNAmG/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DieuLeCongTy
              }}
            </span>
          </a>
        </li>
        <li style="list-style: none">
          <a
            href="https://drive.google.com/file/d/19R5Tqoqld0F3ibMThy9Sa8d8iSWxh3Al/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay20072023
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://drive.google.com/file/d/1lEQAG8Rm6KvI32JUMjrS_lmA6LvQevVG/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlanthu24ngay6723
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1xmsrvQE7yMiRHh1U7fNF8BIkXil4HHk1/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay13062023
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_7_3_Quyet_dinh_ve_gia_ban_thiet_bi.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_23_7_3_Quyet_dinh_ve_gia_ban_thiet_bi
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=nghiquyethdquantri.jpg"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_NghiQuyetHoiDongQuanTri16032023
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_5_17_Quyet_dinh_ve_dai_ly_va_don_gia_thiet_bi_cua_Vimass.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_cn_23517QuyetdinhvedailyvadongiathietbicuaVimass
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1iwKEcWDD5r-KRh8ZD8_73CITeouWFinV/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_phongchongruatien
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_4_17_Bien_ban_hop_HDQT.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay17042023
              }}
            </span>
          </a>
        </li>
       
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1_Kq8_ehehNX3Syinn71pUlx2wV_QsnS_/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_04_07_Bien_ban_hop_DHDCD.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay07042023
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1epE1P0s2AWlcTAdqQqQl86oxB_QrOM23/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay17032023
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/19cxyZokouo8JS7ZG7DArl-orELpHQRkr/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Quyetdinhvenhomcongtacvienngay22022023
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_02_02_hop_dong_cung_cap_dich_vu_thu_chi_ho_cua_Vimass.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1wjhUjmV0EDlllINNd5FEw7KthvRmi43H/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_QuyetdinhvephidichvutaichinhcuaVimass01022023
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1_Dn6IsH0i1Rjsn4yhJa1McY02JBAuasI/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_QuyetdinhtrienkhaithunghiemthedanangtaiVietNamngay1712023
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1XWu1q6n3Qt0nbpDS8DDmWooeI7UOya0v/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_QuyetdinhcapthevatemchocanbonhanvienVimassngay1612023
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_03_14_Bao_cao_hoat_dong_nam_2022_ket_qua_2_thang_2023_va_ke_hoach_2023.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=hoat_dong_kinh_doanh_2_thang_dau_nam_20223.jpg"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Hoatdongkinhdoanh2thangdaunam2023ngay14032022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=23_03_08_BAO_CAO_KIEM_TOAN_2022_CTY_CP_DV_NEN_DI_DONG_VN.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022
              }}
            </span>
          </a>
        </li>
     
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_12_9_Bien_ban_thoa_thuan_su_dung_ten_va_logo_cua_Hoi_huu_nghi_Viet_Nam_Hungary.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanthoathuansudungtenvalogocuaHoihuunghiVietNamHungaryngay09122022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=29_11_2022_Giay_phep_xuat_nhap_khau_san_pham_mat_ma_dan_su.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay29112022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_10_11_Dang_ky_kinh_doanh_Vimass_22.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlanthu22ngay11102022
              }}
            </span>
          </a>
        </li>
        <li style="list-style: none">
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_10_6_Bien_ban_hop_HDQT.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay06102022
              }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bien_ban_hop_dai_hoi_co_dong_bat_thuong31082022.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay31082022
              }}
            </span>
          </a>
        </li>
        <li style="list-style: none">
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_8_2_Bien_ban_hop_HĐQT.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay02082022
              }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Hop_dong_hop_tac_cung_cap_dich_vu_thu_va_chi_ho_vimass_qrvn_11_07_2022.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_HopdonghoptaccungcapdichvuthuvachihoViMASSQRVNngay11072022
              }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://drive.google.com/file/d/1uhdkxuF51epeoT9pGiuskdNjnnlU4CRL/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_QuyetdinhcungcaptrangthietbichoCBNVngay01072022
              }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bien_ban_hop_HDQT_31_05_2022.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTngay31052022
              }}
            </span>
          </a>
        </li>

        <li style="list-style: none">
          <a
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=DKKD_NgheAn.png"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhNgheAnngay2752022
              }}
            </span>
          </a>
        </li>
        <li style="list-style: none">
          <a
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=dkkd-quangninh.jpg"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhQuangNinhngay20052022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=DKKD_CNHUE.png"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhlandauchinhanhHuengay18052022
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=DKKD_HAIPHONG.jpg"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhlandauchinhanhHaiPhongngay07052022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=DKKD_chi_nhanh_Can_Tho.jpg"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhlandauchinhanhCanThongay29042022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_4_28_Bien_ban_Hop_HDQT.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTngay28042022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bao_cao_hoat_dong_quy_I_va_ke_hoach_2022.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BaocaohoatdongquyIvakehoach2022ngay26042022
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=DKKD_chi_nhanh_Da_Nang.jpg"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhlandauchinhanhDaNangngay22032022
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_3_11_Dang_ky_kinh_doanh_lan_thu_21.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlanthu21ngay11032022
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1yJ4VlyP9-wglP6Yq1KbJjPLrTHfMk-Qr/view?usp=sharing"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopDaihoicodongthuongnien2022ngay22022022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_2 _21_Bao_cao_cua_CT HDQT_tai_DHDCD_thuong_nien.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BaocaocuaCTHDQTtaiDHDCDthuongnien21022022
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            target="_blank"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bao_cao_kiem_toan_2021.pdf"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BaocaokiemtoanVimass2021ngay27012022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=22_1_25_Bien_ban_hop_HDQT.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQT2512022
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1PpS3KCV1VO-vrqCqIKLgBjEjrTwLExZ6/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_ThucHienXoaTaiKhoanVi
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=DKKD_20.jpg"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlanthu20ngay31122021
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/1PrJOOBzi42bcMycb9zyHpcyqWuh2km7F/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay30112021
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://drive.google.com/file/d/15anuxnIfUnHW6g935M6fvd3qV1Kh_-_Z/view?usp=sharing"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTVimassngay28102021
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=BANG_CAN_DOI_KE_TOAN_VA_BAO_CAO_KET_QUA_HOAT_DONG_KINH_DOANH_9_THANG_DAU_NAM_2021.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Bangcandoiketoanvabaocaoketquakinhdoanh9thangdaunam202122102021
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=21_10_5_Giay_phep_kinh_doanh_san_pham_dich_vu_MMDS.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Giayphepkinhdoanhsanphamdichvumatmadansu4102021
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bien_Ban_Hop_HDQT_22_6_21.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHoidongquantriVimassngay2262021
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bien_ban_hop_dai_hoi_dong_co_dong_thuong_nien_27_04_21.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2021ngay2742021
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DKKD_19.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlanthu19ngay23032021
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DKKD_18.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlanthu18ngay15102020
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Phan_cong_can_bo_trien_khai_cks.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bien_ban_Hop_DHDCD_Vimass_2019.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanvanghiquyethopDHDCDthuongnien2019ngay442019
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Nghi_quyet_HDQT.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_NghiquyetHoidongquantriVimassngay242019
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Giay_moi_hop_DHCD.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Giaymoihopdaihoicodongthuongnien2019ngay2132019
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DANH_SACH_EMAIL_CO_DONG_VIMASS.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Danhsachemailcuacodongngay2132019
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Nghi_quyet_HDQT_DV_CTCKSCC.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_NghiquyetHDQTthongquaviecxincapgiayphepchungthucCKSngay2522019
              }}
            </span>
          </a>
        </li>
        <li>
          <a href="#popupVayVon" name="open_popup" rel="PopupVimass">
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_NghiquyetHoidongquantriVimassveviecvayvonngay3012019
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=GIAY_PHEP_NHAP_KHAU_TB_MMDS.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_GiayphepnhapkhauthietbiMMDSngay2812019
              }}
            </span>
          </a>
        </li>
        <li>
          <a href="#popupGop" name="open_popup" rel="PopupVimass">
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DKKDlan6cuachinhanhHCMngay2412019
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=BB_HOP_HDQT_VAY_VON.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHDQTlan1ngay2312019
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=Vimass1546235814185didsq.jpg"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_NghiquyetHDQTso1201dQDHDQTHuydongvongop10ngay18122018
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://web.vimass.vn/vmbank/services/media/getFile?id=Hop_dong-chuyen_nhuong_von_gop_DOC"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_MauhopdongchuyennhuongcophanVimass
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://web.vimass.vn/vmbank/services/media/getFile?id=Bien_ban_thanh_ly_HĐCNPVG_Vimass_doc"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_MaubienbanthanhlyHDCNPVGVimass
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DIEU_LE_CTY_CP_VIMASS.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DieulecongtycophandichvunendidongVietNam
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://web.vimass.vn/vmbank/services/media/getImage?id=Vimass15464860470459flok.jpg"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlan17ngay492018
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DKKD_Vimass_lan16_ngay_13_6_2018.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlan16ngay1362018
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=Bien_Ban_Hop_HĐTV_Vimass_2018.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_BienbanhopHoidongthanhvienthuongnienVimassngay952018
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DKKD_Vimass_lan15_ngay_04_04_2018.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhVimasslan15ngay442018
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/16XDp2WrZdJ6O2Rdl7Arwgz-GfKcnipFx/view"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Giayphep41GPNHNNcuaNganhangnhanuoc1232018
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DKKD_Vimass_lan14_ngay_4_1_2018.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_DangkykinhdoanhVimasslan14ngay412018
              }}
            </span>
          </a>
        </li>

        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DKKD_06.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlan6ngay19122013
              }}
            </span>
          </a>
        </li>
        <li>
          <a
            href="https://vimass.vn/vmbank/services/media/viewFile?id=DKKD_Vimass_lan_1_ngay_21_05_2012.pdf"
            target="_blank"
          >
            <span style="color: #0066cc; float: left"
              >{{
                currentLanguage.body_text_congty_codong_Dangkykinhdoanhlandaungay3152012
              }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { languages } from "@/components/languages";

export default {
  components: {},
  data() {
    return {
      currentLanguage: languages.vi,
      lang: "",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("languages");
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },
  },
};
</script>