import axios from "axios";
import { apiCheckLogin, apiDangNhap, apiLayTiGia, apiVpos } from "./api";
import CryptoJS from "crypto-js";

const getMD5 = (input) => {
  return CryptoJS.MD5(input).toString();
};
const header = {
  "Access-Control-Allow-Origin": "https://vimass.vn",
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
  "Access-Control-Allow-Headers":
    "DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Max-Age": 3600,
  "Access-Control-Expose-Headers": "Content-Length",
};
const codeCheckSum = "0819226669jdqpowrifioefiqo3289r79f";
const currentTime = new Date().getTime();
const userLapTrinh = "0979951954";

//check logi
export const getCheckLoginUser = (idVi) => {
  var cks = getMD5(currentTime + "1216546" + idVi + userLapTrinh);

  return axios
    .get(
      apiCheckLogin + currentTime + "_" + cks + "_" + idVi + "_" + userLapTrinh,
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//  dang nhap
export const postLogin = (
  user,
  pass,
  deviceId,
  VimassMH,
  companyCode,
  type
) => {
  return axios
    .post(
      apiDangNhap,
      { user, pass, deviceId, VimassMH, companyCode, type },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//  dang ky
export const postResgister = (id, pass) => {
  var funcId = 21;
  var deviceId = 4;
  var appId = 1;
  return axios
    .post(
      apiDangNhap,
      { id, pass, appId, deviceId, funcId },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//postLayDsStore
export const postLayDsStore = (sessionLogin, idLogin, accOwner, idStore) => {
  var funcId = 2;
  var timeRequest = currentTime;
  var companyCode = "";
  var typeLogin = 0;
  var mcId = "";
  var limit = 20;
  var offset = 0;

  var cksInput = getMD5(
    codeCheckSum +
      sessionLogin +
      idLogin +
      typeLogin +
      idStore +
      timeRequest +
      limit +
      offset
  );
  return axios
    .post(
      apiVpos,
      {
        funcId,
        sessionLogin,
        idLogin,
        typeLogin,
        accOwner,
        companyCode,
        idStore,
        mcId,
        timeRequest,
        cksInput,
        limit,
        offset,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//post Lấy category
export const postLayCategory = (sessionLogin, idLogin, idStore) => {
  var funcId = 22;
  var typeLogin = 0;
  var companyCode = "";
  var timeRequest = currentTime;
  var offset = 0;
  var limit = 100;
  var cksInput = getMD5(
    codeCheckSum +
      sessionLogin +
      idLogin +
      typeLogin +
      timeRequest +
      idStore +
      offset +
      limit
  );
  return axios
    .post(
      apiVpos,
      {
        funcId,
        sessionLogin,
        idLogin,
        typeLogin,
        companyCode,
        idStore,
        timeRequest,
        offset,
        limit,
        cksInput,
      },
      { header: header }
    )

    .then((res) => {
      return res.data;
    });
};
//post Lấy Item
export const postLayItem = (sessionLogin, idLogin, idStore, idCate) => {
  var funcId = 43;
  var typeLogin = 0;
  var companyCode = "";
  var timeRequest = currentTime;
  var offset = 0;
  var limit = 100;
  var productId = "";

  var cksInput = getMD5(
    codeCheckSum +
      sessionLogin +
      idLogin +
      typeLogin +
      timeRequest +
      idStore +
      productId +
      offset +
      limit +
      idCate
  );
  return axios
    .post(
      apiVpos,
      {
        funcId,
        sessionLogin,
        idLogin,
        typeLogin,
        companyCode,
        timeRequest,
        idCate,
        idStore,
        productId,
        offset,
        limit,
        cksInput,
      },
      { header: header }
    )

    .then((res) => {
      return res.data;
    });
};
export const postTaoBill = (
  sessionLogin,
  idLogin,
  idStore,
  listOrder,
  mst,
  email,
  sdtLH,
  totalAmount,
  sdtShipLienHe,
  diaChiShip
) => {
  var funcId = 60;
  var soBan = 0;
  var typeLogin = 0;
  var companyCode = "";
  var timeRequest = currentTime;
  var voucherCode = "";
  var note = "";
  var diaChiNhanHang = "";
  var totalDiscountAmount = 0;

  var cksInput = getMD5(
    codeCheckSum +
      sessionLogin +
      idLogin +
      typeLogin +
      timeRequest +
      idStore +
      voucherCode +
      mst +
      email +
      sdtLH +
      totalAmount +
      totalDiscountAmount
  );
  console.log(sessionLogin);
  return axios
    .post(
      apiVpos,
      {
        funcId,
        soBan,
        sessionLogin,
        idLogin,
        typeLogin,
        companyCode,
        timeRequest,
        idStore,
        voucherCode,
        listOrder,
        mst,
        email,
        sdtLH,
        note,
        diaChiNhanHang,
        totalAmount,
        totalDiscountAmount,
        cksInput,
        sdtShipLienHe,
        diaChiShip
      },
      { header: header }
    )

    .then((res) => {
      return res.data;
    });
};
export const postLayTiGia = () => {
  var deviceID = 3;
  var currentTime = new Date().getTime();

  var cks = getMD5(deviceID + "ZgVCyyHxqyyMd$aN112YHD" + currentTime);
  return axios
    .post(
      apiLayTiGia,
      {
        deviceID,
        currentTime,
        cks,
      },
      { header: header }
    )

    .then((res) => {
      return res.data;
    });
};
