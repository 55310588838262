<template>
  <form class="from-align--style">
     <div class="from-bround-add-cate">
      <span class ="fs-2 pt-3 fw-bold">Thêm danh mục</span>
          <div class="inputWithIcon">
            <input type="text" placeholder="Tên danh mục" v-model="inputCategotyName" style="height: 45px;" />
          <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
          </div>
          <div class="inputWithIcon">
            <input type="text" placeholder="Nhập 6 số token"  v-model="inputToken" maxlength="6" style="height: 45px;" />
           <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
          </div>
          <div class ="span--style-h">
              <span class ="fs-5 " id ="notificationText">{{notification}} </span>
          </div>
          <button @click.prevent="createCategory" class="w-100 fs-5 mt-2 rounded-1 fw-bold btn-style" >
            Thực hiện
          </button>
     </div>
    </form>
  </template>
  
  <script>
  import {postCreateCategory} from "@/components/js/VposService";
    export default {
      props:{
        parentFuncCreate:{
          type: Function,
          required: true
        }
      },
      data() {
        return {  
          InputIdStore: "", inputToken: "", inputCategotyName: "",notification:""
        };
    },
 
    methods:{
    
        async createCategory(){
        try{
          
           this.InputIdStore= localStorage.getItem('idCuaHang');

           // console.log("response ========> nhay vao day")
            const response = await postCreateCategory(this.inputToken, this.InputIdStore, this.inputCategotyName);
           // console.log("response ========>"+ JSON.stringify(response));
  
            if( response.msgCode == 1){
                this.notification = response.msgContent;
                // window.location.href = "/vpos/taocuahang";
                setTimeout(() => {
                  this.inputToken = " ";
                  this.inputCategotyName= " ";
                  this.notification = " ";
                  this.parentFuncCreate();
                }, 2000);
              
            }else if(response.msgCode == 8){
                this.notification = "OTP hết hiệu lực";
            }else{
                this.notification = "Không thành công";
            }
            }catch(e){
                console.log("Exception e:",e);
            }
          
        },
    
    }
    }
  </script>
  