<template>
  <div class="row ">
    <div class="col-12 col-md-5 d-flex justify-content-star align-items-center ">
      <div class="d-none">
        <span class="ms-2 me-2 fs-5 fw-bold ">{{ shopName}}</span> <br>
        <span class="ms-2 me-2">{{ storeAdd}}</span>
      </div>
      <select class ="ms-2 me-2 select--style fw-bold" v-model="type" >
          <option :value="0" class="option--style">Tất cả</option>
          <option :value="1" class="option--style">Thu</option>
          <option :value="2" class="option--style">Chi</option>
      </select>
      <span class="ms-2 me-2 fw-bold ">Từ</span>
      <Datepicker :inline="true" v-model="dateFrom" @change="handleButtonClick" ref="myButton" inputFormat ="dd/MM/yyyy"  lear-button="true"
          input-class="inpmy-picker-classt" calendar-class="W-100"
          @selected="parentFunctionSelectedHandler" 
          @opened="datepickerOpenedFunction" 
          @closed="parentFunctionCloseHandler"
      />
      <span class="ms-2 me-2 fw-bold ">Đến</span>
      <Datepicker :inline="true" v-model="dateTo" @change="handleButtonClick" ref="myButton" inputFormat ="dd/MM/yyyy"  :typeable="true"
          input-class="customClass" 
          @selected="parentFunctionSelectedHandler" 
          @opened="datepickerOpenedFunction" 
          @closed="parentFunctionCloseHandler"
      />
      <button ref="myButton" class="btn-se ms-3 p-2 fw-bold" @click="traCuu">Tìm</button>
    </div>
    <div class="col-12 col-md-4 d-flex align-items-center">
      <span class="me-5 fw-bold ">Giao dich: {{ formatTien(this.numFound) }} </span>
      <span class="fw-bold ">Tổng tiền: </span> <span class="ms-1 fw-bold " style="color:#009644">{{ formatTien(this.tongTien) }} đ</span>
    </div>
  </div> 

   <div class="row p-0 m-1">
      <table class="p-2">
        <tr>
          <th>STT</th>
          <th>Số tiền</th>
          <th>Phí</th>
          <th>Nội dung</th>
          <th>Thời gian</th>
        </tr>
        <tr v-for="(item,index) in listTrans" :key="index">
          <td class ="td--bg">{{index}}</td>
          <td class ="td--bg td--color"> + {{ formatTien(item.realAmount) }}</td>
          <td class ="td--bg">{{ formatTien(item.phi) }}</td>
          <td class ="td--bg">{{ item.content }}</td>
          <td class ="td--bg" style="color: #777777;">{{ formatTime(item.transTime)}}</td>
        </tr>
      </table>
   </div>
</template>

<script>

import Datepicker from 'vue3-datepicker';
import {postSaoKeGiaoDich} from "@/components/js/VposService";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      itemStore: null, shopName:"",storeAdd:"", type: 0, listTrans:[],numFound: 0, tongTien: 0,
       dateFrom: new Date(), dateTo: new Date(), startOfDayMillis: 0, endOfDayMillis: 0, inputValue: "", tatolTrans: 0, tatolMany: 0, page:[]
    };
  },
  mounted() {
    // Tự động kích hoạt sự kiện click sau khi component được mount
    // this.triggerClick();
    this.getValueStore();
    this.renderPhanTrang(0,434,50);
    
  },
  methods: {
    getValueStore(){
      const item = localStorage.getItem('selectedStore');
      if (item) {
        this.itemStore = JSON.parse(item);
        this.shopName = this.itemStore.storeName;
        this.storeAdd = this.itemStore.storeAdd;
       // console.log("itemStore: ", JSON.stringify(this.itemStore));
      } else {
        console.log("No item found");
      }
    
      setTimeout(() => {

       }, 500);
    },
    triggerClick() {
      // Sử dụng JavaScript để tự động kích hoạt sự kiện click
      this.$refs.myButton.click();
    },
    parentFunctionSelectedHandler(date) {
      console.log('Date selected:', date);
      // Handle the selected date here
    },
    datepickerOpenedFunction() {
      console.log('Datepicker opened');
      // Handle the datepicker opening here
    },
    parentFunctionCloseHandler() {
       this.check = false;
         setTimeout(() => {
          const button = document.getElementById('myButton');
          if (button) {
            button.click();
          }
            console.log(' after click 500');
       }, 500);
    },
    show() {
      this.calculateTimes();
    },
    calculateTimes() {
      var dateFrom = new Date(this.dateFrom);
      dateFrom.setHours(0, 0, 1,1); // Set to 00:00:01
      this.startOfDayMillis = dateFrom.getTime();
      // console.log("startOfDayMillis: ", this.startOfDayMillis);

      var dateTo = new Date(this.dateTo);
      dateTo.setHours(23, 59, 59,59); // Set to 23:59:59
      this.endOfDayMillis = dateTo.getTime();
      // console.log("endOfDayMillis: ", this.endOfDayMillis);
    },
    async traCuu() {
        var dateFrom = new Date(this.dateFrom);
        dateFrom.setHours(0, 0, 1); // Set to 00:00:01
        this.startOfDayMillis = dateFrom.getTime();
        // console.log("startOfDayMillis: ", this.startOfDayMillis);

        var dateTo = new Date(this.dateTo);
        dateTo.setHours(23, 59, 59); // Set to 23:59:59
        this.endOfDayMillis = dateTo.getTime();
        // console.log("endOfDayMillis: ", this.endOfDayMillis);
      
        try {
          const response = await postSaoKeGiaoDich(this.type, this.itemStore, this.startOfDayMillis, this.endOfDayMillis);
          // console.log("response =====> " + JSON.stringify(response));
          this.listTrans = response.result.docs;//numFound, tongTien
          this.numFound = response.result.numFound;
          this.tongTien = response.result.tongTien;
            
        } catch (error) {
            console.log("catch " + error);
        }
    },
    formatTien(tien) {            
            return new Intl.NumberFormat("vi-VN").format(tien);
           },
    formatTime(time){

        const date = new Date(parseInt(time, 10));
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    },
    renderPhanTrang(start, end, step){
      for (let i = start; i < end; i += step) {
        this.page.push({ start: i, end: i + step });
      }     

      console.log("this.page: " + JSON.stringify(this.page));
  }
}
};
</script>
<style scoped>
.btn-se{
  border-radius: 5px !important;
    background-color: rgb(20, 129, 218);
    border: 2px solid rgb(20, 129, 218);
    color: white;
   
}
th, tr, td{
  border: 1px solid #B9D3E9; cursor: pointer;
}

td{
  padding: 10px 5px;
}
th{
  background-color: #F2F9FF; color: black;
}


.td--bg{
  background: white;
}
.td--color{
  color: #009644; 
}
.select--style{
  height: 40px; border-radius: 5px; border-color: #aaa!important; min-width: 90px; width: 100px;
}

.select--style:focus {
    border-color: #aaa!important;
}
.option--style{
  height: 30px!important;font-weight: bold; width: 50px!important;
}

</style>


