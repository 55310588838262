<template>
  <p id="demo"></p>
</template>

<script>
export default {
  name: "RedirectComponent",
  mounted() {
    this.handleRedirect();
    window.addEventListener("hashchange", this.handleRedirect);
  },
  beforeUnmount() {
    window.removeEventListener("hashchange", this.handleRedirect);
  },
  methods: {
    handleRedirect() {
      if (window.location.href.includes("vcard")) {
        this.redirect("vcard");
      } else if (window.location.href.includes("vpass")) {
        this.redirect("vpass");
      }
    },
    redirect(type) {
      let redirectUrl = "/vi/giaiphapchuyendoiso#" + type + "/";

      const userAgent = window.navigator.userAgent;
      if (userAgent.includes("Android")) {
        redirectUrl =
          "https://play.google.com/store/apps/details?id=com.qr." + type;
      } else if (userAgent.includes("iPhone")) {
        redirectUrl =
          "https://apps.apple.com/" +
          (type === "vpass" ? "vn" : "us") +
          "/app/" +
          type +
          "/id" +
          (type === "vcard" ? "1565903620" : "6448410181");
      }

      window.location.href = redirectUrl;
    },
  },
};
</script>

