<template>
        <div class="inputWithIcon">
          <input type="text" placeholder="Nhập code OTP" v-model="codeOTP" maxlength ="6"/>
          <i class="fa fa-lg fa-fw" aria-hidden="true"></i>
        </div>

        <div>
            <span class ="fs-5 " id ="notificationText">{{notification }}</span>
        </div>
        <button @click="comfirmOTP" class="btn int btn-outline-primary mt-5"> Xác nhận </button>
    
  </template>
  <script>
import {getIpWan, postComfrimOTP } from '@/components/js/VposService';

  export default {

        data() {
            return {
                ipWan:"",
                codeOTP: "",
                confrimOTP: "",
                notification:""
            };
        },
        mounted(){
            this.getIpWan();
            this.getCapcha();
        },
        methods:{
            async comfirmOTP(){
            try{
                const response = await postComfrimOTP(this.codeOTP, this.ipWan, this.confrimOTP);
            
                this.notification = response.msgContent;
    
                }catch(e){
                    console.log("Exception e:",e);
                }
            },
            getCapcha() {
                let random_id = Math.ceil(Math.random() * 1000000);
                this.confrimOTP = random_id;
            },
            async getIpWan(){

                try{
                    const res = await getIpWan();
                    this.ipWan = res;
                    }catch(e){
                        console.log("Exception e:",e);
                    }
                }
               
        }
    }
  </script>
